// ReturnOrderModal.jsx

import React, { useState } from "react";
import { Table, Modal, Tag, Row, Col } from "antd";
import { StopOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { COLORS } from "../../constants";
import { formatMoney } from "../../Formatters/helpers";
import { getVendorName } from "../../Formatters/getVendorName";
import { getProjectName } from "../../Formatters/getProjectName";

const ReturnOrderModal = ({
  purchaseOrder,
  visible,
  onClose,
  vendors,
  projects,
}) => {
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  if (!purchaseOrder || !purchaseOrder.returnOrder) return null;

  const returnOrder = purchaseOrder.returnOrder;

  // Determine the line status based on qtyShipped vs qtyReturn
  const getLineStatus = (line) => {
    if (line.qtyReturn > 0) {
      return line.qtyShipped >= line.qtyReturn ? "Closed" : "Open";
    }
    return <StopOutlined style={{ color: "#d3d3d3" }} />;
  };

  // Handle opening the document modal
  const handleDocumentIconClick = (documents) => {
    setSelectedDocuments(documents);
    setDocumentModalVisible(true);
  };

  // Define item columns
  const itemColumns = [
    {
      title: "Line",
      dataIndex: "lineNumber",
      key: "lineNumber",
    },
    {
      title: "Item #",
      dataIndex: "itemNumber",
      key: "itemNumber",
      width: 80,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 180,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Qty Received",
      dataIndex: "qtyReceived",
      key: "qtyReceived",
      width: 150,
      render: (text, record) => {
        const qtyOrdered = record.qtyOrdered || 0;
        const qtyReceived = record.qtyReceived || 0;
        return `${qtyReceived} / ${qtyOrdered}`;
      },
    },
    {
      title: "Qty Shipped",
      dataIndex: "qtyShipped",
      key: "qtyShipped",
      width: 150,
      render: (text, record) => {
        const qtyShipped = record.qtyShipped || 0;
        const qtyReturn = record.qtyReturn || 0;
        return qtyReturn > 0 ? (
          `${qtyShipped} / ${qtyReturn}`
        ) : (
          <StopOutlined style={{ color: "#d3d3d3" }} />
        );
      },
    },
    {
      title: "Line Status",
      dataIndex: "lineStatus",
      key: "lineStatus",
      width: 100,
      render: (_, record) => {
        const status = getLineStatus(record);
        let color = "orange";
        if (status === "Closed") color = "green";
        if (status === "Open") color = "blue";
        return typeof status === "string" ? (
          <Tag color={color}>{status}</Tag>
        ) : (
          status
        );
      },
    },
    {
      title: "Shipped Date",
      dataIndex: "shippedDate",
      key: "shippedDate",
      width: 110,
      render: (text) => {
        if (!text) {
          return <StopOutlined style={{ color: "#d3d3d3" }} />;
        }
        return dayjs(text).format("MM/DD/YYYY");
      },
    },
  ];

  // Calculate Costs
  // Purchase Order (PO) Costs
  const poShippingCosts = parseFloat(purchaseOrder.shippingCosts || "0");
  const poOtherCosts = parseFloat(purchaseOrder.other || "0");
  const poDiscount = parseFloat(purchaseOrder.discount || "0");
  const subtotalPO = parseFloat(
    purchaseOrder.lines.reduce(
      (total, line) =>
        total +
        parseFloat(line.itemPrice || "0") * parseFloat(line.qtyOrdered || "0"),
      0
    )
  );

  const totalPOAmount =
    poShippingCosts + poOtherCosts - poDiscount + subtotalPO;

  // Return Order (RO) Costs
  const roShippingCosts = parseFloat(returnOrder.shipping || "0");
  const roOtherCosts = parseFloat(returnOrder.other || "0");
  const subtotalRO = parseFloat(
    purchaseOrder.lines.reduce(
      (total, line) =>
        total +
        parseFloat(line.itemPrice || "0") * parseFloat(line.qtyReturn || "0"),
      0
    )
  );

  const totalROAmount = roShippingCosts + roOtherCosts - subtotalRO;

  // Final Total
  const finalTotal = totalPOAmount - totalROAmount;

  // Define PO Costs DataSource
  const poCostsData = [
    { desc: "PO Subtotal", amount: subtotalPO },
    { desc: "PO Shipping Costs", amount: poShippingCosts },
    { desc: "PO Other Costs", amount: poOtherCosts },
    { desc: "PO Discount", amount: -poDiscount },
    { desc: "Total PO Amount", amount: totalPOAmount },
  ];

  // Define RO Costs DataSource
  const roCostsData = [
    { desc: "RO Subtotal", amount: subtotalRO },
    { desc: "RO Shipping Costs", amount: roShippingCosts },
    { desc: "RO Other Costs", amount: roOtherCosts },
    { desc: "Return Total", amount: -subtotalRO },
    { desc: "Total RO Amount", amount: totalROAmount },
  ];

  return (
    <>
      <Modal
        title={
          <div
            style={{
              position: "relative",
              padding: "0 50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <FileTextOutlined
                style={{
                  marginRight: 8,
                  fontSize: "18px",
                  cursor: "pointer",
                  color: COLORS.PRIMARY,
                }}
                onClick={() =>
                  handleDocumentIconClick(returnOrder.documents || [])
                }
              />
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                {`${purchaseOrder.roNumber} / ${getVendorName(
                  purchaseOrder.vendor,
                  vendors
                )}`}
              </span>
              <Tag
                style={{ marginLeft: 8 }}
                color={purchaseOrder.poStatus === "Closed" ? "green" : "blue"}
              >
                {purchaseOrder.poStatus}
              </Tag>
              <Tag
                color={
                  dayjs().isAfter(dayjs(returnOrder.requiredDate))
                    ? "red"
                    : "green"
                }
              >
                {dayjs().isAfter(dayjs(returnOrder.requiredDate))
                  ? "Late"
                  : "On Time"}
              </Tag>
            </div>
            <div
              style={{ position: "absolute", left: "0px", fontSize: "14px" }}
            >
              <strong>PO:</strong> {purchaseOrder.poNumber} /{" "}
              <strong>Project:</strong>{" "}
              {getProjectName(purchaseOrder.projectNumber, projects)}{" "}
            </div>
          </div>
        }
        centered
        visible={visible}
        onCancel={onClose}
        footer={null}
        width="95%"
        style={{ marginTop: "32px", marginBottom: "32px" }}
      >
        {/* Line Items Table */}
        <Table
          columns={itemColumns}
          dataSource={purchaseOrder.lines} // Display all lines
          rowKey="lineNumber"
          pagination={false}
          size="small"
          rowClassName={(record) =>
            record.qtyReturn > 0 ? "return-line" : "non-return-line"
          }
        />

        {/* Summary Costs Section */}
        <div style={{ marginTop: 16 }}>
          <Row gutter={[16, 16]}>
            {/* Left Column: Dates */}
            <Col xs={24} sm={24} md={8}>
              <div>
                <div>
                  <strong>Ordered Date:</strong>{" "}
                  {dayjs(returnOrder.orderedDate).format("MM/DD/YYYY") || "N/A"}
                </div>
                <div style={{ marginTop: 8 }}>
                  <strong>Required Date:</strong>{" "}
                  {dayjs(returnOrder.requiredDate).format("MM/DD/YYYY") ||
                    "N/A"}
                </div>
              </div>
            </Col>

            {/* Middle Column: PO Costs Table */}
            <Col xs={24} sm={12} md={8}>
              <Table
                pagination={false}
                showHeader={false}
                size="small"
                columns={[
                  { title: "Description", dataIndex: "desc", key: "desc" },
                  {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (text, record) =>
                      record.desc === "PO Discount" ? (
                        <Tag color="volcano">{formatMoney(text)}</Tag>
                      ) : record.desc === "Total PO Amount" ? (
                        <Tag
                          color="green"
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          {formatMoney(text)}
                        </Tag>
                      ) : (
                        formatMoney(text)
                      ),
                  },
                ]}
                dataSource={poCostsData}
                style={{ width: "100%" }}
                bordered
              />
            </Col>

            {/* Right Column: RO Costs Table */}
            <Col xs={24} sm={12} md={8}>
              <Table
                pagination={false}
                showHeader={false}
                size="small"
                columns={[
                  { title: "Description", dataIndex: "desc", key: "desc" },
                  {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (text, record) =>
                      record.desc === "Return Total" ? (
                        <Tag color="volcano">{formatMoney(text)}</Tag>
                      ) : record.desc === "Total RO Amount" ? (
                        <Tag
                          color="blue"
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          {formatMoney(text)}
                        </Tag>
                      ) : (
                        formatMoney(text)
                      ),
                  },
                ]}
                dataSource={roCostsData}
                style={{ width: "100%" }}
                bordered
              />
            </Col>
          </Row>

          {/* Final Total Row */}
          <Row style={{ marginTop: 16 }}>
            <Col span={24} style={{ textAlign: "right" }}>
              <Table
                pagination={false}
                showHeader={false}
                size="small"
                columns={[
                  { title: "Description", dataIndex: "desc", key: "desc" },
                  {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (text, record) =>
                      record.desc === "Final Total" ? (
                        <Tag
                          color="green"
                          style={{ fontWeight: 600, fontSize: 16 }}
                        >
                          {formatMoney(text)}
                        </Tag>
                      ) : (
                        formatMoney(text)
                      ),
                  },
                ]}
                dataSource={[{ desc: "Final Total", amount: finalTotal }]}
                style={{ width: 300, marginLeft: "auto" }}
                bordered
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Documents Modal */}
      <Modal
        title="Documents"
        centered
        visible={documentModalVisible}
        onCancel={() => setDocumentModalVisible(false)}
        footer={null}
        style={{ marginTop: "32px", marginBottom: "32px" }}
      >
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          {selectedDocuments.map((doc) => (
            <Col key={doc.uuid} xs={12} sm={8} md={6}>
              <div
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => window.open(doc.documentUrl, "_blank")}
              >
                <FileTextOutlined
                  style={{ fontSize: "32px", color: "#1890ff" }}
                />
                <div>{dayjs(doc.dateSubmitted).format("MM/DD/YYYY")}</div>
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default ReturnOrderModal;
