// src/components/PurchaseOrderModal.js

import React, { useState } from "react";
import { Table, Modal, Tag, Row, Col, Image, Badge, Spin } from "antd";
import {
  StopOutlined,
  FileTextOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { COLORS } from "../../constants";
import { getVendorName } from "../../Formatters/getVendorName";
import { formatMoney } from "../../Formatters/helpers";
import { getProjectName } from "../../Formatters/getProjectName";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import utility functions
import { getSubtotal, getTotalAmount } from "../../utils/purchaseOrderUtils";

const PurchaseOrderModal = ({
  purchaseOrder,
  visible,
  onClose,
  vendors,
  projects,
}) => {
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedItemDescription, setSelectedItemDescription] = useState(""); // Track the description
  const [loadingImages, setLoadingImages] = useState(false); // Loading state for images

  console.log(purchaseOrder);

  if (!purchaseOrder) return null;

  // Determine the line status based on qtyReceived vs qtyOrdered
  const getLineStatus = (line) => {
    if (line.qtyReceived >= line.qtyOrdered) {
      return "Closed";
    } else if (line.qtyReceived > 0 && line.qtyReceived < line.qtyOrdered) {
      return "Partial";
    } else {
      return "Open";
    }
  };

  // Handle Image icon click to show the images
  const handleImageClick = (images, itemDescription) => {
    setSelectedImages(images);
    setSelectedItemDescription(itemDescription); // Set the item description
    setImageModalVisible(true);
  };

  const handleCloseImageModal = () => {
    setSelectedImages(null); // Reset images on modal close
    setImageModalVisible(false);
  };


  const calculateLateStatus = (record) => {
    if (!record || !record.requiredDate) return "On Time";

    const allLinesReceived = record.lines.every(
      (line) => line.qtyReceived >= line.qtyOrdered
    );

    const requiredDateMidnight = dayjs(
      record.requiredDate,
      "MM/DD/YYYY"
    ).startOf("day");
    const todayMidnight = dayjs().startOf("day");

    if (todayMidnight.isAfter(requiredDateMidnight) && !allLinesReceived) {
      return "Late";
    }

    const latestReceivedDate = record.lines
      .filter((line) => line.receivedDate)
      .map((line) => dayjs(line.receivedDate, "MM/DD/YYYY").startOf("day"))
      .sort((a, b) => b - a)[0];

    if (latestReceivedDate) {
      return latestReceivedDate.isAfter(requiredDateMidnight)
        ? "Late"
        : "On Time";
    }

    if (
      !latestReceivedDate &&
      !allLinesReceived &&
      todayMidnight.isBefore(requiredDateMidnight)
    ) {
      return "On Time";
    }

    return "On Time";
  };

  const poStatus = purchaseOrder.lines.every(
    (line) => getLineStatus(line) === "Closed"
  )
    ? "Closed"
    : "Open";

  const modalTitle = (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 50px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Badge
          offset={[-7]}
          onClick={() => handleDocumentIconClick(purchaseOrder.documents || [])}
          style={{ position: "absolute", cursor: "pointer" }}
          size="small"
          count={purchaseOrder.documents?.length || 0}
        >
          <FileTextOutlined
            style={{
              marginRight: 8,
              fontSize: "20px",
              cursor: "pointer",
              color: COLORS.PRIMARY,
            }}
            onClick={() =>
              handleDocumentIconClick(purchaseOrder.documents || [])
            }
          />
        </Badge>
        {purchaseOrder.groupNumber && (
          <Tag color="blue">Group {purchaseOrder.groupNumber}</Tag>
        )}
        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
          {`${purchaseOrder.poNumber} / ${getVendorName(
            purchaseOrder.vendor,
            vendors
          )}`}
        </span>
        <Tag
          style={{ marginLeft: 8 }}
          color={poStatus === "Closed" ? "green" : "blue"}
        >
          {poStatus}
        </Tag>
        <Tag
          color={
            calculateLateStatus(purchaseOrder) === "Late" ? "red" : "green"
          }
        >
          {calculateLateStatus(purchaseOrder)}
        </Tag>
      </div>
      <div
        style={{
          position: "absolute",
          left: "0px",
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
        }}
      >
        <span>
          <strong>Project:</strong>{" "}
          {getProjectName(purchaseOrder.projectNumber, projects)}
        </span>
      </div>
    </div>
  );

  const handleDocumentIconClick = (documents) => {
    setSelectedDocuments(documents);
    setDocumentModalVisible(true);
  };

  const itemColumns = [
    {
      title: "Item #",
      dataIndex: "itemNumber",
      key: "itemNumber",
      width: 80,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 180,
      onCell: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "Qty Ordered",
      dataIndex: "qtyOrdered",
      key: "qtyOrdered",
      width: 100,
    },
    {
      title: "Item price",
      dataIndex: "itemPrice",
      key: "itemPrice",
      width: 100,
      render: (text) => formatMoney(text),
    },
    {
      title: "Line Status",
      dataIndex: "lineStatus",
      key: "lineStatus",
      width: 100,
      render: (_, record) => {
        const status = getLineStatus(record);
        let color = "blue";
        if (status === "Closed") color = "green";
        if (status === "Partial") color = "orange";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Qty Received",
      dataIndex: "qtyReceived",
      key: "qtyReceived",
      width: 100,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {record.qtyReceived || 0}
            {record.images && record.images.length > 0 && (
              <FontAwesomeIcon
                icon={faImage}
                style={{
                  marginLeft: 8,
                  fontSize: 18,
                  color: COLORS.PRIMARY,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleImageClick(record.images, record.itemDescription)
                } // Pass the description
              />
            )}
          </div>
        );
      },
    },

    {
      title: "Received Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      width: 110,
      render: (text) => {
        if (!text) {
          return <StopOutlined style={{ color: "#d3d3d3" }} />;
        }
        return dayjs(text).format("MM/DD/YYYY");
      },
    },
  ];

  const renderDocumentsModal = () => (
    <Modal
      title="Documents"
      centered
      visible={documentModalVisible}
      onCancel={() => setDocumentModalVisible(false)}
      footer={null}
      style={{ marginTop: "32px", marginBottom: "32px" }}
    >
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        {!selectedDocuments.length && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <FileTextOutlined style={{ fontSize: "48px", color: "#d3d3d3" }} />
            <div>No documents found</div>
            <div style={{ marginBottom: 16, fontStyle: "italic" }}>
              This PO pre-dates the log system
            </div>
          </div>
        )}
        {selectedDocuments.map((doc, index) => (
          <Col key={doc.uuid} span={6}>
            <div
              style={{ cursor: "pointer", textAlign: "center" }}
              onClick={() => window.open(doc.documentUrl, "_blank")}
            >
              <FileTextOutlined
                style={{ fontSize: "32px", color: "#1890ff" }}
              />
              <div>Revision: {index}</div>
              <div>{dayjs(doc.dateSubmitted).format("MM/DD/YYYY")}</div>
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );

const renderImagesModal = () => (
  <Modal
    title={`Items Received for ${purchaseOrder.poNumber}`}
    centered
    visible={imageModalVisible}
    onCancel={handleCloseImageModal}
    footer={null}
  >
    <div style={{ marginBottom: 16 }}>
      <strong>Item Description:</strong> {selectedItemDescription}
    </div>
    {loadingImages ? (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Spin  /> {/* Show loading spinner */}
      </div>
    ) : (
      <Image.PreviewGroup>
        {selectedImages?.map((img, index) => (
          <Image
            key={index}
            width={200}
            src={img}
            alt={`Preview ${index + 1}`}
            style={{ marginBottom: 16 }}
          />
        ))}
      </Image.PreviewGroup>
    )}
  </Modal>
);


  // **Refactored Calculations using Utility Functions**
  const subtotal = getSubtotal(purchaseOrder); // Sum of all line items
  const totalAmount = getTotalAmount(purchaseOrder, subtotal); // Final total
  const shippingCosts = parseFloat(purchaseOrder?.shippingCosts || "0"); // Shipping Costs
  const otherCosts = parseFloat(purchaseOrder?.other || "0"); // Other Costs
  const discount = parseFloat(purchaseOrder?.discount || "0"); // Discount

  return (
    <>
      <Modal
        title={modalTitle}
        centered
        visible={visible}
        onCancel={onClose}
        footer={null}
        width="95%"
        style={{ marginTop: "32px", marginBottom: "32px" }}
      >
        <Table
          columns={itemColumns}
          dataSource={purchaseOrder.lines}
          rowKey="lineNumber"
          pagination={false}
          size="small"
        />
        <div style={{ marginTop: 16 }}>
          <Row justify="space-between">
            <Col>
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                <div>
                  <strong>Ordered:</strong>{" "}
                  {dayjs(purchaseOrder?.orderedDate).format("MM/DD/YYYY") ||
                    "N/A"}
                </div>
                <div style={{ margin: "0 12px", fontWeight: "bold" }}>|</div>
                <div>
                  <strong>Required:</strong>{" "}
                  {dayjs(purchaseOrder?.requiredDate).format("MM/DD/YYYY") ||
                    "N/A"}
                </div>
              </div>
            </Col>
            <Col>
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                {/* Subtotal */}
                <div>
                  <strong>Subtotal: </strong>
                  <Tag
                    color="green"
                    style={{
                      opacity: 0.9,
                      fontWeight: "normal",
                      marginLeft: 4,
                    }}
                  >
                    {formatMoney(subtotal)}
                  </Tag>
                </div>
                <div style={{ margin: "0 12px", fontWeight: "bold" }}>|</div>

                {/* Shipping Costs */}
                <div>
                  <strong>Shipping Costs: </strong>
                  {formatMoney(shippingCosts)}
                </div>
                <div style={{ margin: "0 12px", fontWeight: "bold" }}>|</div>

                {/* Other Costs */}
                <div>
                  <strong>Other Costs: </strong>
                  {formatMoney(otherCosts)}
                </div>
                <div style={{ margin: "0 12px", fontWeight: "bold" }}>|</div>

                {/* Discount */}
                {discount > 0 && (
                  <>
                    <div>
                      <strong>Discount: </strong>
                      <Tag color={"red"} style={{ marginLeft: 4 }}>
                        -{formatMoney(discount)}
                      </Tag>
                    </div>
                    <div style={{ margin: "0 12px", fontWeight: "bold" }}>
                      |
                    </div>
                  </>
                )}

                {/* Total */}
                <div>
                  <strong>Total: </strong>
                  <Tag
                    color="green"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      marginLeft: 4,
                    }}
                  >
                    {formatMoney(totalAmount)}
                  </Tag>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {renderDocumentsModal()}
      {renderImagesModal()}
    </>
  );
};

export default PurchaseOrderModal;
