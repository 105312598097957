import React, { useState, useEffect } from "react";
import { Col, Row, Button, Radio } from "antd";
import {
  DollarCircleOutlined,
  QuestionCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchInput } from "../../Styled/SearchInput";
import AddPurchaseOrder from "../../Components/Purchase/AddPurchaseOrder";
import AddRFQ from "../../Components/Purchase/AddRFQ";
import EditPurchaseOrderTable from "../../Components/Purchase/EditPurchaseOrderTable";
import {
  usePurchaseOrders,
  useReturnOrders,
  useProjects,
  useItems,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";

const Purchasing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTable, setActiveTable] = useState(() => {
    const path = location.pathname.split("/").pop();
    return ["purchase-orders", "rfq", "edit-purchase-orders"].includes(path)
      ? path
      : "purchase-orders";
  });

  const { data: purchaseOrders, refresh: refreshPurchaseOrders } =
    usePurchaseOrders();
  const { data: returnOrders, refresh: refreshReturnOrders } =
    useReturnOrders();
  const { data: projects, refresh: refreshProjects } = useProjects();
  const { data: items, refresh: refreshItems } = useItems();

  const iconStyle = { marginRight: "8px" };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (["purchase-orders", "rfq", "edit-purchase-orders"].includes(path)) {
      setActiveTable(path);
    }
  }, [location]);

  useEffect(() => {
    let title = "Purchasing";
    if (activeTable === "purchase-orders") {
      title = "Purchasing - Purchase Orders";
    } else if (activeTable === "rfq") {
      title = "Purchasing - RFQ";
    } else if (activeTable === "edit-purchase-orders") {
      title = "Purchasing - Edit Purchase Orders";
    }
    document.title = title;
    const path = location.pathname.split("/").pop();
    if (path !== activeTable) {
      navigate(`/purchasing/purchase/${activeTable}`, { replace: true });
    }
  }, [activeTable, navigate, location]);

  const handleTableSwitch = (e) => {
    setActiveTable(e.target.value);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={6}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        Purchase
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Radio.Group value={activeTable} onChange={handleTableSwitch}>
          <Radio.Button style={{ textAlign: "center" }} value="purchase-orders">
            <DollarCircleOutlined style={iconStyle} /> Purchase Order
          </Radio.Button>
          <Radio.Button style={{ textAlign: "center" }} value="rfq">
            <QuestionCircleOutlined style={iconStyle} /> RFQ
          </Radio.Button>
          <Radio.Button
            style={{ textAlign: "center" }}
            value="edit-purchase-orders"
          >
            <EditOutlined style={iconStyle} /> Edit Purchase Order
          </Radio.Button>
        </Radio.Group>
      </Col>
      <Col align="right" span={6}></Col>
      <Col span={24}>
        {activeTable === "purchase-orders" && <AddPurchaseOrder />}
        {activeTable === "rfq" && <AddRFQ />}
        {activeTable === "edit-purchase-orders" && <EditPurchaseOrderTable />}
      </Col>
    </Row>
  );
};

export default Purchasing;
