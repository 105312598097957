import React, { useState, useEffect } from "react";
import {
  Select,
  Table,
  InputNumber,
  Button,
  message,
  Row,
  Col,
  Tag,
  Form,
  DatePicker,
  Input,
  Popover,
  Spin,
  Modal,
  Card
} from "antd";
import { RocketOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { usePurchaseOrders } from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { useVendors } from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import AddressAutocomplete from "../Google/AddressAutocomplete";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { s3Upload } from "../../Functions/s3Upload";
import { generateReturnPdf } from "../Purchase/ReturnOrderPDF";
import { useUser } from "@clerk/clerk-react";
import { getProjectName } from "../../Formatters/getProjectName";
import { useProjects } from "../../Contexts/useSpecificData";
import { TABLES, COLORS, EMAILBODY } from "../../constants";
import { updateById } from "../../Functions/updateById";
import { sendEmail } from "../../Functions/sendEmail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

function AddReturnOrder() {
  const [form] = Form.useForm();
  const {
    data: purchaseOrders,
    loading: poLoading,
    refresh: refreshPurchaseOrders,
  } = usePurchaseOrders();
  const { data: projects } = useProjects();
  const { data: vendors } = useVendors();
  const [selectedPO, setSelectedPO] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [returnQuantities, setReturnQuantities] = useState({});
  const [shipping, setShipping] = useState(0);
  const [other, setOther] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [shipFromAddress, setShipFromAddress] = useState(
    "2624 Weaver Way Suite 200, Doraville, GA 30340"
  );
  const [requiredDate, setRequiredDate] = useState(null);
  const [hasInvalidQty, setHasInvalidQty] = useState(false);
  const [notes, setNotes] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [isEdit, setIsEdit] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const userEmail = useUser().user?.primaryEmailAddress?.emailAddress;
  const userName = useUser().user?.fullName;

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPO) {
      const vendorEmail =
        vendors.find((v) => v.id === selectedPO.vendor)?.vendorEmail || [];
      const prefilledEmails =
        selectedPO.emailAddresses?.length > 0
          ? selectedPO.emailAddresses
          : vendorEmail
          ? [vendorEmail]
          : [];

      setEmailAddresses(prefilledEmails);
      setLineItems(getAllLines(selectedPO));
      setReturnQuantities(getInitialReturnQuantities(selectedPO)); // Set return quantities when PO changes
    }
  }, [selectedPO, vendors]);

  useEffect(() => {
    if (id) {
      const po = purchaseOrders.find((po) => po.id === id);
      console.log("PO:", po);
      if (po) {
        setSelectedPO(po);
        setIsEdit(true);
        setRequiredDate(dayjs(po.returnOrder.requiredDate));
        setShipping(po.returnOrder.shipping);
        setOther(po.returnOrder.other);
        setLineItems(getAllLines(po));
        setReturnQuantities(getInitialReturnQuantities(po)); // Set initial return quantities from PO lines
        setShipFromAddress(po.returnOrder.shipFromAddress);
        setNotes(po.returnOrder.notes);

      }
    }
  }, [id, purchaseOrders]);

  useEffect(() => {
    const hasValidQuantities = Object.values(returnQuantities).some(
      (qty) => qty > 0
    );
    const canSubmit =
      hasValidQuantities && !hasInvalidQty && requiredDate && shipFromAddress;
    setCanSubmit(canSubmit);
  }, [returnQuantities, hasInvalidQty, requiredDate, shipFromAddress]);


  useEffect(() => {
    calculateTotals();
  }, [returnQuantities, shipping, other]);

  const getAllLines = (po) => {
    return po.lines.map((line) => ({
      ...line,
      qtyShipped: line.qtyShipped || 0,
      qtyToReturn: returnQuantities[line.lineNumber] || line.qtyReturn || 0,
    }));
  };

  const getInitialReturnQuantities = (po) => {
    const initialQuantities = {};
    po.lines.forEach((line) => {
      initialQuantities[line.lineNumber] = line.qtyReturn || 0;
    });
    return initialQuantities;
  };

  const handlePOChange = (value) => {
    const po = purchaseOrders.find((po) => po.id === value);

    po.lines.sort(
      (a, b) => parseInt(a.lineNumber, 10) - parseInt(b.lineNumber, 10)
    );

    setSelectedPO(po);
    setIsEdit(false);
  };

  const handleQtyChange = (lineNumber, value) => {
    const line = selectedPO.lines.find(
      (line) => line.lineNumber === lineNumber
    );
    const maxReturnableQty = line.qtyReceived || 0;

    const adjustedValue = Math.max(0, Math.min(value, maxReturnableQty)); // Ensure the value is between 0 and qtyReceived

    setReturnQuantities((prev) => ({
      ...prev,
      [lineNumber]: adjustedValue,
    }));

    // Update the line items to reflect the changes in return quantities
    setLineItems((prevItems) =>
      prevItems.map((item) =>
        item.lineNumber === lineNumber
          ? {
              ...item,
              qtyToReturn: adjustedValue,
            }
          : item
      )
    );

    const isInvalid = Object.keys(returnQuantities).some(
      (key) =>
        returnQuantities[key] >
        selectedPO.lines.find((line) => line.lineNumber === key)?.qtyReceived
    );

    setHasInvalidQty(isInvalid);
  };


  const calculateSubtotal = () => {
    return Object.keys(returnQuantities).reduce((total, lineNumber) => {
      const line = lineItems.find((item) => item.lineNumber === lineNumber);

      if (!line) {
        console.error(`Line with lineNumber ${lineNumber} not found`);
        return total; // Skip this line if not found
      }

      const returnQty = returnQuantities[lineNumber] || 0;
      const itemPrice = line.itemPrice || 0;

      return total + returnQty * itemPrice;
    }, 0);
  };

  const calculateTotals = () => {
    const newSubtotal = calculateSubtotal();
    setSubtotal(newSubtotal);
    setTotal(newSubtotal + shipping + other);
  };

  const handleDateChange = (date) => {
    setRequiredDate(date && date.isValid() ? date : null);
  };

const handleSubmit = async () => {
  try {
    setLoading(true); // Set loading state to true

    if (!isEdit) {
      await refreshPurchaseOrders();
    }

    const allPONumbers = purchaseOrders
      .map((po) => po.roNumber)
      .filter(Boolean);
    let nextRONumber = "AR-1";
    if (allPONumbers.length > 0) {
      const highestRONumber = Math.max(
        ...allPONumbers.map((roNum) => parseInt(roNum.split("-")[1], 10))
      );
      nextRONumber = `AR-${highestRONumber + 1}`;
    }

    console.log("ALL PO NUMBERS :", allPONumbers);

    const itemsToReturn = lineItems.filter(
      (item) => returnQuantities[item.lineNumber] > 0
    );

    if (itemsToReturn.length === 0) {
      message.error("Please enter a quantity to return for at least one item.");
      setLoading(false); // Set loading state to false
      return;
    }

    const updatedPO = {
      ...selectedPO,
      roNumber: selectedPO.roNumber || nextRONumber,
      returnOrder: {
        ...selectedPO.returnOrder,
        orderedDate: dayjs().format("MM/DD/YYYY"),
        shipFromAddress: shipFromAddress,
        requiredDate: dayjs(requiredDate).format("MM/DD/YYYY"),
        shipping: shipping,
        other: other,
        notes: notes,
        submittedBy: userEmail,
        documents: [...(selectedPO.returnOrder?.documents || [])],
      },
      lines: selectedPO.lines.map((line) => {
        const returnQty = returnQuantities[line.lineNumber] || 0;
        if (returnQty > 0) {
          return {
            ...line,
            qtyReturn: returnQty, // Replace instead of adding
            qtyShipped: line.qtyShipped || 0,
          };
        } else {
          // If returnQty is 0, remove qtyReturn and qtyShipped properties
          const { qtyReturn, qtyShipped, ...rest } = line;
          return rest;
        }
      }),
      emailAddresses: emailAddresses,
    };
    // Generate the PDF for the return order
    const pdfBlob = await generateReturnPdf({
      poLines: updatedPO.lines.filter((line) => line.qtyReturn > 0),
      requiredDate: updatedPO.returnOrder.requiredDate,
      notes: updatedPO.returnOrder.notes,
      shipping: updatedPO.returnOrder.shipping,
      other: updatedPO.returnOrder.other,
      subtotal: subtotal,
      totalPO: total,
      shipFromAddress: updatedPO.returnOrder.shipFromAddress,
      selectedVendor: vendors.find((v) => v.id === updatedPO.vendor),
      userName: userName,
      userEmail: userEmail,
      project: getProjectName(selectedPO.projectNumber, projects),
      roNumber: isEdit ? selectedPO.roNumber : updatedPO.roNumber,
      vendorName: getVendorName(updatedPO.vendor, vendors),
    });

    // Create a File object from the Blob
    const file = new File([pdfBlob], `${updatedPO.roNumber}.pdf`, {
      type: "application/pdf",
    });

    // Upload the PDF
    const fileUrl = await s3Upload(
      file,
      isEdit
        ? `[REVISED] Return-${
            updatedPO.roNumber
          }-BMarko-${new Date().toISOString()}.pdf`
        : `Return-${updatedPO.roNumber}-Bmarko-${new Date().toISOString()}.pdf`
    );
    console.log("Uploaded PDF URL:", fileUrl);



    // Add the document information to the updatedPO
    updatedPO.returnOrder.documents.push({
      dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
      documentUrl: fileUrl,
      number: (selectedPO.returnOrder?.documents?.length || 0) + 1,
      uuid: uuidv4(),
    });

    // Save the updated purchase order to the database using updateById
    await updateById(TABLES.PURCHASE_ORDERS, updatedPO, selectedPO.id);
    console.log("Purchase Order updated:", updatedPO);

    // Prepare the email body
    let emailBody;

    if (!isEdit) {
      emailBody = EMAILBODY.RETURN_ORDER({
        poNum: updatedPO.roNumber,
        vendorFirstName:
          vendors.find((v) => v.id === updatedPO.vendor)?.vendorFirstName || "",
        vendor: getVendorName(updatedPO.vendor, vendors),
        reqDateFormatted: dayjs(updatedPO.returnOrder.requiredDate).format(
          "MM/DD/YYYY"
        ),
        capFirstName: userName.split(" ")[0],
        project: getProjectName(selectedPO.projectNumber, projects),
        shipFromAddress: shipFromAddress,
      });
    } else {
      emailBody = EMAILBODY.REVISED_RETURN_ORDER({
        poNum: selectedPO.roNumber,
        vendorFirstName:
          vendors.find((v) => v.id === updatedPO.vendor)?.vendorFirstName || "",
        vendor: getVendorName(updatedPO.vendor, vendors),
        reqDateFormatted: dayjs(updatedPO.returnOrder.requiredDate).format(
          "MM/DD/YYYY"
        ),
        originalDate: dayjs(selectedPO.returnOrder.orderedDate).format(
          "MM/DD/YYYY"
        ),
        capFirstName: userName.split(" ")[0],
        project: getProjectName(selectedPO.projectNumber, projects),
        shipFromAddress: shipFromAddress,
      });
    }

    // Send the email
    await sendEmail({
      subject: emailBody.subject,
      htmlBody: emailBody.body,
      emailAddresses: emailAddresses,
      attachmentUrls: [fileUrl],
      sender: userEmail,
    });

    message.success(
      "Return quantities added, PDF uploaded, email sent, and purchase order updated successfully!"
    );

    // Final cleanup and navigation

    await refreshPurchaseOrders();

    if (isEdit) {
      navigate("/purchasing/returns/edit-return-orders");
    }

    setLoading(false); // Ensure loading state is reset
    setSelectedPO(null);
    setReturnQuantities({});
    setRequiredDate(null);
  } catch (error) {
    console.error("Error processing return order:", error);
    message.error("Failed to process the return order.");
    setLoading(false);
  }
};


const deleteReturnOrder = async (purchaseOrderId) => {
  try {
    // Find the purchase order by ID
    const purchaseOrder = purchaseOrders.find(
      (po) => po.id === purchaseOrderId
    );

    if (!purchaseOrder) {
      throw new Error("Purchase order not found");
    }

    // Get the last document in the returnOrder documents array
    const lastDocument =
      purchaseOrder.returnOrder.documents[
        purchaseOrder.returnOrder.documents.length - 1
      ];

    // Prepare the email body
    const emailBody = EMAILBODY.CANCELLED_RETURN_ORDER({
      poNum: purchaseOrder.roNumber,
      vendor: getVendorName(purchaseOrder.vendor, vendors),
      vendorFirstName:
        vendors.find((v) => v.id === purchaseOrder.vendor)?.vendorFirstName ||
        "",
      capFirstName: userName.split(" ")[0],
      reqDateFormatted: dayjs(purchaseOrder.returnOrder.requiredDate).format(
        "MM/DD/YYYY"
      ),
      originalDate: dayjs(purchaseOrder.returnOrder.orderedDate).format(
        "MM/DD/YYYY"
      ),
    });

    // Prepare the attachment URL with the last document's URL
    const attachmentUrls = lastDocument ? [lastDocument.documentUrl] : [];

    // Send the cancellation email
    await sendEmail({
      subject: emailBody.subject,
      htmlBody: emailBody.body,
      emailAddresses: purchaseOrder.emailAddresses,
      attachmentUrls: attachmentUrls,
      sender: userEmail,
    });

    // Create a new object without the returnOrder and with updated lines
    const updatedPO = {
      ...purchaseOrder,
      returnOrder: null, // Remove the returnOrder
      lines: purchaseOrder.lines.map((line) => {
        // Remove qtyShipped and qtyReturn from each line
        const { qtyShipped, qtyReturn, ...rest } = line;
        return rest;
      }),
    };

    // Update the purchase order in the database
    await updateById(TABLES.PURCHASE_ORDERS, updatedPO, purchaseOrderId);

    message.success(
      "Return order cancelled, email sent, and data updated successfully."
    );

    await refreshPurchaseOrders();

    // Navigate to the edit-return-orders page
    navigate("/purchasing/returns/edit-return-orders");
  } catch (error) {
    console.error("Error deleting return order:", error);
    message.error("Failed to delete the return order.");
  }
};


// Confirmation and cancellation logic for the Cancel button
const handleCancelClick = () => {
  Modal.confirm({
    title: "Are you sure you want to cancel this return order?",
    content:
      "This action will remove the return order and all associated return quantities.",
    okText: "Yes, Cancel",
    okType: "danger",
    cancelText: "No",
    onOk: () => deleteReturnOrder(selectedPO.id),
  });
};



  const handleRemoveEmail = (email) => {
    setEmailAddresses((prevEmails) =>
      prevEmails.filter((existingEmail) => existingEmail !== email)
    );
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleAddEmail = () => {
    if (emailRegex.test(newEmail) && !emailAddresses.includes(newEmail)) {
      setEmailAddresses((prevEmails) => [...prevEmails, newEmail]);
      setNewEmail("");
      setPopoverVisible(false);
    } else {
      message.error("Invalid email or email already exists.");
    }
  };

  const emailContent = (
    <div>
      <Input
        placeholder="Enter email"
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        onPressEnter={handleAddEmail}
        style={{ width: "200px", marginBottom: "10px" }}
      />
      <Button
        type="primary"
        style={{ marginLeft: 8 }}
        onClick={handleAddEmail}
        disabled={!emailRegex.test(newEmail)} // Disable Add button unless the email is valid
      >
        Add
      </Button>
    </div>
  );

  const extractPoNumber = (poNumber) => {
    const match = poNumber.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 0;
  };

  // const canSubmit = !(
  //   Object.values(returnQuantities).every((qty) => qty <= 0) ||
  //   hasInvalidQty ||
  //   !requiredDate ||
  //   !shipFromAddress
  // );

 const columns = [
   {
     title: "Line Number",
     dataIndex: "lineNumber",
     key: "lineNumber",
   },
   {
     title: "Item Description",
     dataIndex: "itemDescription",
     key: "itemDescription",
   },
   {
     title: "Manufacturer",
     dataIndex: "manufacturer",
     key: "manufacturer",
   },
   {
     title: "Qty Ordered",
     dataIndex: "qtyOrdered",
     key: "qtyOrdered",
   },
   {
     title: "Price",
     dataIndex: "itemPrice",
     key: "itemPrice",
     render: (text) => formatMoney(text || 0),
   },
   {
     title: "Total",
     dataIndex: "total",
     key: "total",
     render: (_, record) =>
       formatMoney((record.qtyOrdered || 0) * (record.itemPrice || 0)),
   },
   {
     title: "Qty Received",
     dataIndex: "qtyReceived",
     key: "qtyReceived",
   },
   ...(isEdit
     ? [
         {
           title: "Qty Shipped",
           dataIndex: "qtyShipped",
           key: "qtyShipped",
         },
       ]
     : []),
   {
     title: "Qty to Return",
     dataIndex: "qtyToReturn",
     key: "qtyToReturn",
     render: (_, record) => (
       <InputNumber
         min={0}
         type="number"
         max={record.qtyReceived || 0}
         value={returnQuantities[record.lineNumber] || 0}
         onChange={(value) => handleQtyChange(record.lineNumber, value)}
       />
     ),
   },
   {
     title: "Return Total",
     dataIndex: "returnTotal",
     key: "returnTotal",
     render: (_, record) => {
       const returnTotal =
         (returnQuantities[record.lineNumber] || 0) * (record.itemPrice || 0);
       return formatMoney(returnTotal);
     },
   },
 ];


  return (
    <>
      {loading && (
        <div style={overlayStyle}>
          <Spin size="large" />
        </div>
      )}
      <Form form={form}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col
            span={isEdit ? 12 : 8}
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!isEdit && (
              <Select
                placeholder="Select Purchase Order"
                style={{ width: "100%" }}
                onChange={handlePOChange}
                value={selectedPO ? selectedPO.id : undefined}
                loading={poLoading}
                showSearch
                disabled={isEdit}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  const poNumber = option.children[0].toLowerCase();
                  const vendorName = getVendorName(
                    option.vendor,
                    vendors
                  ).toLowerCase();
                  return (
                    poNumber.includes(input.toLowerCase()) ||
                    vendorName.includes(input.toLowerCase())
                  );
                }}
              >
                {purchaseOrders
                  .filter(
                    (po) =>
                      po.lines.some((line) => line.qtyReceived > 0) &&
                      !po.returnOrder
                  )
                  .sort((a, b) => {
                    const alphaComparison = b.poNumber.localeCompare(
                      a.poNumber
                    );
                    if (alphaComparison !== 0) return alphaComparison;
                    return (
                      extractPoNumber(b.poNumber) - extractPoNumber(a.poNumber)
                    );
                  })
                  .map((po) => (
                    <Option key={po.id} value={po.id} vendor={po.vendor}>
                      {po.poNumber} - {getVendorName(po.vendor, vendors)}
                    </Option>
                  ))}
              </Select>
            )}

            {isEdit && (
              <Card
                title={
                  <>
                    <span>{selectedPO.poNumber} - </span>
                    <span>{getVendorName(selectedPO.vendor, vendors)}</span>
                  </>
                }
                extra={
                  <>
                    <Tag>Return: {selectedPO.roNumber}</Tag>
                    <Tag>
                      Project:{" "}
                      {getProjectName(selectedPO.projectNumber, projects)}
                    </Tag>
                  </>
                }
                size="small"
                className="box-shadow"
                bodyStyle={{ padding: selectedPO.groupNumber ? 6 : 0 }}
                style={{ width: "100%" }}
              ></Card>
            )}

            <div style={{ marginRight: 16 }} />
            {!isEdit && (
              <Form.Item label="Project" style={{ margin: 0 }}>
                <Tag>{getProjectName(selectedPO?.projectNumber, projects)}</Tag>
              </Form.Item>
            )}
          </Col>
          <Col span={isEdit ? 0 : 8} />
          <Col span={isEdit ? 12 : 8} style={{ textAlign: "right" }}>
            {isEdit && (
              <Button
                onClick={handleCancelClick}
                danger
                icon={<DeleteOutlined />}
                style={{ marginRight: 16 }}
              >
                Cancel Return Order
              </Button>
            )}
            <Button
              type="primary"
              onClick={handleSubmit}
              icon={<RocketOutlined />}
              disabled={!canSubmit || loading} // Disable submit button unless conditions are met and avoid multiple submissions
            >
              Submit Return Order
            </Button>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Form.Item label="Email" style={{ margin: 0 }}>
              <div>
                {emailAddresses.length > 0 &&
                  emailAddresses.map((email) => (
                    <Tag
                      closable
                      onClose={() => handleRemoveEmail(email)}
                      key={email}
                      style={{ marginTop: 4 }}
                      color={COLORS.PRIMARY}
                    >
                      {email}
                    </Tag>
                  ))}
                <Popover
                  content={emailContent}
                  title="Add Email"
                  trigger="click"
                  visible={popoverVisible}
                  onVisibleChange={setPopoverVisible}
                >
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 8 }}
                  />
                </Popover>
              </div>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Ship From Address" style={{ margin: 0 }}>
              <AddressAutocomplete
                form={form}
                value={shipFromAddress}
                initialValue={shipFromAddress}
                onChange={setShipFromAddress}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Required Date" style={{ margin: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                value={requiredDate}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col span={16} />
        </Row>

        <Table
          dataSource={lineItems}
          columns={columns}
          rowKey="lineNumber"
          pagination={false}
          size="small"
          rowClassName={(record) =>
            returnQuantities[record.lineNumber] > 0 ? "expanded-row" : ""
          }
        />

        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={4}/>
          <Col span={5}>
            <Form.Item label="Shipping">
              <InputNumber
                min={0}
                value={shipping}
                onChange={(value) => setShipping(value)}
                type="number"
                prefix="$"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Other">
              <InputNumber
                min={0}
                value={other}
                onChange={(value) => setOther(value)}
                type="number"
                prefix="$"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Subtotal" style={{ display: "none" }}>
              {/* Original InputNumber is hidden */}
              <InputNumber
                disabled
                value={subtotal.toFixed(2)}
                type="number"
                prefix="$"
                style={{ width: "100%" }}
              />
            </Form.Item>

            {/* Add the Subtotal as a Tag outside the hidden Form.Item */}
            <Form.Item label="Subtotal" style={{ marginBottom: 0 }}>
              <Tag
                color="green"
                style={{
                  fontSize: "16px",
                  width: "100%",
                  padding: 6,
                  textAlign: "center",
                  opacity: 0.9,
                }}
              >
                {formatMoney(subtotal)}
              </Tag>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Total" style={{ fontWeight: 600 }}>
              <Tag
                color="green"
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  width: "100%",
                  padding: 6,
                }}
              >
                <strong>{formatMoney(total)}</strong>
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <Form.Item label="Notes">
              <TextArea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
  zIndex: 1000, // Ensures it's on top of other elements
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default AddReturnOrder;
