// AddVendorModal.jsx

import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button, Switch, Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES, COLORS } from "../../constants";
import AddressAutocomplete from "../Google/AddressAutocomplete";

const AddVendorModal = ({
  children,
  onAddSuccess,
  vendor,
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isOnlineOrder, setIsOnlineOrder] = useState(false);

  useEffect(() => {
    if (visible) {
      if (vendor) {
        console.log("Vendor:", vendor);
        form.setFieldsValue({
          companyName: vendor.companyName,
          address: vendor.googleAddress?.formatted_address || "",
          suite: vendor.suite || "",
          googleAddress: vendor.googleAddress || {},
          vendorFirstName: vendor.vendorFirstName,
          vendorLastName: vendor.vendorLastName,
          vendorEmail: vendor.vendorEmail,
          vendorPhone: vendor.vendorPhone,
          vendorMobilePhone: vendor.vendorMobilePhone,
          onlineOrder: vendor.onlineOrder,
          additionalEmails: vendor.additionalEmails || [],
        });
        setIsOnlineOrder(vendor.onlineOrder);
      } else {
        form.resetFields();
        setIsOnlineOrder(false);
      }
    }
  }, [visible, vendor, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      // Prepare values
      const payload = {
        ...values,
        onlineOrder: isOnlineOrder,
        additionalEmails: values.additionalEmails
          ? values.additionalEmails.filter((email) => email)
          : [],
        address: values.googleAddress?.formatted_address || "", // Ensure address is set from googleAddress
      };

      // Optionally, you might want to remove googleAddress from payload if not needed
      // delete payload.googleAddress;

      if (vendor) {
        await updateById(TABLES.VENDORS, payload, vendor.id);
      } else {
        await addItemsToTable(TABLES.VENDORS, payload);
      }

      setLoading(false);
      setVisible(false);
      onAddSuccess(payload);
    } catch (error) {
      setLoading(false);
      console.error("Validation Failed:", error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={vendor ? "Edit Vendor" : "Add New Vendor"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {vendor ? "Save Changes" : "Add Vendor"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            additionalEmails: vendor ? vendor.additionalEmails : [],
            onlineOrder: vendor ? vendor.onlineOrder : false,
          }}
        >
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[
              { required: true, message: "Please enter the company name" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="onlineOrder"
            label="Online Order"
            valuePropName="checked"
          >
            <Switch onChange={(checked) => setIsOnlineOrder(checked)} />
          </Form.Item>

          {!isOnlineOrder && (
            <>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please enter the address" },
                ]}
              >
                <AddressAutocomplete form={form} />
              </Form.Item>
              <Form.Item name="suite" label="Suite">
                <Input />
              </Form.Item>
            </>
          )}

          {/* Hidden Form.Item for googleAddress */}
          <Form.Item name="googleAddress" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="vendorFirstName"
                label="Contact First Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the contact first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vendorLastName"
                label="Contact Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the contact last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="vendorPhone"
                label="Phone"
                rules={[
                  {
                    required: !isOnlineOrder,
                    message: "Please enter the phone number",
                  },
                ]}
              >
                <PhoneInput
                  country={"us"}
                  value={form.getFieldValue("vendorPhone")}
                  onChange={(value) =>
                    form.setFieldsValue({ vendorPhone: value })
                  }
                  inputStyle={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="vendorMobilePhone" label="Mobile Phone">
                <PhoneInput
                  country={"us"}
                  value={form.getFieldValue("vendorMobilePhone")}
                  onChange={(value) =>
                    form.setFieldsValue({ vendorMobilePhone: value })
                  }
                  inputStyle={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="vendorEmail"
            label="Primary Email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
              {
                required: !isOnlineOrder,
                message: "Please enter the email address",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.List name="additionalEmails">
            {(fields, { add, remove }) => (
              <>
                <Col span={24} style={{ marginBottom: 8 }}>
                  Additional Email Addresses
                </Col>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={8}>
                    <Col span={20}>
                      <Form.Item
                        {...restField}
                        name={name}
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                          {
                            required: true,
                            message:
                              "Please enter an email address or remove the field",
                          },
                        ]}
                      >
                        <Input placeholder="Enter additional email" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="danger"
                        onClick={() => remove(name)}
                        icon={
                          <DeleteOutlined
                            color={COLORS.WARNING}
                            style={{ color: COLORS.WARNING }}
                          />
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Additional Email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default AddVendorModal;
