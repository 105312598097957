import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Regular.ttf",
    bold: "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-SemiBold.ttf",
    italics:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Black.ttf",
    bolditalics:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Black.ttf",
  },
};

const redLine = "#DF1837";

const convertImgToDataURLviaCanvas = (url, callback) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    const canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL("image/png");
    callback(dataURL);
  };
  img.src = url;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = url;
  }
};

let company_logo = null;
let watermark_logo = null;
let powered_by_aria = null;

convertImgToDataURLviaCanvas(
  "https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png",
  (dataUrl) => {
    company_logo = dataUrl;
  }
);

convertImgToDataURLviaCanvas(
  "https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png",
  (dataUrl) => {
    watermark_logo = dataUrl;
  }
);

convertImgToDataURLviaCanvas(
  "https://images-for-aria.s3.us-east-2.amazonaws.com/powered-by-aria.png",
  (dataUrl) => {
    powered_by_aria = dataUrl;
  }
);

const generateRFQContent = (poLines, vendorDetails, userName, project) => {
  const today = dayjs().format("MM/DD/YYYY");
  const formattedRequiredDate = dayjs(poLines.requiredDate).format(
    "MM/DD/YYYY"
  );

  return {
    background: (currentPage, pageSize) => {
      return {
        image: "watermark",
        width: 400,
        absolutePosition: { x: 92, y: 400 },
        opacity: 0.055,
      };
    },
    pageMargins: [30, 30, 30, 60],
    footer: (currentPage, pageCount) => {
      return {
        table: {
          widths: [40, "*", 40],
          body: [
            [
              { text: "", alignment: "right" },
              {
                image: "ariapower",
                alignment: "center",
                width: 100,
                height: 24,
              },
              { text: pageCount, alignment: "left" },
            ],
          ],
        },
        layout: "noBorders",
      };
    },
    content: [
      {
        columns: [
          { image: "bmlogo", width: 200 },
          {
            stack: [
              {
                margin: [198, 2, 2, 2],
                layout: "noBorders",
                table: {
                  widths: [60, 80],
                  alignment: "right",
                  body: [
                    [
                      {
                        text: "Request for Quote",
                        colSpan: 2,
                        fontSize: 12,
                        alignment: "center",
                        bold: true,
                      },
                      {},
                    ],
                    [
                      { text: "Date:", fontSize: 8, alignment: "right" },
                      { text: today, fontSize: 8, alignment: "left" },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        margin: [0, 3, 0, 5],
        table: {
          widths: [400, 110],
          body: [
            [
              {
                text: "Bmarko Structures LLC.",
                fontSize: 8,
                alignment: "left",
                border: [false, false, false, false],
              },
              {
                text: `Required Date: ${formattedRequiredDate}`,
                fontSize: 8,
                alignment: "center",
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 533,
            h: 0,
            r: 1,
            lineColor: redLine,
          },
        ],
      },
      {
        style: "tableExample",
        color: "#444",
        margin: [20, 10, 20, 10],
        fontSize: 8,
        layout: "noBorders",
        table: {
          widths: [40, 270, 45, 150],
          body: [
            [
              { text: "Vendor:" },
              { text: vendorDetails.companyName || "" },
              { text: "Ship To:" },
              { text: poLines.shipToAddress.split(",")[0] || "" },
            ],
            [
              { text: "Name:" },
              { text: vendorDetails.contactName || "" },
              { text: "" },
              {
                text:
                  poLines.shipToAddress.split(",")[1] +
                  " " +
                  poLines.shipToAddress.split(",")[2],
              },
            ],
            [
              { text: "Email:" },
              { text: vendorDetails.email || "" },
              { text: "Requestor:" },
              { text: userName || "" },
            ],
            [
              { text: "Phone:" },
              { text: vendorDetails.phoneNumber || "" },
              { text: "Email:" },
              { text: poLines.userEmail || "" },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "Project:" },
              { text: project || "" },
            ],
          ],
        },
      },
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 533,
            h: 0,
            r: 1,
            lineColor: redLine,
          },
        ],
      },
      {
        style: "tableExample",
        margin: [0, 10, 0, 10],
        table: {
          widths: [15, 40, 160, "*", 60, 15, 20],
          alignment: "left",
          headerRows: 1,
          body: [
            [
              { text: "Line", bold: true, fontSize: 7, fillColor: "#F5F5F5" },
              {
                text: "Category",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Item Description",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Manufacturer",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Manufacturer ID",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              { text: "Qty", bold: true, fontSize: 7, fillColor: "#F5F5F5" },
              { text: "UOM", bold: true, fontSize: 7, fillColor: "#F5F5F5" },
            ],
            ...poLines.items.map((line, index) => [
              { text: index + 1, alignment: "left", fontSize: 7 },
              { text: line.category || "", alignment: "left", fontSize: 7 },
              { text: line.itemDescription, alignment: "left", fontSize: 7 },
              { text: line.manufacturer || "", alignment: "left", fontSize: 7 },
              {
                text: line.manufacturerId || "",
                alignment: "left",
                fontSize: 7,
              },
              { text: line.qty, alignment: "left", fontSize: 7 },
              { text: line.uom || "", alignment: "left", fontSize: 7 },
            ]),
          ],
        },
      },
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 533,
            h: 0,
            r: 1,
            lineColor: "black",
          },
        ],
      },
      {
        margin: [0, 10, 0, 0],
        columns: [
          poLines.notes
            ? {
                style: "tableExample",
                table: {
                  widths: [200],
                  body: [
                    [{ text: "Notes:", bold: true, fontSize: 8 }],
                    [{ text: poLines.notes, fontSize: 8 }],
                  ],
                },
              }
            : {},
        ],
      },
    ],
    images: {
      bmlogo: company_logo,
      watermark: watermark_logo,
      ariapower: powered_by_aria,
    },
  };
};


export const generateRFQBlob = async (
  poLines,
  vendorDetails,
  userName,
  project,
  requiredDate
) => {
  const docDefinition = generateRFQContent(
    poLines,
    vendorDetails,
    userName,
    project,
    requiredDate
  );
  return new Promise((resolve, reject) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      resolve(blob);
    });
  });
};

