import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";
import { formatMoney } from "../../Formatters/helpers";


// Configure pdfMake to use custom fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Regular.ttf",
    bold: "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-SemiBold.ttf",
    italics:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Black.ttf",
    bolditalics:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Black.ttf",
  },
};

const redLine = "#DF1837";

const convertImgToDataURLviaCanvas = (url, callback) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    const canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL("image/png");
    callback(dataURL);
  };
  img.src = url;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = url;
  }
};

let company_logo = null;
let watermark_logo = null;
let powered_by_aria = null;

// Load your images as Data URLs
convertImgToDataURLviaCanvas(
  "https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png",
  (dataUrl) => {
    company_logo = dataUrl;
  }
);

convertImgToDataURLviaCanvas(
  "https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png",
  (dataUrl) => {
    watermark_logo = dataUrl;
  }
);

convertImgToDataURLviaCanvas(
  "https://images-for-aria.s3.us-east-2.amazonaws.com/powered-by-aria.png",
  (dataUrl) => {
    powered_by_aria = dataUrl;
  }
);

const generatePdfContent = (
  poLines,
  requiredDate,
  notes,
  shipping,
  other,
  discount,
  subtotal,
  totalPO,
  purchaseOrderData,
  userName,
  userEmail,
  selectedVendor,
  isEdit,
  originalPoNumber,
  originalPoDate
) => {
  const today = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedRequiredDate = requiredDate
    ? dayjs(requiredDate).format("MM/DD/YYYY")
    : "";

  return {
    background: (currentPage, pageSize) => {
      return {
        image: "watermark",
        width: 400,
        absolutePosition: { x: 92, y: 400 },
        opacity: 0.055,
      };
    },
    pageMargins: [30, 30, 30, 60],
    footer: (currentPage, pageCount) => {
      return {
        table: {
          widths: [40, "*", 40],
          body: [
            [
              { text: "", alignment: "center" },
              {
                text: "This Purchase Order is subject to Bmarko's Standard Terms and Conditions which are incorporated by reference as if fully set forth herein. \n The Standard Terms and Conditions are located at https://bmarkostructures.com/purchase-order-terms/",
                alignment: "center",
                fontSize: 7,
              },
              { text: "", alignment: "center" },
            ],
            [
              { text: "", alignment: "right" },
              {
                image: "ariapower",
                alignment: "center",
                width: 100,
                height: 24,
              },
              { text: pageCount, alignment: "left" },
            ],
          ],
        },
        layout: "noBorders",
      };
    },
    content: [
      {
        columns: [
          { image: "bmlogo", width: 200 },
          {
            stack: [
              {
                margin: [198, 2, 2, 2],
                layout: "noBorders",
                table: {
                  widths: [60, 80],
                  alignment: "right",
                  body: [
                    [
                      {
                        text: isEdit
                          ? "Revised Purchase Order"
                          : "Purchase Order",
                        colSpan: 2,
                        fontSize: 12,
                        alignment: "center",
                        bold: true,
                      },
                      {},
                    ],
                    [
                      { text: "Date:", fontSize: 8, alignment: "right" },
                      { text: today, fontSize: 8, alignment: "left" },
                    ],
                    [
                      { text: "PO#", fontSize: 8, alignment: "right" },
                      {
                        text: purchaseOrderData.poNumber,
                        fontSize: 8,
                        alignment: "left",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        margin: [0, 3, 0, 5],
        table: {
          widths: [400, 110],
          body: [
            [
              {
                text: "Bmarko Structures LLC.",
                fontSize: 8,
                alignment: "left",
                border: [false, false, false, false],
              },
              {
                text: `Required Date: ${formattedRequiredDate}`,
                fontSize: 8,
                alignment: "center",
                border: [false, false, false, false],
                fillColor: "yellow",
              },
            ],
          ],
        },
      },
      ...(isEdit
        ? [
            {
              text: `This Revised Purchase Order supersedes the original PO# ${originalPoNumber} sent on ${dayjs(
                originalPoDate
              ).format("MM/DD/YYYY")}`,
              alignment: "center",
              margin: [0, 4, 0, 4],
              fontSize: 8,
              bold: true,
            },
          ]
        : []),
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 533,
            h: 0,
            r: 1,
            lineColor: redLine, // Red line above vendor info
          },
        ],
      },
      {
        style: "tableExample",
        color: "#444",
        margin: [20, 10, 20, 10],
        fontSize: 8,
        layout: "noBorders",
        table: {
          widths: [40, 270, 45, 150],
          body: [
            [
              { text: "Vendor:" },
              { text: selectedVendor.name },
              { text: "Ship To:" },
              { text: purchaseOrderData.shipToAddress.split(",")[0] },
            ],
            [
              { text: "Name:" },
              { text: selectedVendor.contactName },
              { text: "" },
              {
                text:
                  purchaseOrderData.shipToAddress.split(",")[1] +
                  " " +
                  purchaseOrderData.shipToAddress.split(",")[2],
              },
            ],
            [
              { text: "Email:" },
              { text: selectedVendor.email },
              { text: "Requestor:" },
              { text: userName },
            ],
            [
              { text: "Phone:" },
              { text: selectedVendor.phone },
              { text: "Email:" },
              { text: userEmail },
            ],
            [
              { text: "" },
              { text: "" },
              { text: "Project:" },
              { text: purchaseOrderData.selectedProject },
            ],
          ],
        },
      },

      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 533,
            h: 0,
            r: 1,
            lineColor: redLine, // Horizontal line above table
          },
        ],
      },
      {
        style: "tableExample",
        margin: [0, 10, 0, 10],
        table: {
          widths: [15, 40, 160, "*", 60, 15, 20, 40, 50],
          alignment: "left",
          headerRows: 1,
          body: [
            [
              {
                text: "Line",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Category",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Item Description",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Manufacturer",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Manufacturer ID",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Qty",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "UOM",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Unit Price",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
              {
                text: "Total",
                bold: true,
                fontSize: 7,
                fillColor: "#F5F5F5",
              },
            ],
            ...poLines.map((line, index) => [
              {
                text: String(index + 1).padStart(2, "0"),
                alignment: "left",
                fontSize: 7,
              },
              { text: line.category || "", alignment: "left", fontSize: 7 },
              {
                text: line.itemDescription,
                alignment: "left",
                fontSize: 7,
              },
              {
                text: line.manufacturer || "",
                alignment: "left",
                fontSize: 7,
              },
              {
                text: line.manufacturerId || "",
                alignment: "left",
                fontSize: 7,
              },
              { text: line.uom || "", alignment: "left", fontSize: 7 },
              { text: line.qty, alignment: "left", fontSize: 7 },
              {
                text: formatMoney(line.price),
                alignment: "left",
                fontSize: 7,
              },
              {
                text: formatMoney(line.total),
                alignment: "left",
                fontSize: 7,
              },
            ]),
          ],
        },
      },
      {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 533,
            h: 0,
            r: 1,
            lineColor: "black", // Horizontal line after table
          },
        ],
      },
      {
        margin: [0, 10, 0, 0],
        columns: [
          notes
            ? {
                style: "tableExample",
                table: {
                  widths: [200],
                  body: [
                    [{ text: "Notes:", bold: true, fontSize: 8 }],
                    [{ text: notes, fontSize: 8 }],
                  ],
                },
              }
            : {},
          {
            stack: [
              {
                style: "tableExample",
                margin: [100, 9, 0, 0],
                layout: "noBorders",
                table: {
                  widths: [60, 100],
                  body: [
                    [
                      {
                        text: "SUBTOTAL",
                        fontSize: 7,
                      },
                      {
                        text: formatMoney(subtotal),
                        fontSize: 7,
                      },
                    ],
                    [
                      {
                        text: "SHIPPING",
                        fontSize: 7,
                      },
                      {
                        text:
                          shipping && shipping !== "0"
                            ? formatMoney(shipping)
                            : "",
                        fontSize: 7,
                      },
                    ],
                    [
                      {
                        text: "OTHER",
                        fontSize: 7,
                      },
                      {
                        text: other && other !== "0" ? formatMoney(other) : "",
                        fontSize: 7,
                      },
                    ],
                    discount
                      ? [
                          {
                            text: "DISCOUNT",
                            fontSize: 7,
                          },
                          {
                            text: "-" + formatMoney(discount),
                            fontSize: 7,
                          },
                        ]
                      : [{}, {}],
                  ],
                },
              },
              {
                // Horizontal line always above the TOTAL
                canvas: [
                  {
                    type: "rect",
                    x: 85,
                    y: 0,
                    w: 145,
                    h: 0,
                    r: 1,
                    lineColor: "black",
                  },
                ],
              },
              {
                style: "tableExample",
                margin: [100, 9, 0, 0],
                layout: "noBorders",
                table: {
                  widths: [60, 100],
                  body: [
                    [
                      {
                        text: "TOTAL",
                        bold: true,
                        fontSize: 9,
                      },
                      {
                        text: formatMoney(totalPO),
                        bold: true,
                        fontSize: 9,
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
    images: {
      bmlogo: company_logo,
      watermark: watermark_logo,
      ariapower: powered_by_aria,
    },
  };
};


export const generatePdfBlob = async (purchaseOrderData, isEdit,originalPoNumber,originalPoDate) => {
    console.log("purchaseOrderData", purchaseOrderData);

  return new Promise((resolve, reject) => {
    const {
      poLines,
      requiredDate,
      notes,
      shipping,
      other,
      discount,
      subtotal,
      totalPO,
      additionalTables,
      selectedVendor, // Add selectedVendor here
      userName, // Add userName here
      userEmail, // Add userEmail here
    } = purchaseOrderData;

    const pdfDocs = [
      generatePdfContent(
        poLines,
        requiredDate,
        notes,
        shipping,
        other,
        discount,
        subtotal,
        totalPO,
        purchaseOrderData,
        userName,
        userEmail,
        selectedVendor, // Pass selectedVendor to generatePdfContent
        isEdit,
        originalPoNumber,
        originalPoDate,
      ),
    ];

    if (additionalTables.length > 0) {
      additionalTables.forEach((table) => {
        const additionalSubtotal = table.poLines.reduce(
          (sum, line) => sum + line.total,
          0
        );
        const additionalTotalPO =
          additionalSubtotal + table.shipping + table.other - table.discount;

        const pdfDoc = generatePdfContent(
          table.poLines,
          table.requiredDate
            ? dayjs(table.requiredDate).format("MM/DD/YYYY")
            : "",
          table.notes,
          table.shipping,
          table.other,
          table.discount,
          additionalSubtotal,
          additionalTotalPO,
          purchaseOrderData,
          userName,
          userEmail,
          selectedVendor // Pass selectedVendor to generatePdfContent
        );

        pdfDocs.push(pdfDoc);
      });
    }

    try {
      const pdfDocGenerator = pdfMake.createPdf(pdfDocs[0]);

      pdfDocGenerator.getBlob((blob) => {
        resolve(blob);
      });
    } catch (error) {
      reject(error);
    }
  });
};

