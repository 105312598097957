import React, { useEffect, useState, useCallback, useRef } from "react";
import { message, Tooltip } from "antd";
import { useDropzone } from "react-dropzone";
import { Loader } from "../../Styled/Loader";
import { listFiles, uploadFile } from "../../Functions/google";

const renderFileIcon = (file) => {
  return (
    <img
      src={
        file.iconLink ||
        "https://www.iconfinder.com/data/icons/file-types-23/512/file_type_unknown-512.png"
      }
      alt={file.name}
      style={{
        width: 24,
        height: 24,
        position: "absolute",
        top: 8,
        right: 8,
        zIndex: 2,
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderRadius: "4px",
      }}
    />
  );
};

const renderFilePreview = (file) => {
  return (
    <div
      onClick={() => window.open(file.webViewLink, "_blank")}
      style={{
        position: "relative",
        width: 140,
        height: 140,
        overflow: "hidden",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        background: "#fff",
        padding: "10px",
        boxSizing: "border-box",
        cursor: "pointer",
      }}
    >
      <div style={{ position: "relative", height: "70%" }}>
        {file.thumbnailLink ? (
          <img
            src={file.thumbnailLink}
            alt={file.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
            }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f0f0",
              color: "#999",
            }}
          >
            No Preview
          </div>
        )}
        {renderFileIcon(file)}
      </div>
      <Tooltip title={file.name}>
        <div
          style={{
            position: "relative",
            marginTop: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {file.name}
        </div>
      </Tooltip>
    </div>
  );
};

const FileList = ({ files }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
      {files.map((file) => (
        <div key={file.id} style={{ display: "block", textDecoration: "none" }}>
          {renderFilePreview(file)}
        </div>
      ))}
    </div>
  );
};

const FilePreview = ({ googleFolderId }) => {
  const [files, setFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const firstLoadRef = useRef(true);
  const containerRef = useRef(null);

  const fetchFiles = async (showLoading = false) => {
    if (!googleFolderId) return;
    if (showLoading) {
      setFileLoading(true);
    }
    try {
      const data = await listFiles(googleFolderId);
      console.log("Files fetched:", data.files);
      setFiles(data.files);
    } catch (error) {
      console.error("Failed to fetch files:", error);
      message.error("Failed to fetch files.");
    } finally {
      if (showLoading) {
        setFileLoading(false);
      }
    }
  };

  useEffect(() => {
    if (firstLoadRef.current) {
      fetchFiles(true);
      firstLoadRef.current = false;
    } else {
      fetchFiles();
    }
  }, [googleFolderId]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!googleFolderId) return;
      setFileLoading(true);
      try {
        for (const file of acceptedFiles) {
          await uploadFile(file, googleFolderId);
        }
        message.success("Files uploaded successfully!");
        fetchFiles();
      } catch (error) {
        console.error("Failed to upload files:", error);
        message.error("Failed to upload files.");
      } finally {
        setFileLoading(false);
      }
    },
    [googleFolderId]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchFiles();
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [googleFolderId]);

  return (
    <div ref={containerRef}>
      {fileLoading ? (
        <Loader tip="Loading files..." />
      ) : (
        <>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #d9d9d9",
              padding: "20px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag and drop files here, or click to select files</p>
          </div>
          <FileList files={files} />
        </>
      )}
    </div>
  );
};

export default FilePreview;
