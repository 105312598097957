import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { updateById } from "../../Functions/updateById";
import { addItemsToTable } from "../../Functions/addAria";
import { createFolder } from "../../Functions/google";
import { TABLES } from "../../constants";
import { getEstimateNumber } from "../../Functions/getEstimateNumber";
import {
  WALL_R_VALUES,
  FLOOR_R_VALUES,
  CEILING_R_VALUES,
  ESTIMATE_STATUS,
} from "../../constants";

const { Option } = Select;

const AddEstimateModal = ({ children, estimate, onAddSuccess, title }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      if (estimate) {
        form.setFieldsValue({
          estimateName: estimate.estimateName,
          estimateDescription: estimate.estimateDescription,
          estimateStatus: estimate.estimateStatus || "Pre-Hand off",
          handedOff: estimate.handedOff,
          estimated: estimate.estimated,
          quoteBy: estimate.quoteBy ? moment(estimate.quoteBy) : null,
          assignedTo: estimate.assignedTo,
          customerName: estimate.customer?.name || "",
        });
      } else {
        form.resetFields();
      }
    }
  }, [estimate, form, visible]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      console.log("Submitted Values:", values);

      if (estimate && estimate.id) {
        // If editing an existing estimate
        if (!estimate.googleFolderId) {
          const folderResponse = await createFolder(values.estimateName);
          values.googleFolderId = folderResponse.folderId;
        }

        // Update customer if customer ID exists
        if (estimate.customer && estimate.customer.id) {
          await updateById(
            TABLES.CUSTOMERS,
            { name: values.customerName },
            estimate.customer.id
          );
        }

        await updateById(TABLES.ESTIMATES, values, estimate.id);
      } else {
        // If creating a new estimate, fetch the estimate number, create a new folder, and add its ID to the values
        const newEstimateNumber = await getEstimateNumber();
        values.estimateNumber = newEstimateNumber;

        const folderResponse = await createFolder(values.estimateName);
        values.googleFolderId = folderResponse.folderId;

        const customer = { name: values.customerName };
        const customerResponse = await addItemsToTable(
          TABLES.CUSTOMERS,
          customer
        );
        values.customer = { id: customerResponse.id, name: customer.name };

        const newEstimate = await addItemsToTable(TABLES.ESTIMATES, values);
        values.id = newEstimate.id;
      }

      setVisible(false);
      onAddSuccess(values);
      setLoading(false);
    } catch (error) {
      console.error("Validation Failed:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={title || (estimate ? "Edit Estimate" : "Add New Estimate")}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {estimate ? "Save Changes" : "Add Estimate"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={14}>
              <Form.Item
                name="estimateName"
                label="Estimate Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="estimateStatus"
                label="Estimate Status"
                initialValue={"Pre-Hand off"}
              >
                <Select defaultValue={"Pre-Hand off"}>
                  {ESTIMATE_STATUS.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="customerName"
                label="Customer Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estimateDescription"
                label="Description"
                rules={[{ required: true }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="quoteBy" label="Quote By">
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="assignedTo" label="Assigned To">
                <Select style={{ width: "100%" }}>
                  <Option value="Matt McConnell">Matt McConnell</Option>
                  <Option value="Scott Tippins">Scott Tippins</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddEstimateModal;
