import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button, Select, InputNumber } from "antd";
import { addAssembly} from "../../Functions/assemblyHooks";
import { ASSEMBLY_CATEGORIES, TABLES } from "../../constants";
import { useUser } from "@clerk/clerk-react";
import { updateById } from "../../Functions/updateById";

const { Option } = Select; // Destructure for easier usage

const AddAssemblyModal = ({ children, assembly, onAddSuccess, title }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useUser();

  console.log("email is ", user.user.id);

  //user.primaryEmailAddress.emailAddress



  useEffect(() => {
    if (assembly) {
      form.setFieldsValue({
        assemblyName: assembly.assemblyName,
        description: assembly.description,
        laborCost: assembly.laborCost,
        submittedBy: user?.user,
        category: assembly.category, // Make sure to handle category when editing
      });
    } else {
      form.resetFields();
    }
  }, [assembly, form]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      console.log("Submitting with values: ", values);

      const updatedAssembly = { ...assembly, ...values };
      const operation = assembly
        ? updateById(TABLES.ASSEMBLIES, updatedAssembly, assembly.id)
        : addAssembly(values);

      const result = await operation;
      onAddSuccess(result, !assembly);
      setVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error handling assembly:", error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: showModal })
      )}
      <Modal
        title={title || (assembly ? "Edit Assembly" : "Add New Assembly")}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {assembly ? "Save Changes" : "Add Assembly"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="assemblyName"
            label="Assembly Name"
            rules={[
              {
                required: true,
                message: "Please input the name of the assembly!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Quote Description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please select a category!" }]}
          >
            <Select
              showSearch
              placeholder="Select a category"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.entries(ASSEMBLY_CATEGORIES).map(([key, value]) => (
                <Option key={key} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="laborCost" label="Labor Cost" >
            <InputNumber prefix={"$"} style={{width:"100%"}} type="number"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddAssemblyModal;
