import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Table,
  InputNumber,
  Select,
  Tag,
  Popover,
  message,
  DatePicker,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AddressAutocomplete from "../Google/AddressAutocomplete";
import { getVendorName } from "../../Formatters/getVendorName";
import { useItems } from "../../Contexts/useSpecificData";
import { useVendors } from "../../Contexts/useSpecificData";
import { useProjects } from "../../Contexts/useSpecificData";
import { COLORS } from "../../constants";
import { generateRFQBlob } from "./RFQPDF";
import { s3Upload } from "../../Functions/s3Upload";
import { sendEmail } from "../../Functions/sendEmail";
import { getProjectName } from "../../Formatters/getProjectName";
import { EMAILBODY } from "../../constants";
import { useUser } from "@clerk/clerk-react";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";
import { formatDate } from "../../Formatters/helpers";

const { Option } = Select;

function AddRFQ() {
  const [form] = Form.useForm();
  const { data: vendors } = useVendors();
  const { data: itemsData } = useItems();
  const { data: projects } = useProjects();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [requiredDate, setRequiredDate] = useState(null);
  const [rfqLines, setRfqLines] = useState([]);
  const [shipToAddress, setShipToAddress] = useState(
    "2624 Weaver Way Suite 200, Doraville, GA 30340"
  );
  const [notes, setNotes] = useState("");
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);
  const userEmail = useUser().user?.primaryEmailAddress?.emailAddress;
  const userName = useUser().user?.fullName;
  const [loading, setLoading] = useState(false);
  const userFirstName = useUser().user?.firstName;

  const sortedVendors = vendors.sort((a, b) =>
    getVendorName(a.id, vendors).localeCompare(getVendorName(b.id, vendors))
  );

  const getVendorDetails = (vendorId) => {
    const vendor = vendors.find((vendor) => vendor.id === vendorId);
    if (!vendor) return { contactName: "", phoneNumber: "", email: "" };

    const companyName = getVendorName(vendor.id,vendors) || "";

    const contactName = `${vendor.vendorFirstName || ""} ${
      vendor.vendorLastName || ""
    }`.trim();
    const phoneNumber = formatPhoneNumber(vendor.vendorPhone) || "";
    const email = vendor.vendorEmail || "";
    const contactFirstName = vendor.vendorFirstName || "";

    console.log("vendor details are", { contactName, phoneNumber, email, companyName });

    return { contactName, phoneNumber, email, companyName, contactFirstName };
  };

  const isFormValid = () => {
    const hasValidItems =
      rfqLines.length > 0 && rfqLines.every((line) => line.qty > 0);
    return selectedVendor && requiredDate && hasValidItems && selectedProject;
  };

  const handleVendorChange = (value) => {
    const vendorEmail =
      vendors.find((vendor) => vendor.id === value)?.vendorEmail || "";
    setSelectedVendor(value);
    setEmails([vendorEmail]);
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  const handleItemSelect = (itemId) => {
    const selectedItem = itemsData.find((item) => item.id === itemId);

    const newRfqLine = {
      key: Date.now(),
      itemId: selectedItem.id,
      category: selectedItem.category,
      itemDescription: selectedItem.itemDescription,
      manufacturer: selectedItem.manufacturer,
      manufacturerId: selectedItem.manufacturerId,
      orderBatchSize: selectedItem.orderBatchSize,
      uom: selectedItem.uom,
      qty: 0,
    };

    setRfqLines([...rfqLines, newRfqLine]);
  };

  const clearForm = () => {
    setSelectedVendor(null);
    setSelectedProject(null);
    setRequiredDate(null);
    setRfqLines([]);
    setShipToAddress("2624 Weaver Way Suite 200, Doraville, GA 30340");
    setNotes("");
    setEmails([]);
    setNewEmail("");
    setPopoverVisible(false);
    form.resetFields();
  };

  const handleQtyChange = (key, value) => {
    const newRfqLines = rfqLines.map((line) => {
      if (line.key === key) {
        return { ...line, qty: value };
      }
      return line;
    });
    setRfqLines(newRfqLines);
  };

  const handleRemoveItem = (key) => {
    setRfqLines(rfqLines.filter((line) => line.key !== key));
  };

  const handleAddEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail("");
      setPopoverVisible(false);
    } else {
      message.error("Please enter a valid email address");
    }
  };

  const handleRemoveEmail = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleSubmitRFQ = async () => {
  setLoading(true);
  if (!isFormValid()) {
    message.error("Please complete all fields before submitting.");
    return;
  }

  const rfqData = {
    selectedVendor: selectedVendor,
    project: selectedProject,
    items: rfqLines,
    shipToAddress,
    requiredDate,
    notes,
    userEmail,
  };

  try {
    const vendorDetails = getVendorDetails(selectedVendor);
    const blob = await generateRFQBlob(
      rfqData,
      vendorDetails,
      userName,
      getProjectName(selectedProject, projects),
      requiredDate,
    );
    const uploadPath = `RFQ-${new Date().toISOString()}.pdf`;
    const url = await s3Upload(blob, uploadPath);
    const emailBody = EMAILBODY.REQUEST_FOR_QUOTE({
      vendorName: getVendorName(selectedVendor, vendors),
      rfqUrl: url,
      vendor: vendorDetails.companyName,
      vendorFirstName: vendorDetails.contactFirstName,
      vendorPhone: vendorDetails.phoneNumber,
      capFirstName: userFirstName,
      reqDateFormatted: formatDate(requiredDate),
    });

    await sendEmail({
      subject: emailBody.subject,
      htmlBody: emailBody.body,
      emailAddresses: emails,
      attachmentUrls: [url],
      sender: userEmail,

    });

    message.success("RFQ submitted successfully.");
    form.resetFields(["qty"]);
    setLoading(false);
    clearForm();
  } catch (error) {
    console.error("Failed to submit RFQ:", error);
    message.error("An error occurred while submitting the RFQ.");
    setLoading(false);
  }
};


  const columns = [
    {
      title: "Line",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Manufacturer ID",
      dataIndex: "manufacturerId",
      key: "manufacturerId",
    },
    {
      title: "UOM",
      dataIndex: "orderBatchSize",
      key: "orderBatchSize",
      render: (text, record) => `${text} ${record.uom}`,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.qty}
          onChange={(value) => handleQtyChange(record.key, value)}
          type="number"
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          danger
          onClick={() => handleRemoveItem(record.key)}
          icon={<DeleteOutlined />}
        ></Button>
      ),
    },
  ];

  const emailContent = (
    <div>
      <Input
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        placeholder="Enter new email"
        style={{ width: 200, marginBottom: 8 }}
        type="email"
      />
      <Button   type="primary" onClick={handleAddEmail} block>
        Add
      </Button>
    </div>
  );

  return (
    <Form form={form}>
      <Row gutter={[16, 8]} style={{ marginBottom: 16 }} align="middle">
        <Col span={12}>
          <Form.Item label="Vendor" style={{ margin: 0 }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Vendor"
              optionFilterProp="children"
              onChange={handleVendorChange}
              value={selectedVendor}
            >
              {sortedVendors.map((vendor) => (
                <Option key={vendor.id} value={vendor.id}>
                  {getVendorName(vendor.id, vendors)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            icon={<RocketOutlined />}
            style={{ marginLeft: 16 }}
            disabled={!isFormValid() || loading}
            loading={loading}
            onClick={handleSubmitRFQ}
          >
            Submit RFQ
          </Button>
        </Col>
      </Row>

      {selectedVendor && (
        <Row gutter={[16, 8]} style={{ marginBottom: 8 }}>
          <Col span={12}>
            <Form.Item label="Email" style={{ margin: 0 }}>
              <div>
                {emails.length > 0 &&
                  emails.map((email) => (
                    <Tag
                      closable
                      onClose={() => handleRemoveEmail(email)}
                      key={email}
                      style={{ marginTop: 4 }}
                      color={COLORS.PRIMARY}
                    >
                      {email}
                    </Tag>
                  ))}
                <Popover
                  content={emailContent}
                  title="Add Email"
                  trigger="click"
                  visible={popoverVisible}
                  onVisibleChange={setPopoverVisible}
                >
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 8 }}
                  />
                </Popover>
              </div>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Contact Name" style={{ margin: 0 }}>
              <Input
                value={getVendorDetails(selectedVendor).contactName || ""}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Phone Number" style={{ margin: 0 }}>
              <PhoneInput
                country={"us"}
                value={getVendorDetails(selectedVendor).phoneNumber || ""}
                dropdownStyle={{
                  border: "none",
                  background: "white",
                  backgroundColor: "white",
                }}
                containerClass="displayOnlyPhone"
                inputClass="displayOnlyPhone"
                dropdownClass="displayOnlyPhone"
                disableDropdown={true}
                disabled
                containerStyle={{ width: "100%" }}
                inputStyle={{
                  width: "100%",
                  border: "none",
                  background: "none",
                  cursor: "default",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={[16, 8]} style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Form.Item label="Ship To Address" style={{ margin: 0 }}>
            <AddressAutocomplete
              form={form}
              value={shipToAddress}
              initialValue={shipToAddress}
              onChange={setShipToAddress}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Project" style={{ margin: 0 }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Project"
              optionFilterProp="children"
              onChange={handleProjectChange}
              value={selectedProject}
            >
              {projects
                .sort((a, b) =>
                  getProjectName(a.id, projects).localeCompare(
                    getProjectName(b.id, projects)
                  )
                )
                .map((project) => (
                  <Option key={project.id} value={project.id}>
                    {getProjectName(project.id, projects)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Required Date" style={{ margin: 0 }}>
            <DatePicker
              style={{ width: "100%" }}
              value={requiredDate}
              onChange={(date) => setRequiredDate(date)}
            />
          </Form.Item>
        </Col>
      </Row>

      <>
        <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
          <Col span={24}>
            <Form.Item label="Add Item" style={{ margin: 0 }}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Item to Add"
                optionFilterProp="children"
                onChange={(value) => handleItemSelect(value)}
                value={null}
              >
                {itemsData
                  .filter(
                    (item) => !rfqLines.some((line) => line.itemId === item.id)
                  )
                  .sort((a, b) => {
                    const itemA = (a.itemDescription || "").trim();
                    const itemB = (b.itemDescription || "").trim();
                    return itemA.localeCompare(itemB);
                  })
                  .map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.itemDescription}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {rfqLines.length > 0 && (
          <>
            <Table
              dataSource={rfqLines}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="key"
            />
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={24}>
                <Form.Item label="Notes">
                  <Input.TextArea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </>
    </Form>
  );
}

export default AddRFQ;
