import { parsePrice } from "./helpers";

export const calculateEstimateCosts = (estimate) => {
  const materialsCost =
    (estimate.customItems?.reduce(
      (total, item) => total + parsePrice(item.price) * item.quantity,
      0
    ) || 0) +
    (estimate.modules?.reduce(
      (total, module) =>
        total +
        parsePrice(module.container.price) *
          parseInt(module.container.quantity, 10),
      0
    ) || 0) +
    (estimate.rfq?.reduce(
      (total, rfqItem) =>
        total +
        rfqItem.assemblyItems?.reduce(
          (itemTotal, item) =>
            itemTotal + parsePrice(item.price) * item.quantity,
          0
        ) *
          rfqItem.quantity,
      0
    ) || 0);

  const laborCost =
    estimate.rfq?.reduce(
      (total, rfqItem) =>
        total + parseFloat(rfqItem.laborCost) * rfqItem.quantity,
      0
    ) || 0;

  const totalCost = materialsCost + laborCost;

  return { materialsCost, laborCost, totalCost };
};
