export function incrementPONumber(poNumber, increment = 1) {
  const prefixMatch = poNumber.match(/^([A-Z]+-)/); // Match the prefix (e.g., "A-")
  const numericPartMatch = poNumber.match(/(\d+)$/); // Match the numeric part (e.g., "1234")

  if (!prefixMatch || !numericPartMatch) {
    throw new Error("Invalid PO Number format");
  }

  const prefix = prefixMatch[1]; // Extract the prefix
  const currentNumber = parseInt(numericPartMatch[1], 10); // Parse the numeric part

  const newNumber = currentNumber + increment; // Increment the numeric part
  const newPONumber = `${prefix}${newNumber}`; // Combine prefix and incremented number

  return newPONumber;
}

// Example usage:
const poNumber = "A-1234";
const nextPONumber = incrementPONumber(poNumber); // "A-1235"
console.log(nextPONumber); // Output: A-1235

// For generating a sequence of PO numbers:
const initialPONumber = "A-1234";
const additionalTablesCount = 3; // Number of additional tables

let currentPONumber = initialPONumber;
for (let i = 0; i < additionalTablesCount; i++) {
  currentPONumber = incrementPONumber(currentPONumber);
  console.log(`PO Number for table ${i + 1}: ${currentPONumber}`);
}
