import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Badge, Row, Col, Card, Button } from "antd";
import { StopOutlined, FilterOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { formatMoney } from "../../Formatters/helpers";
import dayjs from "dayjs";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import { getProjectName } from "../../Formatters/getProjectName";
import PurchaseOrderModal from "../Dashboard/PurchaseOrderModal";
import { SearchInput } from "../../Styled/SearchInput";
import TableHeader from "../../Styled/TableHeader";
import { getSubtotal, getTotalAmount } from "../../utils/purchaseOrderUtils";
import { getPoRevisionNumber } from "../../Formatters/getPoRevisionNumber";

const PurchasingTable = ({ onFilterChange }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();

  useEffect(() => {
    if (!poLoading) {
      const aggregatedResult = aggregateData(purchaseOrders);
      const sortedResult = aggregatedResult.sort((a, b) =>
        compareDates(a.orderedDate, b.orderedDate)
      );
      setData(sortedResult);
      setOriginalData(sortedResult);
      setLoading(false);
      onFilterChange(sortedResult);
    }
  }, [poLoading, purchaseOrders]);

  const compareDates = (a, b) => dayjs(b).diff(dayjs(a));

const aggregateData = (data) => {
  const aggregated = {};

  data.forEach((item) => {
    if (!aggregated[item.poNumber]) {
      // Calculate subtotal using utility function
      const subtotal = getSubtotal(item);
      // Calculate total amount using utility function
      const totalAmount = getTotalAmount(item, subtotal);

      aggregated[item.poNumber] = {
        ...item,
        lines: item.lines || [],
        totalQty: item.lines.reduce(
          (sum, line) => sum + (parseFloat(line.qtyOrdered) || 0),
          0
        ),
        subtotal, // Store subtotal
        totalAmount, // Store total amount
        receivedDate: null,
        poStatus: "Closed",
      };
    }

    item.lines.forEach((line) => {
      if (line.receivedDate) {
        const lineReceivedDate = dayjs(line.receivedDate);
        if (
          !aggregated[item.poNumber].receivedDate ||
          lineReceivedDate.isAfter(
            dayjs(aggregated[item.poNumber].receivedDate)
          )
        ) {
          aggregated[item.poNumber].receivedDate = line.receivedDate;
        }
      }
      const lineStatus =
        line.qtyReceived >= line.qtyOrdered
          ? "Closed"
          : line.qtyReceived > 0
          ? "Partial"
          : "Open";
      if (lineStatus !== "Closed") {
        aggregated[item.poNumber].poStatus = "Open";
      }
    });

    const requiredDateMidnight = dayjs(
      aggregated[item.poNumber].requiredDate
    ).startOf("day");
    const latestReceivedDate = aggregated[item.poNumber].receivedDate
      ? dayjs(aggregated[item.poNumber].receivedDate).startOf("day")
      : null;
    const todayMidnight = dayjs().startOf("day");
    let timeStatus = "On Time";
    if (
      latestReceivedDate &&
      latestReceivedDate.isAfter(requiredDateMidnight)
    ) {
      timeStatus = "Late";
    } else if (
      todayMidnight.isAfter(requiredDateMidnight) &&
      aggregated[item.poNumber].poStatus === "Open"
    ) {
      timeStatus = "Late";
    }
    aggregated[item.poNumber].timeStatus = timeStatus;
  });

  return Object.values(aggregated);
};


  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value === "") {
      setData(originalData);
      onFilterChange(originalData);
      return;
    }

const filteredData = originalData.filter((record) => {
  // Normalize the search input
  const searchValue = value.toLowerCase().trim();

  // Extract and normalize fields for comparison
  const poNumber = record.poNumber?.toLowerCase() || "";
  const vendorName = getVendorName(record.vendor, vendors)?.toLowerCase() || "";
  const buyerName = formatBuyerName(record.buyer)?.toLowerCase() || "";
  const groupNumber = record.groupNumber?.toLowerCase() || "";
  const projectName =
    getProjectName(record.projectNumber, projects)?.toLowerCase() || "";

  // Initialize group match flag
  let isGroupMatch = false;

  // Define regex patterns
  const groupPattern = /^group\s+(.+)$/i; // Matches "group g-1" and captures "g-1"
  const exactGroupPattern = /^group$/i; // Matches "group" exactly

  if (groupPattern.test(searchValue)) {
    // Search input matches "group g-1"
    const match = searchValue.match(groupPattern);
    const groupTerm = match[1].trim();
    isGroupMatch = groupNumber.includes(groupTerm);
  } else if (exactGroupPattern.test(searchValue)) {
    // Search input is exactly "group"
    isGroupMatch = groupNumber.length > 0;
  } else {
    // Search input does not include "group"; match groupNumber directly
    isGroupMatch = groupNumber.includes(searchValue);
  }

  return (
    poNumber.includes(searchValue) ||
    vendorName.includes(searchValue) ||
    buyerName.includes(searchValue) ||
    isGroupMatch ||
    projectName.includes(searchValue)
  );
});




    setData(filteredData);
    onFilterChange(filteredData);
  };

  const handleFilterChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    applyFilters(filters);
  };

  const applyFilters = (filters) => {
    let filteredData = originalData;

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filteredData = filteredData.filter((record) =>
          filters[key].includes(
            key === "vendor"
              ? getVendorName(record[key], vendors)
              : key === "buyer"
              ? formatBuyerName(record[key])
              : key === "projectNumber"
              ? getProjectName(record[key], projects)
              : record[key]
          )
        );
      }
    });

    setData(filteredData);
    onFilterChange(filteredData);
  };

  const getUniqueFilters = (dataSource, dataIndex, filters) => {
    const filterValues = filters[dataIndex];
    let uniqueValues = Array.from(
      new Set(
        dataSource.map((item) =>
          dataIndex === "vendor"
            ? getVendorName(item[dataIndex], vendors)
            : dataIndex === "buyer"
            ? formatBuyerName(item[dataIndex])
            : dataIndex === "projectNumber"
            ? getProjectName(item[dataIndex], projects)
            : item[dataIndex]
        )
      )
    );

    if (filterValues && filterValues.length) {
      uniqueValues = uniqueValues.filter((value) =>
        filterValues.includes(value)
      );
    }

    // Sort unique values alphabetically (A-Z)
    uniqueValues.sort((a, b) => a.localeCompare(b));

    return uniqueValues.map((value) => ({ text: value, value: value }));
  };

  const vendorFilters = getUniqueFilters(data, "vendor", filteredInfo);
  const buyerFilters = getUniqueFilters(data, "buyer", filteredInfo);
  const projectFilters = getUniqueFilters(data, "projectNumber", filteredInfo);
  const statusFilters = getUniqueFilters(data, "poStatus", filteredInfo);

  const removeFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setData(originalData);
    onFilterChange(originalData);
  };

const columns = [
  {
    title: "PO Number",
    dataIndex: "poNumber",
    key: "poNumber",
    sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    filteredValue: filteredInfo.poNumber || null,
    defaultSortOrder: "descend",
    render: (text, record) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{text}</span>
        {record.groupNumber && (
          <Tag color="blue" style={{ marginLeft: 8, fontSize: 10 }}>
            Group {record.groupNumber}
          </Tag>
        )}
        <Badge
          count={record.lines.length}
          style={{ backgroundColor: "#6BA1E2", fontWeight: "bold" }}
        />
      </div>
    ),
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
    key: "vendor",
    sorter: (a, b) =>
      getVendorName(a.vendor, vendors).localeCompare(
        getVendorName(b.vendor, vendors)
      ),
    filterSearch: true,
    filters: vendorFilters,
    filteredValue: filteredInfo.vendor || null,
    onFilter: (value, record) =>
      getVendorName(record.vendor, vendors).includes(value),
    render: (text) => getVendorName(text, vendors),
  },
  {
    title: "Date Submitted",
    dataIndex: "orderedDate",
    key: "orderedDate",
    sorter: (a, b) => dayjs(a.orderedDate).diff(dayjs(b.orderedDate)),
    render: (text) => dayjs(text).format("MM/DD/YYYY"),
  },
  {
    title: "Required Date",
    dataIndex: "requiredDate",
    key: "requiredDate",
    sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
    render: (text) => dayjs(text).format("MM/DD/YYYY"),
  },
  {
    title: "Buyer",
    dataIndex: "buyer",
    key: "buyer",
    sorter: (a, b) =>
      formatBuyerName(a.buyer).localeCompare(formatBuyerName(b.buyer)),
    filterSearch: true,
    filters: buyerFilters,
    filteredValue: filteredInfo.buyer || null,
    onFilter: (value, record) =>
      formatBuyerName(record.buyer).toLowerCase().includes(value.toLowerCase()),
    render: (text) => formatBuyerName(text),
  },
  {
    title: "Project",
    dataIndex: "projectNumber",
    key: "projectNumber",
    sorter: (a, b) => {
      let aProjName = getProjectName(a.projectNumber, projects) || "";
      let bProjName = getProjectName(b.projectNumber, projects) || "";
      return aProjName.localeCompare(bProjName);
    },
    filterSearch: true,
    filters: projectFilters,
    filteredValue: filteredInfo.projectNumber || null,
    onFilter: (value, record) => {
      let projName = getProjectName(record.projectNumber, projects) || "";
      return projName.includes(value);
    },
    render: (text) => getProjectName(text, projects),
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    sorter: (a, b) => a.totalAmount - b.totalAmount,
    render: (text, record) => formatMoney(text),
  },
  {
    title: "PO Status",
    dataIndex: "poStatus",
    key: "poStatus",
    sorter: (a, b) => a.poStatus.localeCompare(b.poStatus),
    filters: statusFilters,
    filteredValue: filteredInfo.poStatus || null,
    onFilter: (value, record) => record.poStatus.includes(value),
    render: (status) => {
      let color = "blue";
      if (status === "Closed") color = "green";
      return (
        <Tag style={{ fontSize: 11 }} color={color}>
          {status}
        </Tag>
      );
    },
  },
  {
    title: "Time Status",
    dataIndex: "timeStatus",
    key: "timeStatus",
    filters: [
      { text: "On Time", value: "On Time" },
      { text: "Late", value: "Late" },
    ],
    filteredValue: filteredInfo.timeStatus || null,
    onFilter: (value, record) => record.timeStatus === value,
    render: (text, record) => {
      const color = record.timeStatus === "Late" ? "red" : "green";
      return (
        <Tag style={{ fontSize: 11 }} color={color}>
          {record.timeStatus}
        </Tag>
      );
    },
  },
  {
    title: "Date Received",
    dataIndex: "receivedDate",
    key: "receivedDate",
    sorter: (a, b) => {
      if (!a.receivedDate && !b.receivedDate) return 0;
      if (!a.receivedDate) return -1; // `a` is null, so it comes after `b`
      if (!b.receivedDate) return 1; // `b` is null, so it comes after `a`
      return dayjs(a.receivedDate).diff(dayjs(b.receivedDate));
    },
    render: (text, record) => {
      if (record.poStatus !== "Closed")
        return <StopOutlined style={{ color: "#d3d3d3" }} />;
      return dayjs(text).format("MM/DD/YYYY");
    },
  },
];


  const onRowClick = (record) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };

  return (
    <>
      <TableHeader
        totalResults={data.length}
        searchValue={searchTerm}
        onSearchChange={handleSearch}
        removeFilters={removeFilters}
        totalItems={originalData.length}
        title={"Purchase Orders"}
        showRemoveFilters={true} // Set to true or false depending on the use case
      />
      <Table
        className="small-table"
        dataSource={data}
        columns={columns}
        rowClassName="clickable-row"
        rowKey="id"
        loading={loading || poLoading}
        onRow={(record) => ({ onClick: () => onRowClick(record) })}
        onChange={handleFilterChange}
        pagination={{
          pageSizeOptions: ["10", "25", "50", "100", "250"],

          // hideOnSinglePage: true, total: data.length, showTotal: (total) => <Tag>{total} items</Tag>
        }}
      />
      <PurchaseOrderModal
        purchaseOrder={selectedRecord}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        vendors={vendors}
        projects={projects}
      />
    </>
  );
};

export default PurchasingTable;
