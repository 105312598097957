import { parsePrice } from "./helpers";

export function getAssemblyCost(assembly) {
  const laborCost = assembly.laborCost ? parsePrice(assembly.laborCost) : 0;

  const itemsTotalCost = assembly.assemblyItems
    ? assembly.assemblyItems.reduce((total, item) => {
        const price = item.price ? parsePrice(item.price) : 0;
        const quantity = item.quantity ? parseFloat(item.quantity) : 0;
        return total + price * quantity;
      }, 0)
    : 0;

  const totalCost = laborCost + itemsTotalCost;
  return totalCost;
}
