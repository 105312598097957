import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Tag,
  Badge,
  Popover,
  Tooltip,
  Modal,
  Select,
  Form,
  Statistic,
  message,
  Spin,
  Input,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
  StopOutlined,
  CalendarOutlined,
  PaperClipOutlined,
  DeleteOutlined as DeleteIcon,
  WarningOutlined,
} from "@ant-design/icons";
import {
  useItems,
  useVendors,
  usePurchaseOrders,
  useProjects,
} from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import { getVendorName } from "../../Formatters/getVendorName";
import { COLORS, TABLES } from "../../constants";
import { updateById } from "../../Functions/updateById";
import AddItemModal from "../../Components/Items/AddItemModal";
import dayjs from "dayjs";
import PurchaseOrderModal from "../../Components/Dashboard/PurchaseOrderModal";
import { SearchInput } from "../../Styled/SearchInput";
import { exportToCsv } from "../../Functions/exportToCsv";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import Highlighter from "react-highlight-words";
import { getLastPurchasedItem, getDefaultPriceItem } from "../../utils/itemsHelpers";

const { Option } = Select;

const ItemsTable = () => {
  // Data fetching hooks
  const {
    data: items = [],
    refresh: refreshItems,
    loading: isLoading,
  } = useItems();
  const { data: vendors = [] } = useVendors();
  const { data: purchaseOrders = [] } = usePurchaseOrders();
  const { data: projects = [] } = useProjects();

  // State variables
  const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRange, setSelectedRange] = useState("all"); // Default to All Time
  const [selectedItemBreakdown, setSelectedItemBreakdown] = useState(null);
  const [purchaseOrderModalVisible, setPurchaseOrderModalVisible] =
    useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [addVendorModalVisible, setAddVendorModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isRangeLoading, setIsRangeLoading] = useState(false); // Loading state for Select
  const [selectOpen, setSelectOpen] = useState(false); // Control Select's open state
  const [searchResultsLength, setSearchResultsLength] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  // Function to determine the date from based on selectedRange
  const getDateFrom = useCallback((range) => {
    switch (range) {
      case "all":
        return dayjs("1900-01-01");
      case "thisYear":
        return dayjs().startOf("year");
      case "lastYear":
        return dayjs().subtract(1, "year").startOf("year");
      default:
        if (typeof range === "number") {
          return dayjs().subtract(range, "day");
        }
        return dayjs("1900-01-01"); // Default fallback
    }
  }, []);

  const timeRanges = [7, 30, 60, 90, 180, "thisYear", "lastYear", "all"];

  const rangeLabels = {
    7: "Last 7 Days",
    30: "Last 30 Days",
    60: "Last 60 Days",
    90: "Last 90 Days",
    180: "Last 180 Days",
    thisYear: "This Year",
    lastYear: "Last Year",
    all: "All Time",
  };

  // Effect to handle changes in selectedRange
  useEffect(() => {
    const updateFilteredPurchaseOrders = () => {
      setIsRangeLoading(true); // Start loading

      setTimeout(() => {
        try {
          const dateFrom = getDateFrom(selectedRange);

          const filteredPOs = purchaseOrders?.filter((po) =>
            dayjs(po.orderedDate).isAfter(dateFrom)
          );

          setFilteredPurchaseOrders(filteredPOs);
          setIsRangeLoading(false); // End loading after updating
        } catch (error) {
          console.error("Error updating purchase orders:", error);
          message.error("An error occurred while updating purchase orders.");
          setIsRangeLoading(false); // End loading on error
        }
      }, 300); // Delay to allow spinner to render
    };

    updateFilteredPurchaseOrders();
  }, [selectedRange, purchaseOrders, getDateFrom]);

useEffect(() => {
  if (!items) {
    setFilteredItems([]);
    setSearchResultsLength(0);
    setIsRangeLoading(false); // End loading
    return;
  }

  const lowercasedFilter = searchTerm.toLowerCase();

  const filtered = items
    .map((item) => {
      // Use utility functions to get last purchased details and default price
      const { lastPurchasedDate, lastPurchasedPrice } = getLastPurchasedItem(
        item,
        purchaseOrders
      );
      const defaultPrice = getDefaultPriceItem(item, purchaseOrders);

      // Compute timesPurchased and totalSpent based on filteredPurchaseOrders
      let timesPurchased = 0;
      let totalSpent = 0;

      filteredPurchaseOrders?.forEach((po) => {
        let itemPurchasedInPO = false;

        po.lines.forEach((line) => {
          const vendorItemIds = item.vendors.map((vendor) => vendor.itemId);
          if (vendorItemIds.includes(line.itemId)) {
            totalSpent += line.qtyOrdered * line.itemPrice;
            itemPurchasedInPO = true;
          }
        });

        if (itemPurchasedInPO) {
          timesPurchased += 1;
        }
      });

      return {
        ...item,
        timesPurchased,
        totalSpent,
        lastPurchasedDate,
        defaultPrice,
      };
    })
    .filter((item) => {
      // Existing checks for item description and item number
      const itemMatches =
        (item.itemDescription &&
          item.itemDescription.toLowerCase().includes(lowercasedFilter)) ||
        (item.itemNumber &&
          item.itemNumber.toLowerCase().includes(lowercasedFilter));

      // Checks for vendors using formatMoney
      const vendorMatches = item.vendors.some((vendor) => {
        const vendorName = getVendorName(vendor.id, vendors);
        const priceMatch =
          (vendor.price &&
            formatMoney(vendor.price)
              .toLowerCase()
              .includes(lowercasedFilter)) ||
          (vendor.lastPrice &&
            formatMoney(vendor.lastPrice)
              .toLowerCase()
              .includes(lowercasedFilter));

        const nameMatch =
          vendorName && vendorName.toLowerCase().includes(lowercasedFilter);

        return priceMatch || nameMatch;
      });

      return itemMatches || vendorMatches;
    });

  setFilteredItems(filtered);
  setSearchResultsLength(filtered.length);
  setIsRangeLoading(false); // End loading

  // Expand rows where vendor matches, only if searchTerm is not empty
  if (searchTerm.trim() !== "") {
    const expandedKeys = filtered
      .filter((item) => {
        // Expand if vendor matches
        return item.vendors.some((vendor) => {
          const vendorName = getVendorName(vendor.id, vendors);

          const priceMatch =
            (vendor.price &&
              formatMoney(vendor.price)
                .toLowerCase()
                .includes(lowercasedFilter)) ||
            (vendor.lastPrice &&
              formatMoney(vendor.lastPrice)
                .toLowerCase()
                .includes(lowercasedFilter));

          const nameMatch =
            vendorName && vendorName.toLowerCase().includes(lowercasedFilter);

          return priceMatch || nameMatch;
        });
      })
      .map((item) => item.id);

    setExpandedRowKeys(expandedKeys);
  } else {
    // If searchTerm is empty, collapse all rows
    setExpandedRowKeys([]);
  }
}, [items, searchTerm, filteredPurchaseOrders, purchaseOrders, vendors]);



  // Effect to handle document title
  useEffect(() => {
    if (items) {
      document.title = `Items`;
    }
  }, [items]);

  // Handlers for row expansion and double-click
  const handleRowDoubleClick = (record) => {
    const currentIndex = expandedRowKeys.indexOf(record.id);
    const newExpandedKeys = [...expandedRowKeys];

    if (currentIndex >= 0) {
      // Collapse row if already expanded
      newExpandedKeys.splice(currentIndex, 1);
    } else {
      // Expand row if not expanded
      newExpandedKeys.push(record.id);
    }

    setExpandedRowKeys(newExpandedKeys);
  };

  const handleRowExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys) =>
        prevKeys.filter((key) => key !== record.id)
      );
    }
  };

  // Handler for range change with Select control
  const handleRangeChange = (value) => {
    setSelectedRange(value);
    setSelectOpen(false); // Close the Select dropdown
  };

  const handleShowBreakdown = (item) => {
    // Set the selected item for showing its details in the breakdown modal
    setSelectedItem(item);

    const vendorItemIds = item.vendors.map((vendor) => vendor.itemId);

    // Filter the purchase orders related to the selected item
    const filteredOrders = filteredPurchaseOrders?.flatMap((po) => {
      const itemPurchasedInPO = po.lines.some((line) =>
        vendorItemIds.includes(line.itemId)
      );

      if (itemPurchasedInPO) {
        return [
          {
            poNumber: po.poNumber,
            purchaseOrder: po, // Pass the entire purchase order object
            orderedDate: po.orderedDate,
            vendorName: getVendorName(po.vendor, vendors),
            total: po.lines
              .filter((line) => vendorItemIds.includes(line.itemId))
              .reduce((acc, line) => acc + line.qtyOrdered * line.itemPrice, 0), // Calculate total
            // Add other details as needed
          },
        ];
      }
      return [];
    });

    // Set the filtered purchase orders for the breakdown modal
    setSelectedItemBreakdown(filteredOrders);
  };

  // Handle opening the PurchaseOrderModal when PO number is clicked
  const handleOpenPurchaseOrder = (purchaseOrder) => {
    setSelectedPurchaseOrder(purchaseOrder);
    setPurchaseOrderModalVisible(true);
  };

  const handleAddSuccess = (newItem) => {
    refreshItems();
    message.success("Item added successfully.");
  };

  const handleDeleteItem = async () => {
    setSaving(true);
    try {
      await updateById(TABLES.ITEMS, { deleted: true }, selectedItem.id);
      refreshItems();
      setDeleteModalVisible(false);
      message.success("Item deleted successfully.");
    } catch (error) {
      console.error("Failed to delete item:", error);
      message.error("Failed to delete item.");
    } finally {
      setSaving(false);
    }
  };

  const handleAddVendor = async () => {
    try {
      const values = await form.validateFields();
      setSaving(true);
      const updatedVendors = [
        ...selectedItem.vendors,
        {
          id: values.vendor,
          price: parseFloat(values.price),
          itemId: uuidv4(), // Generate a new itemId
        },
      ];

      await updateById(
        TABLES.ITEMS,
        { vendors: updatedVendors },
        selectedItem.id
      );
      refreshItems();
      setAddVendorModalVisible(false);
      form.resetFields();
      message.success("Vendor added successfully.");
    } catch (error) {
      console.error("Failed to add vendor:", error);
      message.error("Failed to add vendor.");
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteVendor = async (itemId, vendorId) => {
    setSaving(true);
    const item = items.find((item) => item.id === itemId);
    const updatedVendors = item.vendors.filter(
      (vendor) => vendor.id !== vendorId
    );

    try {
      if (updatedVendors.length === 0) {
        // If no vendors remain, prompt the user to confirm item deletion
        Modal.confirm({
          title: "Delete Item",
          icon: <WarningOutlined />,
          content: (
            <p>
              Removing the last vendor will delete the item. Are you sure you
              want to proceed?
            </p>
          ),
          onOk: async () => {
            await updateById(TABLES.ITEMS, { deleted: true }, itemId);
            refreshItems();
            setVisiblePopover({});
            message.success("Item deleted successfully.");
          },
          okText: "Yes",
          cancelText: "No",
        });
      } else {
        await updateById(TABLES.ITEMS, { vendors: updatedVendors }, itemId);
        refreshItems();
        setVisiblePopover({});
        message.success("Vendor removed successfully.");
      }
    } catch (error) {
      console.error("Failed to delete vendor:", error);
      message.error("Failed to remove vendor.");
    } finally {
      setSaving(false);
    }
  };

  const handleEditPrice = async (itemId, vendorId, newPrice) => {
    if (!newPrice || parseFloat(newPrice) < 0) return;

    setSaving(true);
    const item = items.find((item) => item.id === itemId);

    const updatedVendors = item.vendors.map((vendor) => {
      if (vendor.id === vendorId) {
        return {
          ...vendor,
          lastPrice: vendor.price, // Store the current price as lastPrice
          price: parseFloat(newPrice), // Update the price
          lastUpdated: dayjs().format("MM/DD/YYYY"), // Set the lastUpdated field to the current date
        };
      }
      return vendor;
    });

    try {
      await updateById(TABLES.ITEMS, { vendors: updatedVendors }, itemId);
      refreshItems();
      setVisiblePopover({});
      message.success("Price updated successfully.");
    } catch (error) {
      console.error("Failed to update price:", error);
      message.error("Failed to update price.");
    } finally {
      setSaving(false);
    }
  };

  const handleAddItemClick = () => {
    setSelectedItem(null);
    setAddItemModalVisible(true);
  };

  const handleEditItemClick = (item) => {
    setSelectedItem(item);
    setAddItemModalVisible(true);
  };

  const handleVisibleChange = (key, visible) => {
    setVisiblePopover((prevState) => {
      if (prevState[key] === visible) return prevState;

      return {
        ...prevState,
        [key]: visible,
      };
    });
  };

  const DeletePopoverContent = ({ vendorId, vendorName, itemId, onCancel }) => {
    return (
      <div>
        <p>
          Do you want to remove{" "}
          <span style={{ fontWeight: 600 }}>{vendorName}</span>?
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            No
          </Button>
          <Button
            onClick={() => handleDeleteVendor(itemId, vendorId)}
            type="primary"
            danger
            loading={saving}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  const PricePopoverContent = ({
    itemId,
    vendorId,
    currentPrice,
    onConfirm,
  }) => {
    const [newPrice, setNewPrice] = useState(currentPrice);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          type="number"
          value={newPrice}
          onChange={(e) => setNewPrice(e.target.value)}
          style={{ width: 100, marginRight: 8 }}
          prefix="$"
          min="0"
        />
        <Button
          type="primary"
          onClick={() => onConfirm(itemId, vendorId, newPrice)}
          loading={saving}
          disabled={!newPrice || parseFloat(newPrice) < 0}
        >
          Save
        </Button>
      </div>
    );
  };

  // Export to CSV Functionality
  const handleExportCSV = () => {
    try {
      const rows = [];

      // Step 1: Create a map of vendorItemIds to item and vendor info
      const vendorItemIdToItemAndVendor = {};
      filteredItems.forEach((item) => {
        if (!item.vendors || item.vendors.length === 0) return;
        item.vendors.forEach((vendorInfo) => {
          const vendorItemId = vendorInfo.itemId;
          vendorItemIdToItemAndVendor[vendorItemId] = {
            item,
            vendorInfo,
          };
        });
      });

      // Step 2: Initialize stats per item and vendor per time range
      const statsPerItemAndVendor = {};

      // Initialize stats for all time ranges
      timeRanges.forEach((range) => {
        statsPerItemAndVendor[range] = {};
      });

      // Step 3: Process purchase orders only once
      purchaseOrders?.forEach((po) => {
        timeRanges.forEach((range) => {
          const dateFrom = getDateFrom(range);
          const dateTo =
            range === "lastYear"
              ? dayjs().subtract(1, "year").endOf("year")
              : dayjs();
          if (!dayjs(po.orderedDate).isBetween(dateFrom, dateTo, null, "[]")) {
            return;
          }
          const itemsInPO = new Set();

          po.lines.forEach((line) => {
            const vendorItemId = line.itemId;

            if (vendorItemIdToItemAndVendor[vendorItemId]) {
              const { item, vendorInfo } =
                vendorItemIdToItemAndVendor[vendorItemId];
              const itemKey = `${item.id}_${vendorInfo.id}`;

              if (!statsPerItemAndVendor[range][itemKey]) {
                statsPerItemAndVendor[range][itemKey] = {
                  timesPurchased: 0,
                  totalSpent: 0,
                };
              }

              statsPerItemAndVendor[range][itemKey].totalSpent +=
                line.qtyOrdered * line.itemPrice;

              itemsInPO.add(itemKey);
            }
          });

          // Increment timesPurchased for each unique itemKey in the purchase order
          itemsInPO.forEach((itemKey) => {
            statsPerItemAndVendor[range][itemKey].timesPurchased += 1;
          });
        });
      });

      // Step 4: Generate CSV rows using the precomputed stats
      filteredItems.forEach((item) => {
        if (!item.vendors || item.vendors.length === 0) return;

        item.vendors.forEach((vendorInfo) => {
          const itemKey = `${item.id}_${vendorInfo.id}`;
          const vendorName = getVendorName(vendorInfo.id, vendors);

          // Prepare the base row data
          const row = {
            "Item ID": item.id,
            Category: item.category || "",
            "Item Description": item.itemDescription || "",
            Manufacturer: item.manufacturer || "",
            "Mfg Id": item.manufacturerId || "",
            UOM:
              (item.orderBatchSize ? item.orderBatchSize : "") +
              " " +
              (item.uom || ""),
            "Item Number": item.itemNumber || "",
            "Vendor Name": vendorName || "",
            "Last Updated": vendorInfo.lastUpdated || "",
            "Last Price": vendorInfo.lastPrice || 0,
            "Current Price": vendorInfo.price || 0,
          };

          // Add stats for all time ranges
          timeRanges.forEach((range) => {
            const stats = statsPerItemAndVendor[range][itemKey] || {
              timesPurchased: 0,
              totalSpent: 0,
            };
            const rangeLabel = rangeLabels[range] || range;

            row[`# Purchased (${rangeLabel})`] = stats.timesPurchased;
            row[`$ Purchased (${rangeLabel})`] = stats.totalSpent;
          });

          rows.push(row);
        });
      });

      console.log("Rows to export:", rows);

      if (rows.length === 0) {
        message.error("No data available to export.");
        return;
      }

      exportToCsv(`items_${dayjs().format("YYYY-MM-DD")}`, rows);
    } catch (error) {
      console.error("Error during CSV export:", error);
      message.error("An error occurred during export.");
    }
  };

  // Columns Definition
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: [...new Set(items.map((item) => item.category))].map(
        (category) => ({
          text: category,
          value: category,
        })
      ),
      onFilter: (value, record) => record.category.includes(value),
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Badge
            count={record.vendors.length}
            style={{ backgroundColor: COLORS.PRIMARY, marginBottom: 2 }}
          />
          <span style={{ marginLeft: 8 }}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchTerm]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          </span>
        </div>
      ),
      width: 180,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      width: 320,
      render: (text, record) => (
        <a href="#" onClick={() => handleEditItemClick(record)}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchTerm]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        </a>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: 120,
      ellipsis: true,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Mfg ID",
      dataIndex: "manufacturerId",
      key: "manufacturerId",
      width: 120,
      ellipsis: true,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      width: 65,
      ellipsis: true,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.orderBatchSize}{" "}
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchTerm]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        </div>
      ),
    },
    {
      title: "Item #",
      dataIndex: "itemNumber",
      key: "itemNumber",
      width: 80,
      ellipsis: true,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchTerm]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
      {
    title: "Default Price",
    dataIndex: "defaultPrice",
    key: "defaultPrice",
    width: 100,
    sorter: (a, b) => (a.defaultPrice || 0) - (b.defaultPrice || 0),
    render: (text, record) =>
      text !== null && text !== undefined ? (
        <Tag color="green">{formatMoney(text)}</Tag>
      ) : (
        <span style={{ opacity: 0.6 }}>N/A</span>
      ),
  },
  {
    title: "Last Purchased",
    dataIndex: "lastPurchasedDate",
    key: "lastPurchasedDate",
    width: 120,
    sorter: (a, b) => {
      if (a.lastPurchasedDate && b.lastPurchasedDate) {
        return (
          dayjs(a.lastPurchasedDate, "MM/DD/YYYY").unix() -
          dayjs(b.lastPurchasedDate, "MM/DD/YYYY").unix()
        );
      } else if (a.lastPurchasedDate) {
        return -1;
      } else if (b.lastPurchasedDate) {
        return 1;
      } else {
        return 0;
      }
    },
    render: (text) =>
      text ? (
        text
      ) : (
        <span style={{ opacity: 0.6 }}>Never Purchased</span>
      ),
  },
    {
      className: "column-stats",
      title: (
        <div style={{ textAlign: "center" }}>
          <Select
            className="transparent-select"
            value={selectedRange}
            onChange={handleRangeChange}
            style={{ width: 150 }}
            size="small"
            loading={isRangeLoading} // Integrate loading state
            open={selectOpen} // Control Select's open state
            onDropdownVisibleChange={(open) => setSelectOpen(open)} // Update open state
          >
            <Option value={7}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 7 Days
            </Option>
            <Option value={30}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 30 Days
            </Option>
            <Option value={60}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 60 Days
            </Option>
            <Option value={90}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 90 Days
            </Option>
            <Option value={180}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 180 Days
            </Option>
            <Option value="thisYear">
              <CalendarOutlined style={{ marginRight: 8 }} />
              This Year
            </Option>
            <Option value="lastYear">
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last Year
            </Option>
            <Option value="all">
              <CalendarOutlined style={{ marginRight: 8 }} /> All Time
            </Option>
          </Select>
        </div>
      ),
      children: [
        {
          title: "# Purchased",
          className: "column-stats",
          key: "timesPurchased",
          width: 100,
          sorter: (a, b) => a.timesPurchased - b.timesPurchased,
          render: (text, record) => {
            const timesPurchased = record.timesPurchased;
            return isRangeLoading ? (
              <Spin size="small" />
            ) : timesPurchased > 0 ? (
              <a onClick={() => handleShowBreakdown(record)}>
                {timesPurchased}
              </a>
            ) : (
              <span>{timesPurchased}</span>
            );
          },
        },

        {
          title: "$ Purchased",
          className: "column-stats",
          key: "totalSpent",
          width: 100,
          sorter: (a, b) => a.totalSpent - b.totalSpent,
          render: (text, record) => {
            return isRangeLoading ? (
              <Spin size="small" />
            ) : (
              <Tag color="green">{formatMoney(record.totalSpent)}</Tag>
            );
          },
        },
      ],
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      align: "center",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Tooltip title="Add Vendor">
            <Button
              icon={<UserAddOutlined />}
              size="small"
              type="primary"
              onClick={() => {
                setSelectedItem(record);
                setAddVendorModalVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Item">
            <Button
              icon={<DeleteOutlined />}
              size="small"
              type="primary"
              danger
              onClick={() => {
                setSelectedItem(record);
                setDeleteModalVisible(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  // Expanded Row Render Function
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "",
        key: "actions",
        width: 50,
        render: (text, vendor) => (
          <Popover
            title="Remove vendor from item"
            content={
              <DeletePopoverContent
                vendorId={vendor.vendorId}
                vendorName={getVendorName(vendor.vendorId, vendors)}
                itemId={record.id}
                onCancel={() => setVisiblePopover({})}
              />
            }
            trigger="click"
            visible={visiblePopover[`${record.id}-${vendor.vendorId}`]}
            onVisibleChange={(visible) =>
              handleVisibleChange(`${record.id}-${vendor.vendorId}`, visible)
            }
          >
            <Button icon={<DeleteIcon />} size="small" type="primary" danger />
          </Popover>
        ),
        className: "expanded-row-header",
      },
      {
        title: "Vendor Name",
        dataIndex: "vendorId",
        key: "vendorName",
        render: (vendorId) => {
          const vendorName = getVendorName(vendorId, vendors);
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchTerm]}
              autoEscape
              textToHighlight={vendorName ? vendorName.toString() : ""}
            />
          );
        },
        className: "expanded-row-header",
      },
      {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        render: (text) =>
          text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchTerm]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            <StopOutlined style={{ opacity: 0.7 }} />
          ),
        className: "expanded-row-header",
      },
      {
        title: "Last Price",
        dataIndex: "lastPrice",
        key: "lastPrice",
        render: (text) =>
          text ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchTerm]}
              autoEscape
              textToHighlight={formatMoney(text)}
            />
          ) : (
            <StopOutlined style={{ opacity: 0.7 }} />
          ),
        className: "expanded-row-header",
      },
      {
        title: "Current Price",
        dataIndex: "price",
        key: "price",
        width: 200,
        render: (text, vendor) => (
          <span>
            <Popover
              title="Edit Price"
              content={
                <PricePopoverContent
                  itemId={record.id}
                  vendorId={vendor.vendorId}
                  currentPrice={text}
                  onConfirm={handleEditPrice}
                />
              }
              trigger="click"
              visible={visiblePopover[`${record.id}-${vendor.vendorId}-price`]}
              onVisibleChange={(visible) =>
                handleVisibleChange(
                  `${record.id}-${vendor.vendorId}-price`,
                  visible
                )
              }
            >
              <Button
                icon={<EditOutlined />}
                size="small"
                style={{ marginRight: 8 }}
              />
            </Popover>
            <Tag color="green">
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchTerm]}
                autoEscape
                textToHighlight={formatMoney(text)}
              />
            </Tag>
          </span>
        ),
        className: "expanded-row-header",
      },
    ];

    const data =
      record.vendors?.map((vendor, index) => ({
        key: index,
        vendorId: vendor.id,
        price: vendor.price,
        lastUpdated: vendor.lastUpdated,
        lastPrice: vendor.lastPrice,
        itemId: vendor.itemId, // Include vendor's item ID
      })) || [];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  return (
    <Row gutter={[16, 16]}>
      {/* Header */}
      <Col
        span={12}
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        <div>
          Items{" "}
          <span style={{ opacity: 0.6, fontStyle: "italic", fontSize: 18 }}>
            ({items?.length || 0})
          </span>
        </div>
      </Col>
      <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="default"
          style={{ marginRight: 16 }}
          icon={<PaperClipOutlined />}
          onClick={handleExportCSV}
          disabled={filteredItems.length === 0}
        >
          Export
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddItemClick}
        >
          Add Item
        </Button>
      </Col>

      {/* Search Input */}
      <Col span={24}>
        <SearchInput
          resultsLength={searchResultsLength}
          placeholder="Search Items"
          onChange={(e) => debouncedSearch(e.target.value)}
          style={{}}
          allowClear
          suffix={
            searchTerm ? <DeleteIcon onClick={() => setSearchTerm("")} /> : null
          }
        />
      </Col>

      {/* Items Table */}
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={filteredItems}
          rowKey="id"
          loading={isLoading} // Only show loading spinner for initial data load
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [
              "10",
              "20",
              "50",
              "100",
              "250",
              "500",
              "1000",
              "2000",
            ],
          }}
          size="small"
          rowClassName={(record) =>
            expandedRowKeys.includes(record.id) ? "expanded-header" : ""
          } // Add class when row is expanded
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpand: handleRowExpand, // Handle native expand/collapse
          }}
          onRow={(record) => ({
            onDoubleClick: () => handleRowDoubleClick(record), // Double click to expand/collapse
          })}
          className="small-table"
        />
      </Col>

      {/* Breakdown Modal */}
      {selectedItemBreakdown && (
        <Modal
          title={
            <>
              <Tag color="volcano" style={{}}>
                {rangeLabels[selectedRange] || "Custom Range"}
              </Tag>{" "}
              Breakdown for {selectedItem.itemNumber}
            </>
          }
          visible={!!selectedItemBreakdown}
          onCancel={() => setSelectedItemBreakdown(null)}
          footer={null}
          width={"70%"}
        >
          {selectedItem && (
            <div
              style={{
                fontWeight: 600,
                fontSize: 14,
                fontStyle: "italic",
                marginBottom: 16,
                marginTop: 16,
              }}
            >
              <Tag color="blue" style={{}}>
                {selectedItem.category}
              </Tag>
              {selectedItem.itemDescription}
            </div>
          )}
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col span={12}>
              <Statistic
                color="blue"
                title="Total of All Prices"
                value={formatMoney(
                  selectedItemBreakdown.reduce(
                    (total, item) => total + item.total,
                    0
                  )
                )}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Times Purchased"
                value={selectedItemBreakdown.length}
              />
            </Col>
          </Row>

          <Table
            dataSource={selectedItemBreakdown}
            size="small"
            columns={[
              {
                title: "PO Number",
                dataIndex: "poNumber",
                key: "poNumber",
                render: (text, record) => (
                  <a
                    onClick={() =>
                      handleOpenPurchaseOrder(record.purchaseOrder)
                    }
                  >
                    {text}
                  </a>
                ),
              },
              {
                title: "Ordered Date",
                dataIndex: "orderedDate",
                key: "orderedDate",
                sorter: (a, b) =>
                  dayjs(b.orderedDate).unix() - dayjs(a.orderedDate).unix(),
                defaultSortOrder: "descend",
              },
              { title: "Vendor", dataIndex: "vendorName", key: "vendorName" },
              {
                title: "Total",
                dataIndex: "total",
                key: "total",
                render: (text) => formatMoney(text),
              },
            ]}
            pagination={false}
          />
        </Modal>
      )}

      {/* Purchase Order Modal */}
      {purchaseOrderModalVisible && (
        <PurchaseOrderModal
          visible={purchaseOrderModalVisible}
          purchaseOrder={selectedPurchaseOrder}
          onCancel={() => setPurchaseOrderModalVisible(false)}
          vendors={vendors}
          projects={projects}
          onClose={() => setPurchaseOrderModalVisible(false)}
        />
      )}

      {/* Add Vendor Modal */}
      <Modal
        title={<Tag color={COLORS.PRIMARY}>Add Vendor</Tag>}
        visible={addVendorModalVisible}
        onOk={handleAddVendor}
        onCancel={() => setAddVendorModalVisible(false)}
        confirmLoading={saving}
      >
        <Form form={form}>
          <div style={{ marginTop: 16, marginBottom: 16, fontWeight: 600 }}>
            {selectedItem?.itemDescription}
          </div>
          <Form.Item
            name="vendor"
            rules={[{ required: true, message: "Please select a vendor" }]}
          >
            <Select
              placeholder="Select Vendor"
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {vendors
                // Filter out already selected vendors
                .filter(
                  (vendor) =>
                    !selectedItem?.vendors?.some(
                      (existingVendor) => existingVendor.id === vendor.id
                    )
                )
                // Sort vendors alphabetically by their name
                .sort((a, b) => {
                  const nameA = getVendorName(a.id, vendors).toLowerCase();
                  const nameB = getVendorName(b.id, vendors).toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
                // Map the sorted and filtered vendors to Option components
                .map((vendor) => (
                  <Option key={vendor.id} value={vendor.id}>
                    {getVendorName(vendor.id, vendors)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            rules={[{ required: true, message: "Please enter a price" }]}
          >
            <Input type="number" placeholder="Enter Price" prefix="$" min="0" />
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete Item Modal */}
      <Modal
        title={<Tag color={COLORS.WARNING}>Delete Item</Tag>}
        visible={deleteModalVisible}
        onOk={handleDeleteItem}
        onCancel={() => setDeleteModalVisible(false)}
        confirmLoading={saving}
      >
        Are you sure you wish to delete{" "}
        <strong>{selectedItem?.itemDescription}</strong>?
      </Modal>

      {/* Add Item Modal */}
      <AddItemModal
        visible={addItemModalVisible}
        setVisible={setAddItemModalVisible}
        onAddSuccess={handleAddSuccess}
        items={items}
        item={selectedItem}
        vendors={vendors}
      />
    </Row>
  );
};

export default ItemsTable;
