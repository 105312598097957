import React, { useEffect, useState } from "react";
import {
  Table,
  message,
  Avatar,
  Modal,
  Checkbox,
  Tag,
  Tooltip,
  Row,
  Col,
  Button,
  Input,
  Form,
} from "antd";
import { StopOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import useUsers from "../../Contexts/useUsers";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { updateById } from "../../Functions/updateById";
import axios from "axios";

dayjs.extend(localizedFormat);

const roleOptions = [
  { label: "Admin", value: "admin" },
  { label: "Estimating", value: "estimator" },
  { label: "Purchasing", value: "purchaser" },
  { label: "Receiving", value: "receiver" },
];

function Admin() {
  const { data: users, loading, error, refresh } = useUsers();
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);

  // New state variables for updating roles
  const [isUpdateRolesModalVisible, setIsUpdateRolesModalVisible] =
    useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [updateRolesForm] = Form.useForm();
  const [updateRolesLoading, setUpdateRolesLoading] = useState(false);

  // State variable for search term
  const [searchTerm, setSearchTerm] = useState("");

  const showAddUserModal = () => {
    setIsAddUserModalVisible(true);
  };

  const handleAddUserOk = async () => {
    setFormLoading(true);
    try {
      const values = await form.validateFields();
      const { firstName, lastName, email, roles } = values;

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/clerk/createClerkUser`,
        { firstName, lastName, email, roles },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      message.success("User created successfully.");
      setIsAddUserModalVisible(false);
      refresh(); // Refresh user data after creating user
      form.resetFields(); // Reset form after submission
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      if (error.response && error.response.data) {
        message.error(error.response.data.error || "Failed to create user");
      } else {
        message.error(error.message || "An unknown error occurred.");
      }
    }
  };

  const handleCancelAddUser = () => {
    setIsAddUserModalVisible(false);
    form.resetFields(); // Reset form on cancel
  };

  const onRoleChange = (checkedValues) => {
    setSelectedRoles(checkedValues);
  };

  const getRoleColor = (role) => {
    switch (role) {
      case "admin":
        return "blue";
      case "estimator":
        return "green";
      case "purchaser":
        return "purple";
      case "receiver":
        return "orange";
      default:
        return "gray";
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Function to show the Update Roles Modal
  const showUpdateRolesModal = (user) => {
    setCurrentUser(user);
    // Pre-select roles in the form
    updateRolesForm.setFieldsValue({
      roles: user.publicMetadata?.roles || [],
    });
    setIsUpdateRolesModalVisible(true);
  };

  const handleUpdateRolesOk = async () => {
    setUpdateRolesLoading(true);
    try {
      const values = await updateRolesForm.validateFields();
      const { roles } = values;

      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/clerk/updateUserRole/${currentUser.id}`,
        { roles },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      message.success("User roles updated successfully.");
      setIsUpdateRolesModalVisible(false);
      setUpdateRolesLoading(false);
      updateRolesForm.resetFields();

      // Update the user roles in local state
      refresh(); // Or update your local state if applicable
    } catch (error) {
      setUpdateRolesLoading(false);
      if (error.response && error.response.data) {
        message.error(error.response.data.error || "Failed to update roles");
      } else {
        message.error(error.message || "An unknown error occurred.");
      }
    }
  };

  const handleCancelUpdateRoles = () => {
    setIsUpdateRolesModalVisible(false);
    updateRolesForm.resetFields();
  };

  // Function to handle delete user
  const handleDeleteUser = (user) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      content: (
        <div>
          <p>
            This action is irreversible and will permanently delete the user.
          </p>
          <p>
            <strong>Name:</strong> {user.firstName} {user.lastName}
          </p>
          <p>
            <strong>Email:</strong> {user.emailAddresses[0]?.emailAddress || ""}
          </p>
        </div>
      ),
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        deleteUser(user.id);
      },
    });
  };

  const deleteUser = async (userId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/clerk/deleteClerkUser`,
        { userId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      message.success("User deleted successfully.");
      refresh(); // Refresh the user list
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.error || "Failed to delete user");
      } else {
        message.error(error.message || "An unknown error occurred.");
      }
    }
  };

  // Filter users based on search term
  const filteredUsers =
    users?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const email = user.emailAddresses[0]?.emailAddress.toLowerCase() || "";
      const searchTermLower = searchTerm.toLowerCase();

      return (
        fullName.includes(searchTermLower) || email.includes(searchTermLower)
      );
    }) || [];

  const columns = [
    {
      title: "Avatar",
      dataIndex: "imageUrl",
      key: "avatar",
      render: (imageUrl) => <Avatar src={imageUrl} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <a onClick={() => showUpdateRolesModal(record)}>
          {record.firstName} {record.lastName}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "emailAddresses",
      key: "email",
      render: (emailAddresses) => emailAddresses[0]?.emailAddress || "",
    },
    {
      title: "Last Sign-In",
      dataIndex: "lastSignInAt",
      key: "lastSignInAt",
      render: (lastSignInAt) =>
        lastSignInAt ? (
          dayjs(lastSignInAt).format("MM/DD/YYYY hh:mm A")
        ) : (
          <StopOutlined style={{ opacity: 0.7 }} />
        ),
    },
    {
      title: "Last Active",
      dataIndex: "lastActiveAt",
      key: "lastActiveAt",
      render: (lastActiveAt) =>
        lastActiveAt ? (
          dayjs(lastActiveAt).format("MM/DD/YYYY hh:mm A")
        ) : (
          <StopOutlined style={{ opacity: 0.7 }} />
        ),
    },
    {
      title: "Roles",
      dataIndex: "publicMetadata",
      key: "roles",
      render: (publicMetadata) => (
        <div>
          {publicMetadata?.roles?.length > 0 ? (
            publicMetadata.roles.map((role) => (
              <Tag color={getRoleColor(role)} key={role}>
                {capitalizeFirstLetter(role)}
              </Tag>
            ))
          ) : (
            <Tooltip title="No roles assigned">
              <StopOutlined style={{ color: "red" }} />
            </Tooltip>
          )}
        </div>
      ),
    },
    // New Actions Column
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          danger
          size="small"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteUser(record)}
        >
          Delete
        </Button>
      ),
    },
  ];

  if (error) return <div>Error: {error.message}</div>;

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        <div>
          Users
          {users?.length > 0 && (
            <span
              style={{
                opacity: 0.7,
                marginLeft: 4,
                fontStyle: "italic",
                fontSize: 18,
              }}
            >
              ({filteredUsers.length})
            </span>
          )}
        </div>
      </Col>

      <Col align="right" span={12}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showAddUserModal}
        >
          Add User
        </Button>
      </Col>
      <Col span={24}>
        <Input.Search
          placeholder="Search users..."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={filteredUsers}
          rowKey={(record) => record.id}
          loading={loading}
        />
      </Col>

      {/* Add User Modal */}
      <Modal
        title="Add New User"
        visible={isAddUserModalVisible}
        onOk={handleAddUserOk}
        onCancel={handleCancelAddUser}
        confirmLoading={formLoading}
        okButtonProps={{ disabled: formLoading }}
        cancelButtonProps={{ disabled: formLoading }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input the first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the last name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input the email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Roles"
            name="roles"
            rules={[{ required: true, message: "Please select roles!" }]}
          >
            <Checkbox.Group
              options={roleOptions}
              value={selectedRoles}
              onChange={onRoleChange}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Update Roles Modal */}
      <Modal
        title={`Update Roles for ${currentUser?.firstName} ${currentUser?.lastName}`}
        visible={isUpdateRolesModalVisible}
        onOk={handleUpdateRolesOk}
        onCancel={handleCancelUpdateRoles}
        confirmLoading={updateRolesLoading}
        okButtonProps={{ disabled: updateRolesLoading }}
        cancelButtonProps={{ disabled: updateRolesLoading }}
      >
        <Form form={updateRolesForm} layout="vertical">
          <Form.Item
            label="Roles"
            name="roles"
            rules={[{ required: true, message: "Please select roles!" }]}
          >
            <Checkbox.Group options={roleOptions} />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}

export default Admin;
