import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Spin,
  Table,
  Col,
  Row,
  Switch,
  Input,
  Button,
  message,
  Modal,
  Tag,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  ThunderboltOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import AddAssemblyModal from "../../Components/Assemblies/AddAssemblyModal";
import { formatDate, formatMoney, parsePrice } from "../../Formatters/helpers";
import { SearchInput } from "../../Styled/SearchInput";
import { COLORS, TABLES } from "../../constants";
import { useAssemblies, useItems } from "../../Contexts/useSpecificData";
import { updateById } from "../../Functions/updateById";
import { getPreferredPrice } from "../../Formatters/getPreferredPrice";

export default function BuildAssembly() {
  const { id } = useParams();
  const [assembly, setAssembly] = useState(null);
  const [addedItems, setAddedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showItems, setShowItems] = useState(true);

  const navigate = useNavigate();

  const {
    data: assembliesData,
    loading: assembliesLoading,
    error: assembliesError,
    refresh: refreshAssemblies,
  } = useAssemblies();
  const {
    data: itemsData,
    loading: itemsLoading,
    error: itemsError,
    refresh: refreshItems,
  } = useItems();

  useEffect(() => {
    const selectedAssembly = assembliesData?.find(
      (assembly) => assembly.id === id
    );
    setAssembly(selectedAssembly);
    if (selectedAssembly && selectedAssembly.assemblyItems) {
      setAddedItems(selectedAssembly.assemblyItems);
    }
    setFilteredItems(itemsData || []);
  }, [id, assembliesData, itemsData]);

  useEffect(() => {
    if (assembly && assembly.assemblyName) {
      document.title = assembly.assemblyName; // Set the browser tab title
    } else {
      document.title = "Assemblies";
    }
  }, [assembly]);

  const allQuantitiesValid = () => {
    return addedItems.every((item) => item.quantity && item.quantity > 0);
  };

  const onUpdateSuccess = () => {
    if (allQuantitiesValid()) {
      refreshAssemblies();
      message.success("Assembly updated successfully!");
    } else {
      message.error("Please fill in all quantities before updating.");
    }
  };

  const submitAssemblyUpdate = async () => {
    if (!allQuantitiesValid()) {
      message.error("Please fill in all quantities before submitting.");
      return;
    }

    const assemblyData = { ...assembly };
    assemblyData.assemblyItems = addedItems;

    try {
      await updateById(TABLES.ASSEMBLIES, assemblyData, assembly.id);
      message.success("Assembly updated successfully!");
      refreshAssemblies();
    } catch (error) {
      message.error("Error updating assembly: " + error.message);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const filtered = itemsData.filter(
      (item) =>
        (item.itemNumber && item.itemNumber.includes(value)) ||
        (item.category &&
          item.category.toLowerCase().includes(value.toLowerCase())) ||
        (item.manufacturer &&
          item.manufacturer.toLowerCase().includes(value.toLowerCase())) ||
        (item.itemDescription &&
          item.itemDescription.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredItems(filtered);
  };

  const addItemToAssembly = (item) => {
    if (!addedItems.some((ai) => ai.itemNumber === item.itemNumber)) {
      let price = item.lastPurchased?.price ?? 0;
      let vendor = item.lastPurchased?.vendor ?? null;

      if (!price) {
        const highestVendor = item.vendors.reduce(
          (max, vendor) => (vendor.price > max.price ? vendor : max),
          { price: 0 }
        );
        price = highestVendor.price;
        vendor = highestVendor.id;
      }

      setAddedItems([
        ...addedItems,
        {
          ...item,
          quantity: 1,
          total: price,
          price,
          vendor,
          isLastPurchased: !!item.lastPurchased?.price,
        },
      ]);
    }
  };

  const removeItemFromAssembly = (itemNumber) => {
    setAddedItems(addedItems.filter((item) => item.itemNumber !== itemNumber));
  };

  const handleQuantityChange = (value, itemNumber) => {
    setAddedItems(
      addedItems.map((item) =>
        item.itemNumber === itemNumber
          ? {
              ...item,
              quantity: parseFloat(value) || 0,
              total: (parseFloat(value) || 0) * (parsePrice(item.price) || 0),
            }
          : item
      )
    );
  };

  const getMaterialCost = (addedItems) => {
    return addedItems.reduce(
      (total, item) =>
        total + (item.quantity || 0) * (parsePrice(item.price) || 0),
      0
    );
  };

  const generateFilterOptions = (data, key) => {
    return [...new Set(data.map((item) => item[key]))]
      .sort((a, b) => a.localeCompare(b))
      .map((value) => ({
        text: value,
        value: value,
      }));
  };

  const softDeleteAssembly = async (id) => {
    try {
      const updatedAssemblyData = { ...assembly, deleted: true };
      await updateById(TABLES.ASSEMBLIES, updatedAssemblyData, assembly.id);
      message.success("Assembly marked as deleted successfully!");
      navigate("/estimating/assemblies");
    } catch (error) {
      console.error("Error marking assembly as deleted:", error);
      message.error("Error marking assembly as deleted: " + error.message);
    }
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: generateFilterOptions(itemsData, "category"),
      onFilter: (value, record) => record.category === value,
      filterSearch: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "description",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      filters: generateFilterOptions(itemsData, "manufacturer"),
      onFilter: (value, record) => record.manufacturer === value,
      filterSearch: true,
    },
    {
      title: "Manufacturer ID",
      dataIndex: "manufacturerId",
      key: "manufacturerId",
    },
    { title: "Size", dataIndex: "orderBatchSize", key: "size" },
    { title: "UOM", dataIndex: "uom", key: "uom" },
    { title: "Item", dataIndex: "itemNumber", key: "itemNumber" },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => formatMoney(getPreferredPrice(record)),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          icon={
            addedItems.some(
              (ai) => ai.itemNumber === record.itemNumber
            ) ? null : (
              <PlusOutlined />
            )
          }
          type="primary"
          style={{ width: "100%" }}
          disabled={addedItems.some(
            (ai) => ai.itemNumber === record.itemNumber
          )}
          onClick={() => addItemToAssembly(record)}
        >
          {addedItems.some((ai) => ai.itemNumber === record.itemNumber)
            ? "Added"
            : "Add"}
        </Button>
      ),
    },
  ];

  const addedItemsColumns = [
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "Description", dataIndex: "itemDescription", key: "description" },
    { title: "Manufacturer", dataIndex: "manufacturer", key: "manufacturer" },
    {
      title: "Manufacturer ID",
      dataIndex: "manufacturerId",
      key: "manufacturerId",
      width: 140,
    },
    { title: "Size", dataIndex: "orderBatchSize", key: "size" },
    { title: "UOM", dataIndex: "uom", key: "uom" },
    { title: "Item", dataIndex: "itemNumber", key: "itemNumber" },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => formatMoney(record.price),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          type="number"
          min={0.01}
          step={0.01}
          value={record.quantity ? record.quantity : ""}
          onChange={(e) =>
            handleQuantityChange(e.target.value, record.itemNumber)
          }
          style={{ maxWidth: 70 }}
        />
      ),
    },
    {
      title: "Total",
      key: "total",
      render: (text, record) => {
        return (
          <Tag success color="green" style={{ fontSize: 14 }}>
            {formatMoney((record.quantity || 0) * parsePrice(record.price))}
          </Tag>
        );
      },
    },
    {
      title: "Remove",
      key: "action",
      render: (text, record) => (
        <Button
          icon={<CloseCircleOutlined style={{ color: "#f10000" }} />}
          type="none"
          onClick={() => removeItemFromAssembly(record.itemNumber)}
        />
      ),
    },
  ];

  if (assembliesLoading || itemsLoading) return <Spin />;

  return (
    <Row gutter={[0, 12]}>
      <Col
        span={12}
        style={{
          display: "flex",
          fontSize: 18,
          fontWeight: 600,
        }}
      >
        <AddAssemblyModal
          title="Update Assembly"
          assembly={assembly}
          onAddSuccess={onUpdateSuccess}
        >
          <div
            style={{
              padding: 8,
              borderRadius: 8,
              backgroundColor: "white",
              cursor: "pointer",
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <div>
              {assembly?.assemblyName}{" "}
              <EditOutlined
                style={{
                  fontSize: "18px",
                  color: "#08c",
                  marginLeft: 8,
                  marginTop: 8,
                  alignSelf: "flex-start",
                }}
              />
            </div>
            <div style={{ fontSize: 14, fontWeight: "normal" }}>
              {assembly?.description}
            </div>
          </div>
        </AddAssemblyModal>
      </Col>

      <Col
        span={12}
        style={{
          textAlign: "right",
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          onClick={() =>
            Modal.confirm({
              title: "Are you sure you want to delete this assembly?",
              icon: <ExclamationCircleOutlined />,
              content: "This action cannot be undone.",
              okText: "Yes",
              okType: "danger",
              cancelText: "No",
              onOk: () => {
                softDeleteAssembly(assembly.id);
              },
            })
          }
          icon={<WarningOutlined />}
          danger
          style={{ marginRight: 32, backgroundColor: COLORS.WARNING }}
        >
          Delete Assembly
        </Button>
        <Switch
          checkedChildren="Hide Items"
          unCheckedChildren="Show Items"
          defaultChecked
          onChange={() => setShowItems(!showItems)}
          style={{
            marginRight: 16,
            backgroundColor: showItems ? COLORS.SECONDARY : undefined,
          }}
        />
        <Button
          type="primary"
          onClick={submitAssemblyUpdate}
          disabled={!addedItems.length || !allQuantitiesValid()}
          style={{ marginLeft: 16 }}
          icon={<ThunderboltOutlined />}
          loading={assembliesLoading || itemsLoading}
        >
          Save Assembly
        </Button>
      </Col>
      <Col span={24}>
        {showItems && (
          <>
            <SearchInput
              placeholder="Search items"
              value={searchText}
              onChange={handleSearch}
            />
            <Table
              dataSource={filteredItems}
              columns={columns}
              loading={itemsLoading}
              rowKey="itemNumber"
              pagination={{
                defaultPageSize: 4,
                showSizeChanger: true,
                pageSizeOptions: ["4", "10", "25", "50", "100"],
              }}
              size="small"
              style={{ marginTop: 8 }}
            />
          </>
        )}
      </Col>
      <Col span={24}></Col>
      <Col
        span={24}
        style={{
          marginBottom: 24,
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            padding: "8px 16px",
            fontWeight: "600",
            fontSize: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f0f0f0",
          }}
        >
          <span>Added Items ({addedItems.length})</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "16px", opacity: 0.7 }}>
              Labor Cost: {formatMoney(assembly?.laborCost)}
            </span>
            <span style={{ marginRight: "16px", opacity: 0.7 }}>
              Material Cost: {formatMoney(getMaterialCost(addedItems))}
            </span>
            <span>
              Total Cost:{" "}
              {formatMoney(
                parseFloat(assembly?.laborCost) + getMaterialCost(addedItems)
              )}
            </span>
          </div>
        </div>
        <Table
          dataSource={addedItems}
          loading={itemsLoading}
          columns={addedItemsColumns}
          rowKey="itemNumber"
          pagination={false}
          size="small"
        />
      </Col>
    </Row>
  );
}
