import React, { useState, useEffect } from "react";
import { Col, Row, Button, Radio } from "antd";
import {
  DollarCircleOutlined,
  EditOutlined,
  PlusOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchInput } from "../../Styled/SearchInput";
import AddReturnOrder from "../../Components/Returns/AddReturnOrder";
import ReturnTable from "../../Components/Returns/ReturnTable";
import {
  useReturnOrders,
  useProjects,
  useItems,
} from "../../Contexts/useSpecificData";

const Returns = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTable, setActiveTable] = useState(() => {
    const path = location.pathname.split("/").pop();
    return ["return-orders", "edit-return-orders"].includes(path)
      ? path
      : "return-orders";
  });

  const { data: returnOrders, refresh: refreshReturnOrders } =
    useReturnOrders();
  const { data: projects, refresh: refreshProjects } = useProjects();
  const { data: items, refresh: refreshItems } = useItems();

  const iconStyle = { marginRight: "8px" };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (["return-orders", "edit-return-orders"].includes(path)) {
      setActiveTable(path);
    } else {
      navigate("/purchasing/returns/return-orders", { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    let title = "Returns";
    if (activeTable === "return-orders") {
      title = "Returns - Return Orders";
    } else if (activeTable === "edit-return-orders") {
      title = "Returns - Edit Return Orders";
    }
    document.title = title;
  }, [activeTable]);

  const handleTableSwitch = (e) => {
    const value = e.target.value;
    setActiveTable(value);
    navigate(`/purchasing/returns/${value}`, { replace: true });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={6}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        Returns
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Radio.Group value={activeTable} onChange={handleTableSwitch}>
          <Radio.Button style={{ textAlign: "center" }} value="return-orders">
            <RetweetOutlined style={iconStyle} /> Return Order
          </Radio.Button>
          <Radio.Button
            style={{ textAlign: "center" }}
            value="edit-return-orders"
          >
            <EditOutlined style={iconStyle} /> Edit Return Order
          </Radio.Button>
        </Radio.Group>
      </Col>
      <Col align="right" span={6}>

      </Col>
      <Col span={24}>
        {activeTable === "return-orders" && <AddReturnOrder />}
        {activeTable === "edit-return-orders" && <ReturnTable />}
      </Col>
    </Row>
  );
};

export default Returns;
