import React from "react";
import { Row, Col, Card, Button, Input } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { SearchInput } from "./SearchInput";

//comment

const TableHeader = ({
  totalItems,
  totalResults,
  searchValue,
  onSearchChange,
  removeFilters,
  showRemoveFilters = true,
    searchPlaceholder = "Search Items...",
  title,
}) => {
  return (
    <>
      <Card size="small" style={{ width: "100%" }}>
        <Row>
          <Col
            span={12}
            style={{ fontSize: 18, fontStyle: "italic", opacity: 0.7 }}
                  >
                      {title && (
                          <span>{title} - </span>
  )}
            {totalItems} Results
          </Col>
          {showRemoveFilters && (
            <Col span={12} style={{ textAlign: "right" }}>
              <Button onClick={removeFilters} icon={<FilterOutlined />}>
                Remove Filters
              </Button>
            </Col>
          )}
        </Row>
      </Card>

      <SearchInput
        placeholder={searchPlaceholder}
        resultsLength={totalResults}
        value={searchValue}
        onChange={onSearchChange}
        style={{  }}
      />
    </>
  );
};

export default TableHeader;
