import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Table,
  InputNumber,
  Select,
  Modal,
  Tag,
  Popover,
  message,
  DatePicker,
  Divider,
  Spin,
  Card,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  RocketOutlined,
  StopOutlined,
  FilePdfOutlined,
  CopyOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import AddressAutocomplete from "../Google/AddressAutocomplete";
import {
  useItems,
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { COLORS } from "../../constants";
import { formatMoney } from "../../Formatters/helpers";
import PhoneInput from "react-phone-input-2";
import AdditionalTable from "./AdditionalTable";
import { s3Upload } from "../../Functions/s3Upload";
import { generatePdfBlob } from "./PurchaseOrderPDF";
import getNextPurchaseOrderNumber from "../../Formatters/getPoNumber";
import { incrementPONumber } from "../../Formatters/incrementPoNumber";
import { useUser } from "@clerk/clerk-react";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES, EMAILBODY } from "../../constants";
import { v4 as uuidv4 } from "uuid";
import { sendEmail } from "../../Functions/sendEmail";
import { useNavigate } from "react-router-dom";
import { getProjectName } from "../../Formatters/getProjectName";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";
import DuplicatePOModal from "./DuplicatePOModal";
import { getPoRevisionNumber } from "../../Formatters/getPoRevisionNumber";


const { Option } = Select;

function AddPurchaseOrder() {
  const [form] = Form.useForm();
  const { data: vendors } = useVendors();
  const { data: itemsData } = useItems();
  const { data: projects } = useProjects();
  const { data: purchaseOrders, refresh: refreshPurchaseOrders } =
    usePurchaseOrders();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [requiredDate, setRequiredDate] = useState(null);
  const [poLines, setPoLines] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [other, setOther] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shipToAddress, setShipToAddress] = useState(
    "2624 Weaver Way Suite 200, Doraville, GA 30340"
  );
  const [notes, setNotes] = useState("");
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [additionalTables, setAdditionalTables] = useState([]);
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [poNumber, setPoNumber] = useState(""); // Purchase Order number state
  const userEmail = useUser().user?.primaryEmailAddress?.emailAddress;
  const userName = useUser().user?.fullName;
  const [loading, setLoading] = useState(false);
  const [existingOrder, setExistingOrder] = useState(null);
  const navigate = useNavigate();
  const [groupPOs, setGroupPOs] = useState([]);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const handleOpenDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };

  const handleCloseDuplicateModal = () => {
    setIsDuplicateModalVisible(false);
  };

  console.log("is edit? ", isEdit);

const handleDuplicateItems = (lines, vendorId, po) => {
  console.log("duplicate lines are ", lines);
  console.log("duplicate vendor id is ", vendorId);
  console.log("duplicate po is ", po);

  // Find the vendor object using the vendorId
  const vendorObj = vendors.find((v) => v.id === vendorId);

  if (!vendorObj) {
    console.error(`Vendor with ID ${vendorId} not found.`);
    return;
  }

  const vendorEmail = vendorObj.vendorEmail || "";
  console.log("vendor email is ", vendorEmail);

  const additionalEmails = vendorObj.additionalEmails || [];

  const combinedEmails = vendorEmail
    ? [vendorEmail, ...additionalEmails]
    : additionalEmails;

  if (po.emailAddresses && po.emailAddresses.length > 0) {
    console.log("po email addresses are ", po.emailAddresses);
    setEmails(po.emailAddresses);
  } else {
    setEmails(combinedEmails);
  }

  // Reset qtyReceived to 0 for each duplicated line
  const linesWithResetQtyReceived = lines.map((line) => ({
    ...line,
    qtyReceived: 0, // Ensures qtyReceived is reset to zero
  }));

  setPoLines(convertLinesToState(linesWithResetQtyReceived));
  console.log("new po lines are ", linesWithResetQtyReceived);
  setSelectedVendor(vendorId); // Since selectedVendor expects a vendor ID
};


  const clearForm = () => {
    form.resetFields();
    setSelectedVendor(null);
    setSelectedProject(null);
    setRequiredDate(null);
    setPoLines([]);
    setShipping(0);
    setOther(0);
    setDiscount(0);
    setShipToAddress("2624 Weaver Way Suite 200, Doraville, GA 30340");
    setNotes("");
    setEmails([]);
    setNewEmail("");
    setPopoverVisible(false);
    setAdditionalTables([]);
    setPoNumber("");
    setExistingOrder(null);
    setGroupPOs([]);
    setIsDuplicateModalVisible(false);
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
    zIndex: 1000, // Ensures it's on top of other elements
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  function convertLinesToState(lines) {
    return lines.map((line) => ({
      key: line.itemId,
      itemId: line.itemId,
      category: line.category,
      itemNumber: line.itemNumber,
      itemDescription: line.itemDescription,
      manufacturer: line.manufacturer,
      manufacturerId: line.manufacturerId,
      orderBatchSize: line.orderBatchSize,
      uom: line.uom,
      qty:
        typeof line.qtyOrdered === "string"
          ? parseInt(line.qtyOrdered, 10)
          : line.qtyOrdered, // Check if it's a string before parsing
      qtyReceived:
        typeof line.qtyReceived === "string"
          ? parseInt(line.qtyReceived, 10)
          : line.qtyReceived || 0, // Check for string and default to 0 if invalid
      price:
        typeof line.itemPrice === "string"
          ? parseFloat(line.itemPrice)
          : line.itemPrice, // Handle price similarly
      total:
        (typeof line.qtyOrdered === "string"
          ? parseInt(line.qtyOrdered, 10)
          : line.qtyOrdered) *
        (typeof line.itemPrice === "string"
          ? parseFloat(line.itemPrice)
          : line.itemPrice),
    }));
    console.log("lines are ", lines);
  }

  useEffect(() => {
    if (id) {
      const existingOrder = purchaseOrders.find((order) => order.id === id);
      console.log("existingOrder", existingOrder);

      document.title = `Edit Purchase Order ${existingOrder?.poNumber}`;

      if (existingOrder) {
        console.log("existing purchase order is ", existingOrder);
        setExistingOrder(existingOrder);
        setSelectedVendor(existingOrder.vendor);
        setSelectedProject(existingOrder.projectNumber);
        setRequiredDate(dayjs(existingOrder.requiredDate));
        setPoLines(convertLinesToState(existingOrder.lines));
        setShipping(
          typeof existingOrder.shippingCosts === "string"
            ? parseFloat(existingOrder.shippingCosts) || 0
            : existingOrder.shippingCosts || 0
        );
        setOther(
          typeof existingOrder.other === "string"
            ? parseFloat(existingOrder.other) || 0
            : existingOrder.other || 0
        );
        setDiscount(
          typeof existingOrder.discount === "string"
            ? parseFloat(existingOrder.discount) || 0
            : existingOrder.discount || 0
        );
        setShipToAddress(existingOrder.shipToAddress);
        setNotes(existingOrder.poNotes);
        setIsEdit(true);
        setPoNumber(existingOrder.poNumber);
        setEmails(existingOrder.emailAddresses || []);

        console.log("existingOrder.emails", existingOrder.emailAddresses);

        if (existingOrder.groupNumber) {
          const groupPOs = purchaseOrders.filter(
            (po) => po.groupNumber === existingOrder.groupNumber && po.id !== id
          );
          setGroupPOs(groupPOs); // Assume you have state setGroupPOs initialized
        }

        const vendor = vendors.find(
          (vendor) => vendor.id === existingOrder.vendor
        );

        const vendorEmail = vendor?.vendorEmail;
        const additionalEmails = vendor?.additionalEmails || [];

        setEmails(
          existingOrder.emailAddresses || (vendorEmail ? [vendorEmail] : [])
        );

        // If additionalEmails exist, append them to the existing emails
        if (additionalEmails.length > 0) {
          setEmails((prevEmails) => [...prevEmails, ...additionalEmails]);
        }

        form.setFieldsValue({
          ...existingOrder,
          requiredDate: dayjs(existingOrder.requiredDate),
        });
      } else {
        // Navigate to the main edit page if no existing order is found
        // navigate("/purchasing/purchase/edit-purchase-orders");
      }
    }
  }, [id, purchaseOrders, form, vendors]);

  const handleVendorChange = (value) => {
    const vendor = vendors.find((vendor) => vendor.id === value);
    const vendorEmail = vendor?.vendorEmail || "";
    const additionalEmails = vendor?.additionalEmails || [];

    const combinedEmails = vendorEmail
      ? [vendorEmail, ...additionalEmails]
      : additionalEmails;

    if (poLines.length > 0 || additionalTables.length > 0) {
      Modal.confirm({
        title: "Change Vendor",
        content: "Changing the vendor will reset the order. Continue?",
        onOk: () => {
          form.resetFields();
          setSelectedVendor(value);
          setPoLines([]);
          setEmails(combinedEmails);
          setAdditionalTables([]);
        },
      });
    } else {
      setSelectedVendor(value);
      setEmails(combinedEmails);
    }
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  const handleItemSelect = (
    itemId,
    setPoLinesFunc,
    isAdditionalTable = false,
    tableIndex = null
  ) => {
    const selectedItem = itemsData.find((item) => item.id === itemId);
    if (!selectedItem) {
      console.error("Selected item not found");
      return;
    }

    // Get vendorItemId first
    const vendorItemId = selectedItem.vendors.find(
      (vendor) => vendor.id === selectedVendor
    )?.itemId;

    console.log("vendor item id is ", vendorItemId);

    // Check if the item already exists in the corresponding table by comparing vendorItemId
    const alreadyExists =
      tableIndex !== null
        ? additionalTables[tableIndex].poLines.some(
            (line) => line.itemId === vendorItemId
          )
        : poLines.some((line) => line.itemId === vendorItemId);

    if (alreadyExists) {
      message.error("This item has already been added to the order.");
      return;
    }

    const vendorPrice =
      selectedItem.vendors.find((vendor) => vendor.id === selectedVendor)
        ?.price || 0;

    console.log("vendor item id is ", vendorItemId);

    const newPoLine = {
      key: tableIndex !== null ? `${tableIndex}-${itemId}` : `main-${itemId}`,
      itemId: vendorItemId,
      category: selectedItem.category,
      itemNumber: selectedItem.itemNumber,
      itemDescription: selectedItem.itemDescription,
      manufacturer: selectedItem.manufacturer,
      manufacturerId: selectedItem.manufacturerId,
      orderBatchSize: selectedItem.orderBatchSize,
      uom: selectedItem.uom,
      qty: 0,
      price: vendorPrice,
      total: 0,
    };

    if (isAdditionalTable && tableIndex !== null) {
      setAdditionalTables((prevTables) =>
        prevTables.map((table, idx) => {
          if (idx === tableIndex) {
            const newPoLines = [...table.poLines, newPoLine];
            return { ...table, poLines: newPoLines };
          }
          return table;
        })
      );
    } else {
      setPoLinesFunc((prev) => [...prev, newPoLine]);
    }
  };

  const handleDeletePurchaseOrder = async () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to cancel this purchase order?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Find the purchase order and get the original date from the last document
          const existingOrder = purchaseOrders.find((order) => order.id === id);
          const lastDocument =
            existingOrder.documents && existingOrder.documents.length > 0
              ? existingOrder.documents[existingOrder.documents.length - 1]
              : null;
          const originalDate = lastDocument ? lastDocument.dateSubmitted : "";
          const documentUrl = lastDocument ? lastDocument.documentUrl : "";

          // Update the purchase order by setting `deleted` to true
          const updatedPurchaseOrder = {
            deleted: true,
          };

          await updateById(TABLES.PURCHASE_ORDERS, updatedPurchaseOrder, id);

          // Send the cancellation email
          const emailBody = EMAILBODY.CANCELLED_PURCHASE_ORDER({
            poNum: existingOrder.poNumber,
            vendor: getVendorName(existingOrder.vendor, vendors),
            vendorFirstName:
              getVendorDetails(existingOrder.vendor).firstName || "",
            capFirstName: userName.split(" ")[0],
            originalDate,
          });

          await sendEmail({
            subject: emailBody.subject,
            htmlBody: emailBody.body,
            emailAddresses: existingOrder.emailAddresses,
            attachmentUrls: [documentUrl],
            sender: userEmail,
          });

          message.success("Purchase order deleted and email sent successfully");
          navigate("/purchasing/purchase/edit-purchase-orders");
          refreshPurchaseOrders();
        } catch (error) {
          console.error("Error deleting purchase order:", error);
          message.error("Failed to delete purchase order");
        }
      },
    });
  };

  const handleQtyChange = (key, value, setPoLinesFunc, tableIndex = null) => {
    if (tableIndex !== null) {
      setAdditionalTables((prevTables) =>
        prevTables.map((table, idx) => {
          if (idx === tableIndex) {
            return {
              ...table,
              poLines: table.poLines.map((line) =>
                line.key === key
                  ? { ...line, qty: value, total: value * line.price }
                  : line
              ),
            };
          }
          return table;
        })
      );
    } else {
      setPoLinesFunc((prev) =>
        prev.map((line) =>
          line.key === key
            ? { ...line, qty: value, total: value * line.price }
            : line
        )
      );
    }
  };

  const handleRemoveItem = (key, setPoLinesFunc, tableIndex = null) => {
    if (tableIndex !== null) {
      setAdditionalTables((prevTables) =>
        prevTables.map((table, idx) => {
          if (idx === tableIndex) {
            return {
              ...table,
              poLines: table.poLines.filter((line) => line.key !== key),
            };
          }
          return table;
        })
      );
    } else {
      setPoLinesFunc((prev) => prev.filter((line) => line.key !== key));
    }
  };

  const handleAddEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(newEmail)) {
      setEmails((prev) => [...prev, newEmail]);
      setNewEmail("");
      setPopoverVisible(false);
    } else {
      message.error("Please enter a valid email address.");
    }
  };

  const handleRemoveEmail = (email) => {
    setEmails((prev) => prev.filter((e) => e !== email));
  };

  const handleDateChange = (date) => {
    setRequiredDate(date && date.isValid() ? date : null);
  };

  const handleAdditionalDateChange = (index, date) => {
    setAdditionalTables((prev) =>
      prev.map((table, i) => {
        if (i === index) {
          return { ...table, requiredDate: date };
        }
        return table;
      })
    );
  };

  const handleRemoveAdditionalTable = (index) => {
    setAdditionalTables((prev) => {
      const updatedTables = prev.filter((_, i) => i !== index);
      return updatedTables;
    });
  };

  const handleAddAdditionalRequiredDate = () => {
    Modal.confirm({
      title: "Copy Items?",
      okText: "Yes",
      cancelText: "No",
      content: "Would you like to copy the items from the current table?",
      onOk: () => {
        setAdditionalTables((prev) => [
          ...prev,
          {
            requiredDate: null,
            poLines: [...poLines],
            shipping,
            other,
            discount,
            notes,
          },
        ]);
      },
      onCancel: () => {
        setAdditionalTables((prev) => [
          ...prev,
          {
            requiredDate: null,
            poLines: [],
            shipping: 0,
            other: 0,
            discount: 0,
            notes: "",
          },
        ]);
      },
    });
  };

  const filteredVendors = vendors.filter((vendor) =>
    itemsData.some((item) =>
      item.vendors.some((itemVendor) => itemVendor.id === vendor.id)
    )
  );

  const sortedVendors = filteredVendors.sort((a, b) =>
    getVendorName(a.id, vendors).localeCompare(getVendorName(b.id, vendors))
  );

  const getVendorDetails = (vendorId) => {
    const vendor = vendors.find((v) => v.id === vendorId);
    if (!vendor) {
      return { contactName: "", phoneNumber: "", email: "" };
    }
    return {
      contactName: `${vendor.vendorFirstName || ""} ${
        vendor.vendorLastName || ""
      }`.trim(),
      firstName: vendor.vendorFirstName || "",
      lastName: vendor.vendorLastName || "",
      phoneNumber: vendor.vendorPhone || "",
      email: vendor.vendorEmail || "",
    };
  };

  const columns = (setPoLinesFunc, tableIndex = null) => [
    {
      title: "Line",
      dataIndex: "key",
      key: "line",
      render: (text, record, index) => String(index + 1).padStart(2, "0"),
      width: 40,
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text) => <span>{text}</span>,
    },
    { title: "Manufacturer", dataIndex: "manufacturer", key: "manufacturer" },
    { title: "Mfg ID", dataIndex: "manufacturerId", key: "manufacturerId" },
    {
      title: "UOM",
      dataIndex: "orderBatchSize",
      key: "orderBatchSize",
      render: (text, record) => `${text} ${record.uom}`,
    },
    ...(isEdit
      ? [
          {
            title: "Received",
            dataIndex: "qtyReceived",
            key: "qtyReceived",
            render: (text) => <span>{text}</span>,
          },
        ]
      : []),
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (text, record) => (
        <InputNumber
          style={{ width: 80 }}
          min={isEdit ? record.qtyReceived : 0} // Enforce minimum when in edit mode
          value={record.qty}
          onChange={(value) => {
            handleQtyChange(record.key, value, setPoLinesFunc, tableIndex);
          }}
          type="number"
          className={record.qty === 0 || record.qty === null ? "zero-qty" : ""}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => formatMoney(text),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => formatMoney(text),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) =>
        record.qtyReceived > 0 ? (
          <StopOutlined />
        ) : (
          <Button
            onClick={() =>
              handleRemoveItem(record.key, setPoLinesFunc, tableIndex)
            }
            danger
            disabled={loading}
            type="link"
            icon={<DeleteOutlined />}
          />
        ),
    },
  ];

  const subtotal = poLines.reduce((sum, line) => sum + line.total, 0);
  const totalPO = subtotal + shipping + other - discount;

  console.log("Total is ", totalPO);

  const hasItemsWithQty = (poLines) => {
    return (
      Array.isArray(poLines) &&
      poLines.length > 0 &&
      poLines.some((line) => line.qty > 0)
    );
  };

  const isFormValid = () => {
    const hasValidQty = (lines) => lines.every((line) => line.qty > 0);

    const isMainTableValid = poLines.length > 0 && hasValidQty(poLines);
    const isAdditionalTablesValid = isEdit
      ? true
      : additionalTables.every(
          (table) =>
            table.poLines.length > 0 &&
            hasValidQty(table.poLines) &&
            table.requiredDate
        );

    return (
      selectedVendor &&
      shipToAddress &&
      selectedProject &&
      requiredDate &&
      isMainTableValid &&
      isAdditionalTablesValid
    );
  };

  const hasReceivedItems = () => {
    return poLines.some((line) => line.qtyReceived > 0);
  };

  const emailContent = (
    <div>
      <Input
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        placeholder="Enter new email"
        style={{ width: 200, marginBottom: 8 }}
        type="email"
      />
      <Button disabled={loading} type="primary" onClick={handleAddEmail} block>
        Add
      </Button>
    </div>
  );

  const handleSubmitPurchaseOrder = async () => {
    try {
      setLoading(true);

      // Refresh purchase orders to ensure the PO number is the next in line, but only if not editing
      if (!isEdit) {
        await refreshPurchaseOrders();
      }

      let poNumber = getNextPurchaseOrderNumber(purchaseOrders); // Get the next available PO number

      // Resolve vendor and project details
      const vendorDetails = vendors.find((v) => v.id === selectedVendor);
      const projectDetails = projects.find((p) => p.id === selectedProject);

      console.log("selected project is", selectedProject);
      console.log("project details", projectDetails);

      const buyer = userEmail;

      // Determine the group number if there are multiple tables
      let groupNumber = null;
      if (additionalTables.length > 0) {
        // Search for existing group numbers in purchase orders
        const existingGroupNumbers = purchaseOrders
          .map((order) => order.groupNumber)
          .filter(Boolean); // Filter out null or undefined values

        if (existingGroupNumbers.length === 0) {
          groupNumber = "G-1";
        } else {
          // Extract the numeric part and find the highest existing group number
          const maxGroupNumber = Math.max(
            ...existingGroupNumbers.map((gn) =>
              parseInt(gn.replace("G-", ""), 10)
            )
          );
          groupNumber = `G-${maxGroupNumber + 1}`;
        }
      }

      // Generate and upload PDF for the main table
      const mainBlob = await generatePdfBlob(
        {
          poLines,
          requiredDate,
          notes,
          shipping,
          other,
          discount,
          subtotal,
          totalPO,
          additionalTables: [], // No additional tables for the main PDF
          poNumber: isEdit
            ? getPoRevisionNumber(
                existingOrder.poNumber,
                existingOrder.documents
              )
            : poNumber, // Only pass if isEdit is true
          selectedVendor: {
            name: vendorDetails ? getVendorName(vendorDetails.id, vendors) : "",
            contactName: vendorDetails
              ? `${vendorDetails.vendorFirstName} ${vendorDetails.vendorLastName}`
              : "",
            email: vendorDetails ? vendorDetails.vendorEmail : "",
            phone: vendorDetails
              ? formatPhoneNumber(vendorDetails.vendorPhone)
              : "",
          },
          shipToAddress,
          selectedProject: getProjectName(selectedProject, projects) || "",
          userEmail,
          userName,
        },
        isEdit,
        isEdit ? existingOrder.poNumber : undefined, // Only pass if isEdit is true
        isEdit ? existingOrder.orderedDate : undefined // Only pass if isEdit is true
      );

      const mainUrl = await s3Upload(
        mainBlob,
        isEdit
          ? `[REVISED] PO-${getPoRevisionNumber(
              existingOrder.poNumber,
              existingOrder.documents
            )}-Bmarko-${new Date().toISOString()}.pdf`
          : `PO-${poNumber}-Bmarko-${new Date().toISOString()}.pdf`
      );
      console.log("Main PDF uploaded URL:", mainUrl);

      if (isEdit) {
        const updatedPurchaseOrder = {
          ...existingOrder, // Using existingOrder instead of selectedProject
          documents: [
            ...(existingOrder.documents || []), // Ensure it's an array or use an empty array
            {
              dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
              documentUrl: mainUrl,
              number: (existingOrder.documents?.length || 0) + 1,
              uuid: uuidv4(),
            },
          ],
          lines: poLines.map((line, index) => ({
            category: line.category,
            itemDescription: line.itemDescription,
            itemId: line.itemId,
            itemNumber: line.itemNumber,
            itemPrice:
              typeof line.price === "string"
                ? parseFloat(line.price)
                : line.price,
            lineNumber: `${String(index + 1).padStart(2, "0")}`,
            lineStatus: "Open",
            manufacturer: line.manufacturer,
            manufacturerId: line.manufacturerId,
            orderBatchSize: line.orderBatchSize,
            qtyOrdered:
              typeof line.qty === "string" ? parseInt(line.qty, 10) : line.qty, // Check for string before parsing qtyOrdered
            qtyReceived:
              typeof line.qtyReceived === "string"
                ? parseInt(line.qtyReceived, 10)
                : line.qtyReceived || 0,
            uom: line.uom,
            vendor: selectedVendor,
          })),
          orderedDate: dayjs().format("MM/DD/YYYY"),
          other,
          poNotes: notes,
          projectNumber: selectedProject || "",
          requiredDate: requiredDate.format("MM/DD/YYYY"),
          shippingCosts: shipping,
          discount: discount,
          shipToAddress,
          emailAddresses: emails, // Include the email addresses
        };

        await updateById(TABLES.PURCHASE_ORDERS, updatedPurchaseOrder, id);
        console.log("Purchase Order updated:", updatedPurchaseOrder);
      } else {
        // Create new purchase order
        const mainPurchaseOrder = {
          id: uuidv4(), // Generate a unique ID
          buyer,
          discount: discount,
          created: dayjs().format("MM/DD/YYYY h:mm:ss A"),
          documents: [
            {
              dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
              documentUrl: mainUrl,
              number: 1,
              uuid: uuidv4(),
            },
          ],
          factory: "Doraville",
          lines: poLines.map((line, index) => ({
            category: line.category,
            itemDescription: line.itemDescription,
            itemId: line.itemId,
            itemNumber: line.itemNumber,
            itemPrice: line.price,
            lineNumber: `${String(index + 1).padStart(2, "0")}`,
            lineStatus: "Open",
            manufacturer: line.manufacturer,
            manufacturerId: line.manufacturerId,
            orderBatchSize: line.orderBatchSize,
            qtyOrdered: line.qty,
            qtyReceived: 0, // Qty received will always be 0 initially
            uom: line.uom,
            vendor: selectedVendor,
          })),
          orderedDate: dayjs().format("MM/DD/YYYY"),
          other,
          poNotes: notes,
          poNumber,
          poStatus: "Open",
          projectNumber: selectedProject || "",
          requiredDate: requiredDate.format("MM/DD/YYYY"),
          shippingCosts: shipping,
          shipToAddress,
          tenantId: "bmarko",
          vendor: selectedVendor,
          emailAddresses: emails, // Include the email addresses
          groupNumber: groupNumber || null, // Assign groupNumber if applicable
        };

        await addItemsToTable(TABLES.PURCHASE_ORDERS, mainPurchaseOrder);
        console.log("Main Purchase Order saved:", mainPurchaseOrder);
      }

      // Prepare for sending multiple PDFs if additional tables exist
      let attachmentUrls = [mainUrl];
      let purchaseOrdersInfo = [
        {
          poNum: poNumber,
          reqDateFormatted: requiredDate.format("MM/DD/YYYY"),
        },
      ];

      // Generate and upload PDFs for additional tables
      for (let i = 0; i < additionalTables.length; i++) {
        // Increment PO number for each additional table
        poNumber = incrementPONumber(poNumber, 1);

        console.log("selectedProject in submit is ", selectedProject);

        const tableBlob = await generatePdfBlob({
          poLines: additionalTables[i].poLines,
          requiredDate: additionalTables[i].requiredDate,
          notes: additionalTables[i].notes,
          shipping: additionalTables[i].shipping,
          other: additionalTables[i].other,
          discount: additionalTables[i].discount,
          subtotal: additionalTables[i].poLines.reduce(
            (sum, line) => sum + line.total,
            0
          ),
          totalPO:
            additionalTables[i].poLines.reduce(
              (sum, line) => sum + line.total,
              0
            ) +
            additionalTables[i].shipping +
            additionalTables[i].other -
            additionalTables[i].discount,
          additionalTables: [], // No nested additional tables
          poNumber,
          selectedVendor: {
            name: vendorDetails ? getVendorName(vendorDetails.id, vendors) : "",
            contactName: vendorDetails
              ? `${vendorDetails.vendorFirstName} ${vendorDetails.vendorLastName}`
              : "",
            email: vendorDetails ? vendorDetails.vendorEmail : "",
            phone: vendorDetails
              ? formatPhoneNumber(vendorDetails.vendorPhone)
              : "",
          },
          shipToAddress,
          selectedProject: selectedProject
            ? getProjectName(selectedProject, projects)
            : "",
          userEmail,
          userName,
        });

        const tableUrl = await s3Upload(
          tableBlob,
          `PO-${poNumber}-Bmarko-${new Date().toISOString()}.pdf`
        );
        console.log(`Additional table ${i + 1} PDF uploaded URL:`, tableUrl);

        attachmentUrls.push(tableUrl);
        purchaseOrdersInfo.push({
          poNum: poNumber,
          reqDateFormatted:
            additionalTables[i].requiredDate.format("MM/DD/YYYY"),
        });

        // Save each additional table as a separate purchase order
        const additionalPurchaseOrder = {
          id: uuidv4(), // Generate a unique ID for each additional table
          buyer,
          created: dayjs().format("MM/DD/YYYY h:mm:ss A"),
          documents: [
            {
              dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
              documentUrl: tableUrl,
              number: i + 2, // Increment document number for each additional table
              uuid: uuidv4(),
            },
          ],
          factory: "Doraville",
          lines: additionalTables[i].poLines.map((line, index) => ({
            category: line.category,
            itemDescription: line.itemDescription,
            itemId: line.itemId,
            itemNumber: line.itemNumber,
            itemPrice: line.price,
            lineNumber: `${String(index + 1).padStart(2, "0")}`,
            lineStatus: "Open",
            manufacturer: line.manufacturer,
            manufacturerId: line.manufacturerId,
            orderBatchSize: line.orderBatchSize,
            qtyOrdered: line.qty,
            qtyReceived: 0, // Qty received will always be 0 initially
            uom: line.uom,
            vendor: selectedVendor,
          })),
          orderedDate: dayjs().format("MM/DD/YYYY"),
          other: additionalTables[i].other,
          poNotes: additionalTables[i].notes,
          poNumber,
          poStatus: "Open",
          projectNumber: selectedProject || "",
          requiredDate: additionalTables[i].requiredDate.format("MM/DD/YYYY"),
          shippingCosts: additionalTables[i].shipping,
          shipToAddress,
          tenantId: "bmarko",
          vendor: selectedVendor,
          emailAddresses: emails, // Include the email addresses
          groupNumber, // Assign the same group number to additional tables
        };

        await addItemsToTable(TABLES.PURCHASE_ORDERS, additionalPurchaseOrder);
        console.log(
          `Additional Purchase Order ${i + 1} saved:`,
          additionalPurchaseOrder
        );
      }

      // Final refresh of purchase orders after all processing
      await refreshPurchaseOrders();

      let emailBody;

      let originalDate = "";
      if (
        isEdit &&
        existingOrder.documents &&
        existingOrder.documents.length > 0
      ) {
        const lastDocument =
          existingOrder.documents[existingOrder.documents.length - 1];
        originalDate = dayjs(lastDocument.dateSubmitted).format("MM/DD/YYYY");
      }

      if (!isEdit) {
        emailBody =
          purchaseOrdersInfo.length > 1
            ? EMAILBODY.MULTIPLE_PURCHASE_ORDERS({
                purchaseOrders: purchaseOrdersInfo,
                vendorFirstName: vendorDetails?.vendorFirstName || "",
                capFirstName: userName.split(" ")[0],
                vendor: vendorDetails?.companyName || "",
              })
            : EMAILBODY.PURCHASE_ORDER({
                poNum: purchaseOrdersInfo[0].poNum,
                vendor: vendorDetails?.companyName || "",
                vendorFirstName: vendorDetails?.vendorFirstName || "",
                capFirstName: userName.split(" ")[0],
                reqDateFormatted: purchaseOrdersInfo[0].reqDateFormatted,
              });
      } else {
        emailBody = EMAILBODY.REVISED_PURCHASE_ORDER({
          vendor: vendorDetails?.companyName || "",
          vendorFirstName: vendorDetails?.vendorFirstName || "",
          capFirstName: userName.split(" ")[0],
          reqDateFormatted: purchaseOrdersInfo[0].reqDateFormatted,
          poNum: existingOrder.poNumber,
          originalDate: originalDate,
        });
      }

      await sendEmail({
        subject: emailBody.subject,
        htmlBody: emailBody.body,
        emailAddresses: emails,
        attachmentUrls,
        sender: userEmail,
      });

      if (!isEdit) {
        clearForm();
      }

      setLoading(false);
      message.success("Purchase order saved and email sent successfully!");
    } catch (error) {
      setLoading(false);
      console.error("Error generating or uploading PDFs:", error);
      message.error("Failed to save purchase order or send email.");
    }
  };

  const renderPONumber = (poNumber, groupPOs) => (
    <>
      <Card
        title={
          <>
            <span style={{ fontWeight: 400 }}>Purchase Order</span>{" "}
            <span>{poNumber}</span>
          </>
        }
        extra={<>{getVendorName(existingOrder.vendor, vendors)}</>}
        size="small"
        className="box-shadow"
        bodyStyle={{ padding: existingOrder.groupNumber ? 6 : 0 }}
      >
        {existingOrder.groupNumber && (
          <Tag color="blue">Group {existingOrder.groupNumber}</Tag>
        )}
        {groupPOs &&
          groupPOs.map((po) => (
            <a
              key={po.id}
              href={`/purchasing/purchase/edit-purchase-orders/${po.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "8px" }}
            >
              {po.poNumber}
            </a>
          ))}
      </Card>
    </>
  );




const customFilterOption = (input, option) => {
  if (!option) return false;

  // Retrieve custom data attributes
  const itemNumber = option.props["data-itemnumber"] || "";
  const itemPrice = option.props["data-itemprice"] || "";
  const itemDescription = option.props["data-itemdescription"] || "";
  const mfgId = option.props["data-mfgid"] || "";

  // Normalize the input by removing $, ,, and - (for number and price matching)
  const normalizedInput = input
    .replace(/[$,\-]/g, "")
    .trim()
    .toLowerCase();

  // Trim and lowercase input (for description and mfgId matching)
  const trimmedInput = input.trim().toLowerCase();

  // Normalize the fields for price and item number
  const normalizedPrice = parseFloat(itemPrice)
    .toFixed(2)
    .toString()
    .replace(/[$,]/g, ""); // Convert to fixed decimal and remove $, ,

  const normalizedItemNumber = itemNumber
    .toString()
    .toLowerCase()
    .replace(/[-]/g, ""); // Remove dashes and convert to lowercase

  // Trim and lowercase the item description and mfgId
  const trimmedItemDescription = itemDescription.trim().toLowerCase();
  const trimmedMfgId = mfgId.trim().toLowerCase();

  // Check if the normalized input is included in normalized price or item number
  const priceMatch = normalizedPrice.includes(normalizedInput);
  const itemNumberMatch = normalizedItemNumber.includes(normalizedInput);

  // Check if the trimmed input is included in item description or mfgId
  const descriptionMatch = trimmedItemDescription.includes(trimmedInput);
  const mfgIdMatch = trimmedMfgId.includes(trimmedInput);

  return priceMatch || itemNumberMatch || descriptionMatch || mfgIdMatch;
};


  return (
    <Form form={form}>
      {loading && (
        <div style={overlayStyle}>
          <Spin size="large" />
        </div>
      )}

      <Row
        gutter={[16, 8]}
        style={{ marginBottom: 16, display: "flex", alignItems: "flex-start" }}
        align="middle"
      >
        {isEdit && (
          <Col span={9}>
            <div>{renderPONumber(existingOrder.poNumber, groupPOs)}</div>
          </Col>
        )}

        {!isEdit && (
          <Col span={9}>
            <Form.Item label="Vendor" style={{ margin: 0 }}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Vendor"
                optionFilterProp="children"
                onChange={handleVendorChange}
                value={selectedVendor}
              >
                {sortedVendors.map((vendor) => (
                  <Option key={vendor.id} value={vendor.id}>
                    {getVendorName(vendor.id, vendors)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col span={6} align="center"></Col>
        <Col
          span={9}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            {isEdit && (
              <Button
                danger
                disabled={loading || hasReceivedItems()}
                onClick={handleDeletePurchaseOrder}
                icon={<DeleteOutlined />}
              >
                Cancel Purchase Order
              </Button>
            )}

            {!isEdit && (
              <>
                <Button
                  type="default"
                  icon={<CopyOutlined />}
                  onClick={handleOpenDuplicateModal}
                >
                  Duplicate Purchase Order
                </Button>

                <DuplicatePOModal
                  isVisible={isDuplicateModalVisible}
                  onClose={handleCloseDuplicateModal}
                  purchaseOrders={purchaseOrders}
                  onDuplicate={handleDuplicateItems}
                  vendors={vendors}
                />
              </>
            )}

            <Button
              type="primary"
              icon={<RocketOutlined />}
              style={{ marginLeft: 16 }}
              disabled={!isFormValid() || loading} // Disable the button if form is not valid
              onClick={handleSubmitPurchaseOrder}
              loading={loading}
            >
              Submit Purchase Order
            </Button>
          </div>
        </Col>
      </Row>

      {selectedVendor && (
        <Row gutter={[16, 8]} style={{ marginBottom: 8 }}>
          <Col span={12}>
            <Form.Item label="Email" style={{ margin: 0 }}>
              <div>
                {emails.length > 0 &&
                  emails.map((email) => (
                    <Tag
                      closable
                      onClose={() => handleRemoveEmail(email)}
                      key={email}
                      style={{ marginTop: 4 }}
                      color={COLORS.PRIMARY}
                    >
                      {email}
                    </Tag>
                  ))}
                <Popover
                  content={emailContent}
                  title="Add Email"
                  trigger="click"
                  open={popoverVisible}
                  onOpenChange={setPopoverVisible}
                >
                  <Button
                    type="dashed"
                    disabled={loading}
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 8 }}
                  />
                </Popover>
              </div>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Contact Name" style={{ margin: 0 }}>
              <Input
                value={getVendorDetails(selectedVendor).contactName || ""}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Phone Number" style={{ margin: 0 }}>
              <PhoneInput
                country={"us"}
                value={getVendorDetails(selectedVendor).phoneNumber || ""}
                containerClass="displayOnlyPhone"
                inputClass="displayOnlyPhone"
                dropdownClass="displayOnlyPhone"
                disableDropdown={true}
                disabled
                containerStyle={{ width: "100%" }}
                inputStyle={{
                  width: "100%",
                  border: "none",
                  background: "none",
                  cursor: "default",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={[16, 8]} style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Form.Item label="Ship To Address" style={{ margin: 0 }}>
            <AddressAutocomplete
              form={form}
              value={shipToAddress}
              initialValue={shipToAddress}
              onChange={setShipToAddress}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Project" style={{ margin: 0 }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Project"
              optionFilterProp="children"
              onChange={handleProjectChange}
              value={selectedProject}
            >
              {projects
                .slice() // Create a copy of the array to avoid mutating the original
                .sort((a, b) =>
                  getProjectName(a.id, projects).localeCompare(
                    getProjectName(b.id, projects)
                  )
                ) // Alphabetize using getProjectName
                .map((project) => (
                  <Option key={project.id} value={project.id}>
                    {getProjectName(project.id, projects)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Required Date" style={{ margin: 0 }}>
            <DatePicker
              style={{ width: "100%" }}
              value={requiredDate ? dayjs(requiredDate) : null}
              onChange={handleDateChange}
            />
          </Form.Item>
        </Col>
      </Row>

      {selectedVendor && (
        <>
          <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
            <Col span={24}>
              <Form.Item label="Add Item" style={{ margin: 0 }}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Item to Add"
                  optionFilterProp="children"
                  onChange={(value) => handleItemSelect(value, setPoLines)}
                  value={null}
                  filterOption={customFilterOption} // Add the custom filter function here
                >
                  {itemsData
                    .filter((item) =>
                      item.vendors.some(
                        (vendor) => vendor.id === selectedVendor
                      )
                    )
                    .filter(
                      (item) =>
                        !poLines.some((line) =>
                          item.vendors.some(
                            (vendor) =>
                              vendor.id === selectedVendor &&
                              line.itemId === vendor.itemId
                          )
                        )
                    )
                    .map((item) => {
                      const vendor = item.vendors.find(
                        (vendor) => vendor.id === selectedVendor
                      );
                      const vendorItemId = vendor?.itemId;
                      const itemPrice = vendor?.price;
                      const itemNumber = item?.itemNumber || "";
                      const mfgId = item?.manufacturerId || "";

                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          data-itemnumber={itemNumber}
                          data-itemprice={itemPrice}
                          data-itemdescription={item.itemDescription}
                          data-mfgid={mfgId}
                        >
                          <Tag>{item.itemNumber}</Tag>
                          <Tag color="green">{formatMoney(itemPrice)}</Tag>
                          <Tag color="blue">{item.manufacturerId}</Tag>
                          <span style={{ fontSize: 12 }}>
                            {item.itemDescription}
                          </span>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {poLines.length > 0 && (
            <>
              <Table
                dataSource={poLines}
                columns={columns(setPoLines)}
                pagination={false}
                size="small"
                rowKey="key"
                className="small-table"
              />

              <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={4} />
                <Col span={4}>
                  <Form.Item label="Shipping">
                    <InputNumber
                      min={0}
                      value={shipping}
                      onChange={(value) => setShipping(value)}
                      type="number"
                      prefix="$"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Other">
                    <InputNumber
                      min={0}
                      value={other}
                      onChange={(value) => setOther(value)}
                      type="number"
                      prefix="$"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Discount">
                    <InputNumber
                      min={0}
                      value={discount}
                      onChange={(value) => setDiscount(value)}
                      type="number"
                      className="red-input"
                      prefix="- $"
                      style={{ width: "100%", color: "#f10000" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Subtotal" style={{ display: "none" }}>
                    {/* Original InputNumber is hidden */}
                    <InputNumber
                      disabled
                      value={subtotal.toFixed(2)}
                      type="number"
                      prefix="$"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  {/* Add the Subtotal as a Tag outside the hidden Form.Item */}
                  <Form.Item label="Subtotal" style={{ marginBottom: 0 }}>
                    <Tag
                      color="green"
                      style={{
                        fontSize: "16px",
                        width: "100%",
                        padding: 6,
                        textAlign: "center",
                        opacity: 0.9,
                      }}
                    >
                      {formatMoney(subtotal)}
                    </Tag>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Total" style={{ fontWeight: 600 }}>
                    <Tag
                      color="green"
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        padding: 6,
                        width: "100%",
                      }}
                    >
                      <strong>{formatMoney(totalPO)}</strong>
                    </Tag>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={24}>
                  <Form.Item label="Notes">
                    <Input.TextArea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {hasItemsWithQty(poLines) && !isEdit && (
                <Button
                  type="primary"
                  style={{ width: "100%", marginBottom: 16 }}
                  onClick={handleAddAdditionalRequiredDate}
                  disabled={loading}
                >
                  Add Additional Required Date
                </Button>
              )}
            </>
          )}
        </>
      )}

      {additionalTables.map((table, index) => (
        <div key={index}>
          <Divider />
          <Row
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            <Col span={18} style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: 18, fontWeight: 600 }}>
                Additional Required Date {index + 1}
              </div>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveAdditionalTable(index)}
                color="red"
                style={{ marginLeft: 16 }}
                danger
              >
                Remove
              </Button>
            </Col>
            <Col span={6}>
              <Form.Item label="Required Date" style={{ margin: 0 }}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={table.requiredDate ? dayjs(table.requiredDate) : null}
                  onChange={(date) => handleAdditionalDateChange(index, date)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]} style={{ marginBottom: 8 }}></Row>
          <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
            <Col span={24}>
              <Form.Item label="Add Item" style={{ margin: 0 }}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Item to Add"
                  optionFilterProp="children"
                  onChange={(value) =>
                    handleItemSelect(
                      value,
                      (newPoLines) =>
                        setAdditionalTables((prev) =>
                          prev.map((t, i) =>
                            i === index
                              ? {
                                  ...t,
                                  poLines: Array.isArray(newPoLines)
                                    ? newPoLines
                                    : [],
                                }
                              : t
                          )
                        ),
                      true,
                      index
                    )
                  }
                  value={null}
                  filterOption={customFilterOption} // Add the custom filter function here
                >
                  {itemsData
                    .filter((item) =>
                      item.vendors.some(
                        (vendor) => vendor.id === selectedVendor
                      )
                    )
                    .filter(
                      (item) =>
                        !Array.isArray(table.poLines) ||
                        !table.poLines.some((line) =>
                          item.vendors.some(
                            (vendor) =>
                              vendor.id === selectedVendor &&
                              line.itemId === vendor.itemId
                          )
                        )
                    )
                    .map((item) => {
                      const vendor = item.vendors.find(
                        (vendor) => vendor.id === selectedVendor
                      );
                      const vendorItemId = vendor?.itemId;
                      const itemPrice = vendor?.price;
                      const itemNumber = item?.itemNumber || "";
                      const mfgId = item?.manufacturerId || "";

                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          data-itemnumber={itemNumber}
                          data-itemprice={itemPrice}
                          data-itemdescription={item.itemDescription}
                          data-mfgid={mfgId}
                        >
                          <Tag>{item.itemNumber}</Tag>
                          <Tag color="green">{formatMoney(itemPrice)}</Tag>
                          <Tag color="blue">{mfgId}</Tag>
                          {item.itemDescription}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <AdditionalTable
            tableData={table}
            setTableData={(newData) => {
              const newTables = [...additionalTables];
              newTables[index] = newData;
              setAdditionalTables(newTables);
            }}
            index={index}
          />
        </div>
      ))}
    </Form>
  );
}

export default AddPurchaseOrder;
