import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/drive";

// Function to list files
export const listFiles = async (folderId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/listFiles`, {
      params: { folderId },
    });
    return response.data;
  } catch (error) {
    console.error("Error listing files:", error);
    throw new Error("Failed to list files");
  }
};

// Function to upload a file
export const uploadFile = async (file, folderId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folderId", folderId);

    const response = await axios.post(`${API_BASE_URL}/uploadFile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload file");
  }
};

// Function to create a folder
export const createFolder = async (folderName) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/createFolder`, {
      folderName,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating folder:", error);
    throw new Error("Failed to create folder");
  }
};
