import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  InputNumber,
  Upload,
  message,
  Row,
  Col,
  Switch,
  Table,
  Tag
} from "antd";
import {
  ArrowLeftOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { usePurchaseOrders, useVendors, useProjects } from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { updateById } from "../../Functions/updateById";
import { s3Upload } from "../../Functions/s3Upload";
import { TABLES, SLACK_CHANNELS, COLORS } from "../../constants";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import { Loader } from "../../Styled/Loader";
import dayjs from "dayjs";
import { useUser } from "@clerk/clerk-react";
import { getProjectName } from "../../Formatters/getProjectName";

const ReceiveItemDetailPurchase = () => {
  const { id, itemNumber } = useParams();
  const navigate = useNavigate();
  const { data: purchaseOrders, refresh: refreshPurchaseOrders } =
    usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const [loading, setLoading] = useState(false);

  const [selectedPO, setSelectedPO] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [qtyReceived, setQtyReceived] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [receivedOffSite, setReceivedOffSite] = useState(false);

const { user } = useUser();

const userEmail = user?.primaryEmailAddress?.emailAddress;

  const slackChannel =
    process.env.REACT_APP_STAGE === "_dev"
      ? SLACK_CHANNELS.RECEIVING_DEV
      : SLACK_CHANNELS.RECEIVING_PROD;

  useEffect(() => {
    if (purchaseOrders) {
      const foundPO = purchaseOrders.find((po) => po.id === id);
      setSelectedPO(foundPO);
      console.log("Selected PO:", foundPO);
      if (foundPO) {
        const foundItem = foundPO.lines.find((line) => line.itemNumber === itemNumber);
        setSelectedItem(foundItem);
      }
    }
  }, [id, itemNumber, purchaseOrders]);

  const handleBack = () => {
    navigate(`/receiving/receive/purchase-orders/${id}`);
  };

const handleMaxQty = () => {
  if (selectedItem) {
    setQtyReceived(Number(selectedItem.qtyOrdered) - Number(selectedItem.qtyReceived)); // Safeguard qtyReceived as a number
  }
};

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  if (!selectedPO || !selectedItem || loading) {
    return <Loader />;
  }

  const qtyRemaining = selectedItem.qtyOrdered - selectedItem.qtyReceived;

  if (qtyRemaining <= 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <h2>This line item has been fully received.</h2>
        <Button type="primary" onClick={handleBack}>
          Back to Purchase Orders
        </Button>
      </div>
    );
  }

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

const handleSubmit = async () => {
  setLoading(true);
  if (Number(qtyReceived) <= 0 || (!receivedOffSite && fileList.length === 0)) {
    message.error(
      "Please enter a valid quantity and upload at least one photo, or mark as received off site."
    );
    setLoading(false); // Stop loading if validation fails
    return;
  }

  try {
    let uploadedUrls = [];

    uploadedUrls = await Promise.all(
      fileList.map((file, index) => {
        const fileName = `${selectedPO.poNumber}_${
          selectedItem.itemNumber
        }_image_${index + 1}_${new Date().toISOString()}`;
        return s3Upload(file.originFileObj, fileName);
      })
    );

    const updatedItem = {
      ...selectedItem,
      qtyReceived: Number(selectedItem.qtyReceived) + Number(qtyReceived), // Safeguard qtyReceived as a number
      images: [...(selectedItem.images || []), ...uploadedUrls],
      receivedDate: new Date().toISOString(),
      receivedBy: userEmail,
    };

    const updatedLines = selectedPO.lines.map((line) =>
      line.itemNumber === selectedItem.itemNumber ? updatedItem : line
    );

    const updatedPO = {
      ...selectedPO,
      lines: updatedLines,
    };

    await updateById(TABLES.PURCHASE_ORDERS, updatedPO, selectedPO.id);

    const slackMessage = {
      channel: slackChannel,
      text: `${
        receivedOffSite ? "[OFFSITE] " : ""
      }*New Items Received :truck: :arrow_right: :house: ${
        selectedPO.poNumber
      }*`,
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: `${
              receivedOffSite ? "[OFFSITE] " : ""
            }New Items Received :truck: :arrow_right: :house: ${
              selectedPO.poNumber
            }`,
            emoji: true,
          },
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Vendor:*\n${getVendorName(selectedPO.vendor, vendors)}`,
            },
            {
              type: "mrkdwn",
              text: `*Category:*\n${selectedItem.category}`,
            },
          ],
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Item:*\n${selectedItem.itemDescription}`,
          },
        },
        {
          type: "section",
          fields: [
            {
              type: "mrkdwn",
              text: `*Quantity Received:*\n${qtyReceived}`,
            },
            {
              type: "mrkdwn",
              text: `*Project:*\n${getProjectName(selectedPO.projectNumber, projects)}`,
            },
          ],
        },
        ...uploadedUrls.map((url, index) => ({
          type: "image",
          image_url: url,
          alt_text: `Image ${index + 1}`,
          title: {
            type: "plain_text",
            text: `Image ${index + 1}: ${
              selectedItem.itemNumber
            } - ${dayjs().format("MM-DD-YYYY")} - ${selectedPO.poNumber}`,
          },
        })),
      ],
    };

    await sendSlackMessage(slackMessage);

    // Ensure that the "all lines received" logic works
    const allLinesReceived = updatedLines.every(
      (line) => Number(line.qtyOrdered) === Number(line.qtyReceived) // Safeguard qtyReceived and qtyOrdered as numbers
    );

    if (allLinesReceived) {
      await sendSlackMessage({
        channel: slackChannel,
        text: `:ballot_box_with_check: PO #${selectedPO.poNumber} has been completed! :ballot_box_with_check:`,
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: `:ballot_box_with_check: PO #${selectedPO.poNumber} has been completed! :ballot_box_with_check:`,
              emoji: true,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Vendor:*\n${getVendorName(selectedPO.vendor, vendors)}`,
              },
              {
                type: "mrkdwn",
                text: `*Number of Items:*\n${selectedPO.lines.length}`,
              },
            ],
          },
        ],
      });
    }

    await refreshPurchaseOrders();
    setLoading(false);
    message.success("Item received successfully.");

    if (allLinesReceived) {
      navigate(`/receiving/receive/purchase-orders/`);
    } else {
      navigate(`/receiving/receive/purchase-orders/${id}`);
    }
  } catch (error) {
    setLoading(false);
    console.error("Error receiving item:", error);
    message.error("Failed to receive item. Please try again.");
  }
};

  const isSubmitDisabled =
    qtyReceived <= 0 ||
    (!receivedOffSite && fileList.length === 0 && qtyReceived > 0);

  return (
    <>
      <Row justify="center" style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              navigate(`/receiving/receive/purchase-orders/${selectedPO.id}`)
            }
          >
            Back
          </Button>
        </Col>
        <Col>
          <div style={{ fontWeight: 600, fontSize: 18, textAlign: "center" }}>
            {selectedPO.poNumber}
          </div>
          <div style={{ textAlign: "center" }}>
            {getVendorName(selectedPO.vendor, vendors)}
          </div>
        </Col>
      </Row>
      <Row style={{}}>
        <Col span={24} textAlign="center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Switch
              checked={receivedOffSite}
              onChange={(checked) => setReceivedOffSite(checked)}
            />
            <span style={{ marginLeft: 8 }}>Received Off Site</span>
          </div>
        </Col>
        {receivedOffSite && (
          <Col span={24} textAlign="center">
            <div
              style={{
                textAlign: "center",
                marginTop: 8,
                fontStyle: "italic",
                opacity: 0.7,
              }}
            >
              Items received off site will not require photos.
            </div>
          </Col>
        )}
      </Row>
      <Card
        title={`Line ${selectedItem.lineNumber}`}
        extra={
          <>
            <Tag style={{ fontSize: 14, fontWeight: 600 }} color="blue">
              Qty Remaining: {qtyRemaining}
            </Tag>
          </>
        }
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 8 }}
      >
        <Table
          bordered
          showHeader={false}
          pagination={false}
          size="small"
          dataSource={[
            { key: "1", label: "Item #", value: selectedItem.itemNumber },
            { key: "2", label: "Category", value: selectedItem.category },
            {
              key: "3",
              label: "Item",
              value: selectedItem.itemDescription,
            },
            { key: "4", label: "UOM", value: selectedItem.uom },
            {
              key: "5",
              label: "Qty Ordered",
              value: selectedItem.qtyOrdered,
            },
            {
              key: "6",
              label: "Qty Received",
              value: selectedItem.qtyReceived,
            },
          ]}
          columns={[
            { dataIndex: "label", key: "label", width: 120 },
            { dataIndex: "value", key: "value" },
          ]}
        />

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <InputNumber
              min={0}
              max={qtyRemaining}
              value={qtyReceived}
              type="number"
              onChange={setQtyReceived}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <Button
              onClick={handleMaxQty}
              type="primary"
              style={{ width: "100%" }}
            >
              Receive Max Qty
            </Button>
          </Col>
        </Row>

        {qtyReceived > 0 && (
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              <Upload
                className="custom-upload"
                listType="picture-card"
                fileList={fileList}
                onChange={handleUploadChange}
                onRemove={handleRemove}
                beforeUpload={() => false}
                accept="image/*,text/plain"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                  removeIcon: <CloseOutlined style={{ color: "red" }} />,
                }}
              >
                {fileList.length < 5 && "+ Upload"}
              </Upload>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginTop: 16, width: "100%" }}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ReceiveItemDetailPurchase;
