import React, { useEffect, useState } from "react";
import { Row, Col, Table, Tag } from "antd";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
  useTransactions,
} from "../../Contexts/useSpecificData";
import { Loader } from "../../Styled/Loader";
import { SearchInput } from "../../Styled/SearchInput";
import HeaderText from "../../Styled/HeaderText";
import { formatMoney } from "../../Formatters/helpers";

const Transactions = () => {
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const { data: transactions, loading: transactionsLoading } =
    useTransactions();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    document.title = "Transactions - Purchasing"; // Set the tab title

    // If you're using fetched transactions, uncomment the following line:
    // setDataSource(transactions);

    // Using sample data directly
    setDataSource(transactions);
  }, [transactions]);

  if (poLoading || transactionsLoading) {
    return <Loader />;
  }

  // Define the columns for the table
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (date) => new Date(date).toLocaleDateString("en-US"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Merchant Name",
      dataIndex: "merchant_name",
      key: "merchant_name",
      sorter: (a, b) => a.merchant_name.localeCompare(b.merchant_name),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (categories) => (
        <>
          {categories.map((cat) => (
            <Tag color="blue" key={cat}>
              {cat}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (amount) => <Tag color="green">{formatMoney(amount)}</Tag>,
    },
    // {
    //   title: "Transaction ID",
    //   dataIndex: "transaction_id",
    //   key: "transaction_id",
    //   ellipsis: true,
    // },
    // {
    //   title: "Account ID",
    //   dataIndex: "account_id",
    //   key: "account_id",
    //   ellipsis: true,
    // },
  ];

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <HeaderText text={"Transactions"} />
        </Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SearchInput placeholder="Search Transactions" />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Transactions;
