import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Button, Select, Switch, Row, Col } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  WALL_R_VALUES,
  FLOOR_R_VALUES,
  CEILING_R_VALUES,
  WALL_FINISH_VALUES,
  TABLES,
} from "../../../constants";
import { useItems } from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { getPreferredPrice } from "../../../Formatters/getPreferredPrice";

const { Option } = Select;

const AddModuleModal = ({
  children,
  module,
  onAddSuccess,
  title,
  estimate,
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    data: items,
    loading: itemsLoading,
    error: itemsError,
    refresh: refreshItems,
  } = useItems();
  const shippingContainers =
    items?.filter((item) => item.category === "SHIPPING CONTAINER") || [];

  useEffect(() => {
    if (module) {
      form.setFieldsValue({
        moduleName: module.moduleName,
        description: module.description,
        laborCost: module.laborCost,
        containerId: module.container?.id,
        containerQuantity: module.container?.quantity,
        structureWidth: module.structureWidth,
        structureLength: module.structureLength,
        structureHeight: module.structureHeight,
        partitionWalls: module.partitionWalls,
        wallRValue: module.wallRValue,
        floorRValue: module.floorRValue,
        ceilingRValue: module.ceilingRValue,
        rainProtection: module.rainProtection,
        permitted: module.permitted,
        longWallRemoved: module.longWallRemoved,
        endWallRemoved: module.endWallRemoved,
        wallFinish: module.wallFinish,
      });
    } else {
      form.resetFields();
    }
  }, [module, form]);

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      values.moduleId = module ? module.moduleId : uuidv4(); // Add module ID

      const selectedContainer = shippingContainers.find(
        (item) => item.id === values.containerId
      );

      const containerPrice = getPreferredPrice(selectedContainer);

      values.container = {
        ...selectedContainer,
        quantity: values.containerQuantity,
        price: containerPrice,
      };
      delete values.containerId;
      delete values.containerQuantity;

      console.log("Submitted Values:", values);

      const modules = estimate.modules || [];

      if (module) {
        // Update existing module
        const moduleIndex = modules.findIndex(
          (mod) => mod.moduleId === module.moduleId
        );
        if (moduleIndex >= 0) {
          modules[moduleIndex] = values;
        }
      } else {
        // Add new module
        modules.push(values);
      }

      await updateById(TABLES.ESTIMATES, { modules }, estimate.id);

      setVisible(false);
      setLoading(false);
      onAddSuccess(values);
    } catch (error) {
      console.error("Validation Failed:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={title || (module ? "Edit Module" : "Add New Module")}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        confirmLoading={loading}
        style={{ minWidth: "75%" }}
        footer={[
          <Button
            key="back"
            onClick={() => setVisible(false)}
            disabled={loading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {module ? "Save Changes" : "Add Module"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={14}>
              <Form.Item
                name="moduleName"
                label="Module Name"
                rules={[
                  { required: true, message: "Please enter the module name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="rainProtection"
                    label="Rain Protection"
                    valuePropName="checked"
                    initialValue={false}
                    labelAlign="left"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="permitted"
                    label="Permitted"
                    valuePropName="checked"
                    initialValue={false}
                    labelAlign="left"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={6}></Col>
          </Row>

          <Row gutter={16}>
            <Col span={14}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please enter the description" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="longWallRemoved"
                    label="Long Wall Removed"
                    valuePropName="checked"
                    initialValue={false}
                    labelAlign="left"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="endWallRemoved"
                    label="End Wall Removed"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={[16, 0]}
            style={{
              border: "1px solid #d4d4d4",
              padding: 16,
              marginBottom: 16,
              borderRadius: 8,
            }}
          >
            <Col span={16}>
              <Form.Item
                name="containerId"
                label="Container"
                rules={[{ required: true, message: "Missing container" }]}
              >
                <Select
                  placeholder="Select a container"
                  dropdownStyle={{ minWidth: 300 }}
                  style={{ height: 80 }}
                >
                  {shippingContainers.map((item) => (
                    <Option key={item.id} value={item.id}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {item.itemDescription}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontSize: "smaller",
                            fontStyle: "italic",
                            opacity: 0.8,
                          }}
                        >
                          Item #: {item.itemNumber} | Vendor: {item.vendor}
                        </span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                name="containerQuantity"
                label="No of Containers"
                rules={[{ required: true, message: "Missing quantity" }]}
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="structureWidth"
                label={
                  <>
                    <span>Structure Width</span>
                    <span className="opacity" style={{ marginLeft: 4 }}>
                      (Ft)
                    </span>
                  </>
                }
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="structureLength"
                label={
                  <>
                    <span>Structure Length</span>
                    <span className="opacity" style={{ marginLeft: 4 }}>
                      (Ft)
                    </span>
                  </>
                }
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="structureHeight"
                label={
                  <>
                    <span>Structure Height</span>
                    <span className="opacity" style={{ marginLeft: 4 }}>
                      (Ft)
                    </span>
                  </>
                }
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="partitionWalls"
                label="Number of Partition Walls"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="wallRValue"
                label="Wall R-Value"
                rules={[
                  { required: true, message: "Please select a wall R-value!" },
                ]}
              >
                <Select placeholder="Select R Value">
                  {WALL_R_VALUES.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="wallFinish"
                label="Wall Finish"
                rules={[
                  { required: true, message: "Please select a wall finish!" },
                ]}
              >
                <Select placeholder="Select Wall Finish">
                  {WALL_FINISH_VALUES.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="floorRValue"
                label="Floor R-Value"
                rules={[
                  { required: true, message: "Please select a floor R-value!" },
                ]}
              >
                <Select placeholder="Select Floor R Value">
                  {FLOOR_R_VALUES.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="ceilingRValue"
                label="Ceiling R-Value"
                rules={[
                  {
                    required: true,
                    message: "Please select a ceiling R-value!",
                  },
                ]}
              >
                <Select placeholder="Select Ceiling R Value">
                  {CEILING_R_VALUES.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddModuleModal;
