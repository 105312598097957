import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Card,
  message,
  InputNumber,
  Table,
  Modal,
  Badge,
  Tag,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAssemblies, useEstimates } from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { formatMoney } from "../../../Formatters/helpers";
import { COLORS, TABLES } from "../../../constants";
import { getAssemblyCost } from "../../../Formatters/getAssemblyCost";

const RFQ = ({ estimate }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rfq, setRfq] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [quantities, setQuantities] = useState({});

  const { data: assemblies, loading: assembliesLoading } = useAssemblies();
  const { refresh: refetchEstimates } = useEstimates();

  useEffect(() => {
    if (estimate.rfq) {
      setRfq(estimate.rfq);
    }
  }, [estimate.rfq]);

  const availableAssemblies = assemblies.filter(
    (assembly) => !rfq.find((item) => item.assemblyId === assembly.id)
  );

  const categories = [
    ...new Set(assemblies.map((assembly) => assembly.category)),
  ];

  const handleAdd = (assemblyId) => {
    const assembly = assemblies.find((item) => item.id === assemblyId);
    const newAssembly = {
      ...assembly,
      quantity: quantities[assemblyId],
      assemblyId: assembly.id,
    };
    const updatedRfq = [...rfq, newAssembly];
    setRfq(updatedRfq);
    message.success(`Added ${assembly.assemblyName}`);
    handleSave(updatedRfq, false);
    setQuantities({ ...quantities, [assemblyId]: null });
  };

  const handleQuantityChange = (value, assemblyId) => {
    const updatedRfq = rfq.map((item) =>
      item.assemblyId === assemblyId ? { ...item, quantity: value } : item
    );
    setRfq(updatedRfq);
    handleSave(updatedRfq, false);
  };

  const handleDelete = (assemblyId) => {
    const assembly = rfq.find((item) => item.assemblyId === assemblyId);
    Modal.confirm({
      title: `Are you sure you want to delete ${assembly.assemblyName}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        const updatedRfq = rfq.filter((item) => item.assemblyId !== assemblyId);
        setRfq(updatedRfq);
        handleSave(updatedRfq);
        message.success(`Removed ${assembly.assemblyName}`);
      },
    });
  };

  const handleSave = async (updatedRfq = rfq, showMessage = true) => {
    setLoading(true);
    try {
      await updateById(TABLES.ESTIMATES, { rfq: updatedRfq }, estimate.id);
      setLoading(false);
      if (showMessage) {
        message.success("RFQ saved successfully!");
      }
      refetchEstimates(); // Refresh the estimates data
    } catch (error) {
      setLoading(false);
      message.error("Failed to save RFQ: " + error.message);
    }
  };

  const filteredAssemblies = availableAssemblies.filter((assembly) => {
    const matchesCategory =
      !searchTerm ||
      assembly.category.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSearchTerm =
      !searchTerm ||
      assembly.assemblyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      assembly.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearchTerm;
  });

  const columns = [
    {
      title: "Assembly Name",
      dataIndex: "assemblyName",
      key: "assemblyName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: categories.map((category) => ({
        text: category,
        value: category,
      })),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Price",
      dataIndex: "laborCost",
      key: "laborCost",
      render: (text, record) => formatMoney(getAssemblyCost(record)),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={quantities[record.id]}
          onChange={(value) =>
            setQuantities({ ...quantities, [record.id]: value })
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleAdd(record.id)}
          disabled={!quantities[record.id]}
        >
          Add
        </Button>
      ),
    },
  ];

  const totalCost = rfq.reduce(
    (total, item) => total + getAssemblyCost(item) * item.quantity,
    0
  );

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 18, marginRight: 12, fontWeight: 500 }}>
              Total Cost:
            </span>
            <Tag
              style={{ fontSize: 18, padding: 4, fontWeight: 600 }}
              color={"green"}
            >
              {formatMoney(totalCost)}
            </Tag>
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
          >
            Add Assembly
          </Button>
        </Col>
      </Row>

      {categories.map(
        (category) =>
          rfq.some((item) => item.category === category) && (
            <Row gutter={[16, 16]} style={{ marginTop: 16 }} key={category}>
              <Col span={24}>
                <Card
                  size="small"
                  bodyStyle={{
                    backgroundColor: "#E3ECF8",
                  }}
                  style={{ boxShadow: "0 0 5px 1px #E3ECF8" }}
                >
                  <div
                    className="box-title"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 16,
                      fontWeight: 600,
                      marginBottom: 12,
                    }}
                  >
                    <Badge
                      color={COLORS.PRIMARY}
                      count={
                        rfq.filter((item) => item.category === category).length
                      }
                    />
                    <span style={{ marginLeft: 8 }}>{category}</span>
                    <div style={{ marginLeft: "auto", fontWeight: 600 }}>
                      {formatMoney(
                        rfq
                          .filter((item) => item.category === category)
                          .reduce(
                            (total, item) =>
                              total + getAssemblyCost(item) * item.quantity,
                            0
                          )
                      )}
                    </div>
                  </div>
                  <Row gutter={[16, 16]}>
                    {rfq
                      .filter((item) => item.category === category)
                      .map((assembly) => (
                        <Col span={8} key={assembly.assemblyId}>
                          <Card
                            className="box-shadow"
                            size="small"
                            title={assembly.assemblyName}
                            bodyStyle={{ padding: 0 }}
                            extra={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tag color={"green"}>
                                  {formatMoney(
                                    getAssemblyCost(assembly) *
                                      assembly.quantity
                                  )}
                                </Tag>
                              </div>
                            }
                            actions={[
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{ marginRight: 8, marginLeft: 16 }}
                                >
                                  Qty:
                                </span>
                                <InputNumber
                                  min={1}
                                  value={assembly.quantity}
                                  onChange={(value) =>
                                    handleQuantityChange(
                                      value,
                                      assembly.assemblyId
                                    )
                                  }
                                />
                              </div>,
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  handleDelete(assembly.assemblyId)
                                }
                              />,
                            ]}
                          ></Card>
                        </Col>
                      ))}
                  </Row>
                </Card>
              </Col>
            </Row>
          )
      )}

      <Modal
        title="Select Assembly"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="100%"
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
          <Col span={24}>
            <Input
              placeholder="Search Assemblies"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>

        <Table
          columns={columns}
          size="small"
          dataSource={filteredAssemblies}
          rowKey="id"
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          loading={assembliesLoading}
        />
      </Modal>
    </div>
  );
};

export default RFQ;
