import React, { useEffect, useState, useRef } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Select,
  InputNumber,
  Spin,
  message,
} from "antd";
import { useUser } from "@clerk/clerk-react";
import { formatDate, formatMoney } from "../../../Formatters/helpers";
import { bmarkoFull } from "../../../base64/FullLogo";
import { bmarkoInitials } from "../../../base64/Initials";
import { DownloadOutlined } from "@ant-design/icons";
import { createQuote } from "../../../Functions/createQuote";
import { updateById } from "../../../Functions/updateById";
import { TABLES } from "../../../constants";
import { useEstimates } from "../../../Contexts/useSpecificData";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Regular.ttf",
    bold: "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-SemiBold.ttf",
    italics:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Black.ttf",
    bolditalics:
      "https://images-for-aria.s3.us-east-2.amazonaws.com/Poppins+(1)/Poppins-Black.ttf",
  },
};

const QuotePDF = ({ estimate }) => {
  const [leadTime, setLeadTime] = useState("10-12 Weeks");
  const [quoteValidity, setQuoteValidity] = useState();
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState(0);
  const [taxType, setTaxType] = useState("%");
  const [transport, setTransport] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const pdfViewerRef = useRef(null);

  const {
    data: estimates,
    loading: estimatesLoading,
    error: estimatesError,
    refresh: refreshEstimates,
  } = useEstimates();

  const { user } = useUser();
  const fullName = user?.fullName || "Unknown User";

  useEffect(() => {
    const isDisabled = !leadTime || !quoteValidity;
    setIsButtonDisabled(isDisabled);
  }, [leadTime, quoteValidity]);

  const createPDF = () => {
    const currentDate = formatDate(new Date());
    const quoteValidityDate = formatDate(quoteValidity);

    // Prepare Module Items
    const moduleItems = (estimate.modules || []).map((module) => ({
      description: `${module.container.quantity} x ${module.container.itemDescription}`,
      amount: module.container.price * module.container.quantity,
    }));

    // Prepare Assembly Items
    const assemblyItems = (estimate.rfq || []).map((rfq) => ({
      description: `${rfq.quantity} x ${rfq.assemblyName}`,
      amount:
        rfq.laborCost * rfq.quantity +
        rfq.assemblyItems.reduce(
          (total, item) => total + item.total * rfq.quantity,
          0
        ),
    }));

    // Prepare Custom Items
    const customItems = (estimate.customItems || []).map((item) => ({
      description: `${item.quantity} x ${item.itemDescription}`,
      amount: item.price * item.quantity,
    }));

    // Calculate Totals
    const totalModuleAmount = moduleItems.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    const totalAssemblyAmount = assemblyItems.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    const totalCustomAmount = customItems.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    const totalAmount =
      totalModuleAmount + totalAssemblyAmount + totalCustomAmount;

    // Calculate Tax
    let taxDisplay;
    let taxAmount = 0;
    if (taxType === "%") {
      taxAmount = ((totalAmount + transport) * tax) / 100;
      taxDisplay = formatMoney(taxAmount);
    } else if (taxType === "$") {
      taxAmount = tax;
      taxDisplay = formatMoney(taxAmount);
    } else {
      // "TBD"
      taxAmount = 0; // Treat tax as zero for calculations
      taxDisplay = "TBD";
    }

    // Grand Total is always calculated
    const grandTotalAmount = totalAmount + transport + taxAmount;
    const grandTotal = formatMoney(grandTotalAmount);

    // Pre-Tax Total is always displayed
    const preTaxTotalAmount = totalAmount + transport;
    const preTaxTotal = formatMoney(preTaxTotalAmount);

    const customer = estimate?.customer?.name || "";

    // Module Items Rows
    const moduleItemRows = moduleItems.map((item) => [
      { text: item.description, alignment: "left" },
      { text: formatMoney(item.amount), alignment: "right" },
    ]);

    // Assembly Items Rows
    const assemblyItemRows = assemblyItems.map((item) => [
      { text: item.description, alignment: "left" },
      { text: formatMoney(item.amount), alignment: "right" },
    ]);

    // Custom Items Rows with merged 'AMOUNT' column
    const customItemRows = customItems.map((item, index) => [
      { text: item.description, alignment: "left" },
      index === 0
        ? {
            text: formatMoney(totalCustomAmount),
            rowSpan: customItems.length,
            alignment: "center",
            verticalAlignment: "middle",
            margin: [0, 0, 0, 0],
          }
        : {
            text: "",
            border: [false, false, false, false], // Remove borders for empty cells
          },
    ]);

    const tableBody = [
      [
        {
          text: "DESCRIPTION",
          style: "tableHeader",
          alignment: "center",
        },
        { text: "AMOUNT", style: "tableHeader", alignment: "center" },
      ],
      ...moduleItemRows,
      ...assemblyItemRows,
      ...customItemRows,
    ];

    const dd = {
      background: (currentPage, pageSize) => {
        return [
          {
            image: bmarkoInitials,
            width: 150,
            alignment: "center",
            absolutePosition: {
              x: 0,
              y: (pageSize.height - 150) / 2,
            },
            opacity: 0.1,
          },
        ];
      },
      content: [
        {
          image: bmarkoFull,
          width: 200,
          alignment: "center",
          margin: [0, 0, 0, 20],
        },
        {
          columns: [
            { text: "Quote", style: "header", width: "50%" },
            [
              { text: "", width: "*" },
              {
                columns: [
                  { text: "", width: "*" },
                  {
                    table: {
                      body: [
                        [
                          {
                            text: "DATE:",
                            style: "subheader",
                            alignment: "right",
                          },
                          { text: currentDate, style: "subheader" },
                        ],
                        [
                          {
                            text: "QUOTE VALIDITY:",
                            style: "subheader",
                            alignment: "right",
                          },
                          { text: quoteValidityDate, style: "subheader" },
                        ],
                        [
                          {
                            text: "PREPARED BY:",
                            style: "subheader",
                            alignment: "right",
                          },
                          { text: fullName, style: "subheader" },
                        ],
                        [
                          {
                            text: "LEAD TIME:",
                            style: "subheader",
                            alignment: "right",
                          },
                          { text: leadTime, style: "subheader" },
                        ],
                      ],
                    },
                    layout: "noBorders",
                    alignment: "right",
                    width: "auto",
                  },
                ],
              },
            ],
          ],
        },
        {
          text: `CUSTOMER: ${customer}`,
          style: "customerInfo",
          margin: [0, 10, 0, 10],
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["*", "auto"],
            body: tableBody,
          },
          layout: {
            // Custom layout to handle vertical alignment
            defaultBorder: true,
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 4;
            },
            paddingBottom: function (i, node) {
              return 4;
            },
            hLineWidth: function (i, node) {
              return 0.5;
            },
            vLineWidth: function (i, node) {
              return 0.5;
            },
            hLineColor: function (i, node) {
              return "black";
            },
            vLineColor: function (i, node) {
              return "black";
            },
          },
        },
        {
          columns: [
            { width: "65%", text: "" },
            {
              style: "tableExample",
              table: {
                headerRows: 0,
                widths: ["*", "auto"],
                body: [
                  [
                    { text: "**Transport", alignment: "right" },
                    { text: formatMoney(transport), alignment: "right" },
                  ],
                  [
                    { text: "Pre-Tax TOTAL", alignment: "right" },
                    { text: preTaxTotal, alignment: "right" },
                  ],
                  [
                    { text: "Sales Tax", alignment: "right" },
                    { text: taxDisplay, alignment: "right" },
                  ],
                  [
                    {
                      text: "GRAND TOTAL",
                      alignment: "right",
                      bold: true,
                      fillColor: "#D3D3D3",
                      border: [true, true, true, true],
                    },
                    {
                      text: grandTotal,
                      alignment: "right",
                      bold: true,
                      fillColor: "#D3D3D3",
                      border: [true, true, true, true],
                    },
                  ],
                ],
              },
              layout: {
                defaultBorder: true,
                hLineWidth: function (i, node) {
                  return 0.5;
                },
                vLineWidth: function (i, node) {
                  return 0.5;
                },
                hLineColor: function (i, node) {
                  return "black";
                },
                vLineColor: function (i, node) {
                  return "black";
                },
                paddingLeft: function (i, node) {
                  return 4;
                },
                paddingRight: function (i, node) {
                  return 4;
                },
                paddingTop: function (i, node) {
                  return 2;
                },
                paddingBottom: function (i, node) {
                  return 2;
                },
              },
            },
          ],
        },
      ],
      styles: {
        header: { fontSize: 32, bold: true, color: "red" },
        subheader: { fontSize: 12, bold: true },
        customerInfo: { fontSize: 14, bold: true },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
          fillColor: "#D3E9FF",
          alignment: "center",
        },
        tableExample: { margin: [0, 5, 0, 15] },
      },
      defaultStyle: { font: "Roboto" },
    };

    console.log("PDF content:", dd);
    return dd;
  };

  const handlePDFDownload = async () => {
    setLoading(true);
    setIsButtonDisabled(true);
    try {
      const pdfDocGenerator = pdfMake.createPdf(createPDF());
      pdfDocGenerator.getBlob(async (blob) => {
        console.log("PDF Blob:", blob); // Log the blob to inspect it

        if (!blob || blob.size === 0) {
          throw new Error("Generated PDF blob is empty");
        }

        const quotes = estimate.quotes || [];
        const newRevNumber = quotes.length + 1;
        const fileName = `Quote_${estimate.customer.name}_Bmarko_Rev${newRevNumber}.pdf`;
        const folderId = estimate.googleFolderId; // Ensure this is the correct field containing the folder ID

        // Upload the file blob to the backend and get the URL
        const url = await createQuote(
          blob,
          fileName,
          "application/pdf",
          folderId
        );
        if (!url) {
          throw new Error("Failed to get URL after uploading the PDF");
        }

        // Update the estimate with the new quote URL
        quotes.push({ url, revision: `Rev${newRevNumber}` });

        await updateById(TABLES.ESTIMATES, { quotes }, estimate.id);

        message.success("PDF downloaded successfully.");

        // Download the PDF directly using pdfMake
        pdfDocGenerator.download(fileName);

        // Optionally: Set PDF URL for preview
        setPdfUrl(url);

        refreshEstimates();
        setLoading(false);
        setIsButtonDisabled(false);
      });
    } catch (error) {
      message.error("Failed to upload PDF: " + error.message);
      setLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const renderPDF = () => {
    setLoading(true);
    const pdfDocGenerator = pdfMake.createPdf(createPDF());
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob) + "#toolbar=0&navpanes=0";
      console.log("PDF Blob URL:", url);
      setPdfUrl(url);
      setLoading(false);
    });
  };

  useEffect(() => {
    renderPDF();
  }, [leadTime, quoteValidity, tax, taxType, transport, estimate]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          renderPDF();
        }
      },
      { threshold: 0.1 }
    );

    if (pdfViewerRef.current) {
      observer.observe(pdfViewerRef.current);
    }

    return () => {
      if (pdfViewerRef.current) {
        observer.unobserve(pdfViewerRef.current);
      }
    };
  }, [
    pdfViewerRef,
    leadTime,
    quoteValidity,
    tax,
    taxType,
    transport,
    estimate,
  ]);

  const handleTaxTypeChange = (value) => {
    setTaxType(value);
    if (value === "TBD") {
      setTax(null); // Reset tax value when "TBD" is selected
    }
  };

  return (
    <Row
      gutter={[16, 16]}
      style={{ backgroundColor: "white", padding: "16px" }}
    >
      <Col span={8}>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <label>Lead Time</label>
            <Input
              placeholder="Lead Time"
              value={leadTime}
              onChange={(e) => setLeadTime(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <label>Quote Validity</label>
            <DatePicker
              value={quoteValidity}
              onChange={(date) => setQuoteValidity(date)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={16} style={{ marginTop: 16 }}>
            <label>Sales Tax</label>
            <Row gutter={8}>
              <Col span={7}>
                <Select
                  value={taxType}
                  onChange={handleTaxTypeChange}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="%">%</Select.Option>
                  <Select.Option value="$">$</Select.Option>
                  <Select.Option value="TBD">TBD</Select.Option>
                </Select>
              </Col>
              <Col span={17}>
                {taxType !== "TBD" ? (
                  <InputNumber
                    type="number"
                    placeholder={taxType === "%" ? "Tax (%)" : "Tax ($)"}
                    value={tax}
                    style={{ width: "100%" }}
                    onChange={(value) => setTax(parseFloat(value))}
                  />
                ) : (
                  <Input
                    placeholder="TBD"
                    value="TBD"
                    style={{ width: "100%" }}
                    disabled
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={8} style={{ marginTop: 16 }}>
            <label>Transport</label>
            <InputNumber
              type="number"
              placeholder="Transport"
              prefix={"$"}
              style={{ width: "100%" }}
              value={transport}
              onChange={(value) => setTransport(parseFloat(value))}
            />
          </Col>
          <Col span={24} style={{ marginTop: 16, textAlign: "left" }}>
            <Button
              type="primary"
              onClick={handlePDFDownload}
              disabled={isButtonDisabled || loading}
              icon={<DownloadOutlined />}
              loading={loading}
            >
              {loading ? "Processing..." : "Download PDF"}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={16} ref={pdfViewerRef} style={{ marginTop: 16 }}>
        {loading ? (
          <Row justify="center" align="middle" style={{ height: "100%" }}>
            <Spin height={75} />
          </Row>
        ) : (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="600px"
            style={{ borderRadius: "5px", border: "1px solid #D3D3D3" }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <embed src={pdfUrl} type="application/pdf" />
          </object>
        )}
      </Col>
    </Row>
  );
};

export default QuotePDF;
