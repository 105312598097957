import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Row,
  Col,
  Badge,
  Tag,
  Modal,
  Button,
  Form,
  InputNumber,
  message,
  Typography,
  Empty,
  Image,
  Pagination,
} from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { usePurchaseOrders, useVendors } from "../../Contexts/useSpecificData";
import { updateById } from "../../Functions/updateById";
import { TABLES, SLACK_CHANNELS } from "../../constants";
import { getVendorName } from "../../Formatters/getVendorName";
import { Loader } from "../../Styled/Loader";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";

const { Search } = Input;
const { Text } = Typography;

// Extend dayjs with the isSameOrAfter plugin
dayjs.extend(isSameOrAfter);

// Memoized Card Component to prevent unnecessary re-renders
const PurchaseOrderCard = React.memo(({ line, onClick }) => (
  <Card
    hoverable
    size="small"
    onClick={() => onClick(line)}
    title={
      <>
        <Tag color="blue">{line.poNumber}</Tag> | Received:{" "}
        {dayjs(line.receivedDate).format("MM/DD/YYYY")}
      </>
    }
    style={{ width: "100%" }}
  >
    <Row gutter={[16, 8]}>
      <Col span={12}>
        <Text strong>Line Number:</Text> {line.lineNumber}
      </Col>
      <Col span={12}>
        <Text strong>Item Number:</Text> {line.itemNumber}
      </Col>

      <Col span={12}>
        <Text strong>Vendor:</Text> {line.vendorName}
      </Col>
      <Col span={12}>
        <Text strong>Category:</Text> {line.category}
      </Col>

      <Col span={12}>
        <Text strong>Qty Ordered:</Text> {line.qtyOrdered}
      </Col>
      <Col span={12}>
        <Text strong>Qty Received:</Text> {line.qtyReceived || 0}
      </Col>

      <Col span={24}>
        <Text strong>Item Description:</Text> {line.itemDescription}
      </Col>

      <Col span={24}>
        <Text strong>Received By:</Text> {line.receivedBy}
      </Col>
    </Row>
  </Card>
));

function Unreceive() {
  const {
    data: purchaseOrders,
    refresh: refreshPurchaseOrders,
    loading: loadingPurchaseOrders,
  } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLine, setSelectedLine] = useState(null); // For handling modal
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
  const [loading, setLoading] = useState(false); // Modal loading state
  const [newQtyReceived, setNewQtyReceived] = useState(null); // Track the new value
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  // Get the start of last Monday
  const today = dayjs();
  const lastMonday =
    today.day() === 0 // Check if today is Sunday
      ? today.subtract(1, "week").startOf("week").add(1, "day")
      : today.startOf("week").subtract(1, "week").add(1, "day");

  // Display a spinner if purchaseOrders is not loaded
  if (!purchaseOrders || loadingPurchaseOrders) {
    return <Loader />;
  }

  // Filter and sort the purchase orders and their lines
  const filteredPurchaseOrders = purchaseOrders
    .map((purchaseOrder) => {
      const filteredLines = purchaseOrder.lines
        .filter(
          (line) =>
            line.receivedDate &&
            dayjs(line.receivedDate).isSameOrAfter(lastMonday)
        ) // Only include lines with receivedDate after last Monday
        .sort((a, b) => dayjs(b.receivedDate).diff(dayjs(a.receivedDate))); // Sort by most recent received date first

      return {
        ...purchaseOrder,
        lines: filteredLines,
        vendorName: getVendorName(purchaseOrder.vendor, vendors), // Get vendor name here
      };
    })
    .filter((purchaseOrder) => purchaseOrder.lines.length > 0) // Only include orders with matching lines
    .flatMap((purchaseOrder) =>
      purchaseOrder.lines.map((line) => ({
        ...line,
        poNumber: purchaseOrder.poNumber,
        vendorName: purchaseOrder.vendorName, // Include vendor name with each line
        purchaseOrderId: purchaseOrder.id, // Keep track of the PO ID for updating
      }))
    );

  // Filter by search term
  const filteredAndSearchedLines = filteredPurchaseOrders
    .filter(
      (line) =>
        line.itemDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
        line.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        line.poNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        line.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => dayjs(b.receivedDate).diff(dayjs(a.receivedDate))); // Global sort

  // Calculate pagination
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedLines = filteredAndSearchedLines.slice(startIndex, endIndex);

  // Show the modal for updating qtyReceived
  const showModal = (line) => {
    setSelectedLine(line);
    setNewQtyReceived(line.qtyReceived); // Initialize the new qtyReceived to the current value
    setIsModalVisible(true);
  };

  const handleOk = async (values) => {
    setLoading(true); // Start loading
    try {
      const oldQtyReceived = selectedLine.qtyReceived;

      const updatedLine = {
        ...selectedLine,
        qtyReceived: values.qtyReceived,
        receivedDate:
          values.qtyReceived === 0 ? null : selectedLine.receivedDate,
        images: values.qtyReceived === 0 ? [] : selectedLine.images,
      };

      const originalPo = purchaseOrders.find(
        (po) => po.id === selectedLine.purchaseOrderId
      );

      const updatedLines = originalPo.lines.map((line) =>
        line.itemNumber === selectedLine.itemNumber ? updatedLine : line
      );

      const updatedPurchaseOrder = {
        ...originalPo,
        lines: updatedLines,
      };

      const poStatus = updatedLines.every(
        (line) => line.qtyOrdered <= line.qtyReceived
      )
        ? "Closed"
        : "Open";

      // Send Slack message
      await sendSlackMessage({
        channel:
          process.env.STAGE === "_dev"
            ? SLACK_CHANNELS.RECEIVING_DEV
            : SLACK_CHANNELS.RECEIVING_PROD,
        text: `:newspaper: PO ${originalPo.poNumber} has been altered for receiving.`,
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: `:mega: PO ${originalPo.poNumber} - Receiving Update`,
              emoji: true,
            },
          },
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: `*Item:* ${updatedLine.itemDescription}`,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*PO Status:* ${
                  poStatus === "Closed"
                    ? ":white_check_mark: Closed"
                    : ":octagonal_sign: Open"
                }`,
              },
              {
                type: "mrkdwn",
                text: `*Line Number:* ${updatedLine.lineNumber}`,
              },
            ],
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Old Qty Received:* ${oldQtyReceived}`,
              },
              {
                type: "mrkdwn",
                text: `*New Qty Received:* ${updatedLine.qtyReceived}`,
              },
            ],
          },
        ],
      });

      await updateById(
        TABLES.PURCHASE_ORDERS,
        updatedPurchaseOrder,
        selectedLine.purchaseOrderId
      );

      message.success("Quantity updated successfully.");
      setIsModalVisible(false);
      await refreshPurchaseOrders(); // Refresh the purchase orders after updating
    } catch (error) {
      message.error("Error updating quantity.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Handle search input change and reset pagination
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  return (
    <div>
      {/* Header with Badge */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 16,
          fontSize: 18,
          opacity: 0.9,
        }}
      >
        Recently Received{" "}
        <Badge
          style={{ marginLeft: 8 }}
          overflowCount={1000}
          count={filteredAndSearchedLines.length}
        />
      </div>

      {/* Search Input */}
      <Search
        placeholder="Search by item description, category, Vendor, or PO number"
        onChange={handleSearchChange}
        style={{ marginBottom: 16 }}
      />

      {/* Display Paginated Cards or Empty State */}
      {filteredAndSearchedLines.length > 0 ? (
        <>
          <Row gutter={[16, 16]}>
            {paginatedLines.map((line, index) => (
              <Col key={line.itemNumber+index} xs={24} sm={12} md={8} lg={6}>
                <PurchaseOrderCard line={line} onClick={showModal} />
              </Col>
            ))}
          </Row>

          {/* Pagination Controls */}
          <div style={{ textAlign: "center", marginTop: 16 }}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={filteredAndSearchedLines.length}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false} // Hide pageSize changer if not needed
            />
          </div>
        </>
      ) : (
        <Empty
          style={{ marginTop: 16 }}
          description="No purchase orders recently received found."
        />
      )}

      {/* Modal for Updating Qty Received */}
      {selectedLine && (
        <Modal
          title={`Update Qty Received for ${selectedLine.itemNumber}`}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            initialValues={{
              qtyReceived: selectedLine.qtyReceived || 0,
            }}
            onFinish={handleOk}
          >
            <div style={{ marginBottom: 16 }}>
              <Tag color="blue">{selectedLine.poNumber}</Tag>
            </div>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Text strong>Line Number:</Text> {selectedLine.lineNumber}
              </Col>
              <Col span={12}>
                <Text strong>Item Number:</Text> {selectedLine.itemNumber}
              </Col>
              <Col span={24}>
                <Text strong>Vendor:</Text> {selectedLine.vendorName}
              </Col>
              <Col span={24}>
                <Text strong>Item Description:</Text>
                {selectedLine.itemDescription}
              </Col>
              <Col span={24}>
                <Text strong>Category:</Text> {selectedLine.category}
              </Col>
              <Col span={24}>
                <Text strong>Qty Ordered:</Text> {selectedLine.qtyOrdered}
              </Col>

              {selectedLine.images && selectedLine.images.length > 0 && (
                <Col span={24}>
                  <Image.PreviewGroup>
                    {selectedLine.images.map((img, imgIndex) => (
                      <Image
                        key={imgIndex}
                        width={50}
                        src={img}
                        alt={`Preview ${imgIndex + 1}`}
                      />
                    ))}
                  </Image.PreviewGroup>
                </Col>
              )}
            </Row>

            <Form.Item
              name="qtyReceived"
              label="Update Qty Received"
              style={{ marginTop: 16 }}
              rules={[
                {
                  required: true,
                  message: "Please enter a quantity received",
                },
                {
                  type: "number",
                  min: 0,
                  max: selectedLine.qtyOrdered,
                  message: `Quantity must be between 0 and ${selectedLine.qtyOrdered}`,
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                max={selectedLine.qtyOrdered}
                value={newQtyReceived}
                onChange={setNewQtyReceived}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={newQtyReceived === selectedLine.qtyReceived} // Disable if no change
                style={{ width: "100%" }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default Unreceive;
