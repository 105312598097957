// AddInvoiceModal.jsx

import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Upload,
  Button,
  message,
  Alert,
} from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { TABLES } from "../../constants";
import { getUuid } from "../../utils/getUuid";
import { s3Upload } from "../../Functions/s3Upload"; // Import your existing s3Upload function
import { updateById } from "../../Functions/updateById"; // Import your existing updateById function
import { getVendorName } from "../../Formatters/getVendorName";
import { useVendors } from "../../Contexts/useSpecificData";

const { Dragger } = Upload;

const AddInvoiceModal = ({
  visible,
  onCancel,
  purchaseOrder,
  onInvoiceAdded,
}) => {
  const [form] = Form.useForm();
  const [uploadedFileURL, setUploadedFileURL] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: vendors } = useVendors();

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const uniqueFileName = `${purchaseOrder.poNumber}_invoice_${timestamp}_${file.name}`;
      const fileUrl = await s3Upload(file, uniqueFileName);

      if (fileUrl) {
        setUploadedFileURL(fileUrl);
        setFileName(file.name);
        message.success("File uploaded successfully.");
        onSuccess("ok");
      } else {
        onError("Upload failed");
        message.error("Failed to upload file.");
      }
    } catch (error) {
      console.error("Upload Error:", error);
      onError("Upload failed");
    }
  };

  const handleFormSubmit = async (values) => {
    if (!uploadedFileURL) {
      message.error("Please upload an invoice document.");
      return;
    }

    setIsSubmitting(true);
    const { invoiceAmount, invoiceDueDate, invoiceNumber } = values;
    const newInvoice = {
      id: getUuid(),
      document: uploadedFileURL,
      amount: parseFloat(invoiceAmount),
      dueDate: invoiceDueDate.toISOString(),
      invoiceNumber,
    };

    const updatedInvoices = purchaseOrder.invoices
      ? [...purchaseOrder.invoices, newInvoice]
      : [newInvoice];
    const updatedPO = { ...purchaseOrder, invoices: updatedInvoices };

    try {
      await updateById(TABLES.PURCHASE_ORDERS, updatedPO, purchaseOrder.id);
      message.success("Invoice added successfully!");
      form.resetFields();
      setUploadedFileURL(null);
      setFileName(null);
      onInvoiceAdded(updatedPO); // Notify parent component
      onCancel(); // Close the modal
    } catch (error) {
      console.error("Error updating Purchase Order:", error);
      message.error("Error updating Purchase Order.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveFile = () => {
    setUploadedFileURL(null);
    setFileName(null);
  };

  return (
    <Modal
      title={`Add Invoice for PO #${purchaseOrder.poNumber} - ${getVendorName(purchaseOrder.vendor,vendors)}`} // Update the modal title
      visible={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="invoiceNumber"
          label="Invoice Number"
          rules={[
            { required: true, message: "Please enter the invoice number" },
          ]}
        >
          <Input placeholder="Enter invoice number" />
        </Form.Item>
        <Form.Item
          name="invoiceAmount"
          label="Invoice Amount"
          rules={[
            { required: true, message: "Please enter the invoice amount" },
          ]}
        >
          <InputNumber
            prefix="$"
            style={{ width: "100%" }}
            placeholder="Enter invoice amount"
          />
        </Form.Item>
        <Form.Item
          name="invoiceDueDate"
          label="Invoice Due Date"
          rules={[{ required: true, message: "Please select the due date" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Upload Invoice Document"
          name="invoiceFile"
          rules={[
            { required: true, message: "Please upload the invoice document" },
          ]}
        >
          {!uploadedFileURL ? (
            <Dragger
              name="invoice"
              multiple={false}
              customRequest={handleUpload}
              showUploadList={false}
              accept=".pdf" // Ensure only PDFs are accepted
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ fontSize: "32px" }} />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload (PDF only)
              </p>
            </Dragger>
          ) : (
            <Alert
              message={`Uploaded: ${fileName}`}
              type="success"
              showIcon
              action={
                <Button
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={handleRemoveFile}
                >
                  Remove
                </Button>
              }
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isSubmitting}>
            Submit Invoice
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddInvoiceModal;
