import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, message, Tabs, Card, Space, Badge } from "antd";
import {
  EditOutlined,
  UserOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  DollarOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import { formatDate, formatMoney } from "../../Formatters/helpers";
import AddEstimateModal from "../../Components/Estimating/AddEstimateModal";
import FilePreview from "../../Components/Google/FilePreview";
import EstimateModules from "../../Components/Estimating/Modules/EstimateModules";
import { COLORS } from "../../constants";
import { useEstimates } from "../../Contexts/useSpecificData";
import RFQ from "../../Components/Estimating/RFQ/RFQ";
import CustomItems from "../../Components/Estimating/CustomItems.js/CustomItems";
import BOM from "../../Components/Estimating/BOM/bom";
import QuotePDF from "../../Components/Estimating/Quote/quote";
import { calculateEstimateCosts } from "../../Formatters/calculateEstimate";
import { Loader } from "../../Styled/Loader";

const { TabPane } = Tabs;

export default function BuildEstimate() {
  const { id, tab } = useParams();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const {
    data: estimates,
    loading: estimatesLoading,
    error: estimatesError,
    refresh: refreshEstimates,
  } = useEstimates();

  useEffect(() => {
    if (!estimatesLoading && estimates) {
      const selectedEstimate = estimates.find((est) => est.id === id);
      setEstimate(selectedEstimate || null);
      setLoading(false);
    }
  }, [id, estimates, estimatesLoading]);

  useEffect(() => {
    if (estimate) {
      const tabName = tab ? tab.replace("-", " ") : "modules";
      document.title = `${estimate.estimateNumber} - ${tabName}`;
    } else {
      document.title = "Build Estimate";
    }
  }, [estimate, tab]);

  const onUpdateSuccess = () => {
    refreshEstimates();
    message.success("Estimate updated successfully!");
  };

  if (estimatesError) return <div>Error: {estimatesError.message}</div>;

  const costs = estimate ? calculateEstimateCosts(estimate) : {};

  const handleTabChange = (activeKey) => {
    navigate(`/estimating/estimates/${id}/${activeKey}`);
  };

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <AddEstimateModal
          title="Update estimate"
          estimate={estimate}
          onAddSuccess={onUpdateSuccess}
          loading={loading}
        >
          <Card
            style={{
              backgroundColor: "white",
              cursor: "pointer",
              position: "relative",
            }}
            size="small"
          >
            <EditOutlined
              style={{
                fontSize: "18px",
                color: "#08c",
                position: "absolute",
                top: 16,
                right: 16,
              }}
            />
            <Row gutter={16}>
              <Col span={24}>
                <Space
                  direction="horizontal"
                  size="large"
                  align="center"
                  style={{ width: "100%" }}
                >
                  {/* Estimate Name */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: 18, fontWeight: 600 }}>
                      {estimate?.estimateName || "N/A"}
                    </div>
                  </div>

                  {/* Estimate Number */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 12,
                    }}
                  >
                    <NumberOutlined
                      style={{ color: "#555", fontSize: 16, marginRight: 4 }}
                    />
                    <div
                      style={{ fontSize: 14, color: "#555", fontWeight: 600 }}
                    >
                      {estimate?.estimateNumber || "N/A"}
                    </div>
                  </div>

                  {/* Assigned To */}
                  {estimate?.assignedTo && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 12,
                      }}
                    >
                      <UserOutlined
                        style={{ color: "#555", fontSize: 16, marginRight: 4 }}
                      />
                      <div style={{ fontSize: 14, color: "#555" }}>
                        {estimate?.assignedTo}
                      </div>
                    </div>
                  )}

                  {/* Estimate Status */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 12,
                    }}
                  >
                    <InfoCircleOutlined
                      style={{ color: "#555", fontSize: 16, marginRight: 4 }}
                    />
                    <div style={{ fontSize: 14, color: "#555" }}>
                      {estimate?.estimateStatus || "N/A"}
                    </div>
                  </div>

                  {/* Quote By */}
                  {estimate?.quoteBy && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 12,
                      }}
                    >
                      <CalendarOutlined
                        style={{ color: "#555", fontSize: 16, marginRight: 4 }}
                      />
                      <div style={{ fontSize: 14, color: "#555" }}>
                        {formatDate(estimate?.quoteBy)}
                      </div>
                    </div>
                  )}

                  {/* Total Cost */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 12,
                    }}
                  >
                    <DollarOutlined
                      style={{ color: "#555", fontSize: 16, marginRight: 4 }}
                    />
                    <div style={{ fontSize: 14, color: "#555" }}>
                      {formatMoney(costs.totalCost)}
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>
        </AddEstimateModal>
      </Col>
      <Col span={24}>
        <Tabs
          activeKey={tab || "modules"}
          onChange={handleTabChange}
          style={{ width: "100%" }}
          type="card"
        >
          <TabPane
            tab={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Modules</span>
              </div>
            }
            key="modules"
          >
            {estimate ? (
              <EstimateModules
                estimate={estimate}
                onAddSuccess={refreshEstimates}
              />
            ) : (
<Loader/>
            )}
          </TabPane>

          <TabPane tab="RFQ" key="rfq">
            {estimate ? (
              <RFQ estimate={estimate} />
            ) : (
<Loader/>
            )}
          </TabPane>

          <TabPane tab="Custom Items" key="custom-items">
            {estimate ? (
              <CustomItems estimate={estimate} />
            ) : (
<Loader/>
            )}
          </TabPane>

          <TabPane tab="BOM" key="bom">
            {estimate ? (
              <BOM estimate={estimate} />
            ) : (
<Loader/>
            )}
          </TabPane>

          <TabPane tab="Quote" key="quote">
            {estimate ? (
              <QuotePDF estimate={estimate} />
            ) : (
<Loader/>
            )}
          </TabPane>

          <TabPane tab="Files" key="files">
            {estimate ? (
              <FilePreview googleFolderId={estimate?.googleFolderId} />
            ) : (
<Loader/>
            )}
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}
