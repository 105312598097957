import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Input,
  Table,
  Row,
  Col,
  Card,
  InputNumber,
  message,
  Form,
  Switch,
  Tag,
  Badge,
  Typography,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import {
  useItems,
  useEstimates,
  usePurchaseOrders,
  useVendors,
} from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { formatMoney } from "../../../Formatters/helpers";
import { COLORS, TABLES } from "../../../constants";
import { getDefaultPriceItem } from "../../../utils/itemsHelpers";
import debounce from "lodash.debounce";

const getPreferredPrice = (item) => {
  if (item.lastPurchased && item.lastPurchased.price) {
    return parseFloat(item.lastPurchased.price);
  }
  const highestVendorPrice = item.vendors?.reduce((max, vendor) => {
    return vendor.price > max ? vendor.price : max;
  }, 0);
  return highestVendorPrice ? parseFloat(highestVendorPrice) : 0;
};

const CustomItems = ({ estimate }) => {
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [isCustomForm, setIsCustomForm] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [quantities, setQuantities] = useState({});
  const [customItems, setCustomItems] = useState(estimate.customItems || []);
  const [customItem, setCustomItem] = useState({
    itemDescription: "",
    price: "",
    quantity: 1,
  });

  const { data: items, loading: itemsLoading } = useItems();
  const { refresh: refetchEstimates } = useEstimates();

  useEffect(() => {
    if (estimate.customItems) {
      setCustomItems(estimate.customItems);
    }
  }, [estimate.customItems]);

  const handleAddCustomItem = () => {
    const newItem = {
      ...customItem,
      id: uuidv4(),
      price: parseFloat(customItem.price),
      quantity: parseFloat(customItem.quantity),
      type: "custom",
    };
    const updatedItems = [...customItems, newItem];
    setCustomItems(updatedItems);
    debouncedSave(updatedItems);
    message.success(`Added custom item: ${newItem.itemDescription}`);
    setCustomItem({
      itemDescription: "",
      price: "",
      quantity: 1,
    });
    setVisible(false);
  };

  const handleAddExistingItem = (itemId) => {
    const item = items.find((i) => i.id === itemId);
    if (!item) {
      message.error("Item not found.");
      return;
    }
    const preferredPrice = getPreferredPrice(item);
    const newItem = {
      ...item,
      price: preferredPrice,
      quantity: quantities[itemId],
      type: "aria",
    };
    const updatedItems = [...customItems, newItem];
    setCustomItems(updatedItems);
    debouncedSave(updatedItems);
    message.success(`Added Aria item: ${item.itemDescription}`);
    setQuantities({ ...quantities, [itemId]: null });
  };

  const handleQuantityChange = (value, itemId) => {
    setQuantities({ ...quantities, [itemId]: value });

    // Update the quantity in customItems
    const updatedItems = customItems.map((ci) =>
      ci.id === itemId ? { ...ci, quantity: value } : ci
    );
    setCustomItems(updatedItems);
    debouncedSave(updatedItems);
  };

  const handleSave = async (updatedItems) => {
    try {
      await updateById(
        TABLES.ESTIMATES,
        { customItems: updatedItems },
        estimate.id
      );
      refetchEstimates(); // Refresh the estimates data
    } catch (error) {
      message.error("Failed to update custom items: " + error.message);
    }
  };

  // Debounce the handleSave function to prevent excessive updates
  const debouncedSave = useCallback(
    debounce((updatedItems) => {
      handleSave(updatedItems);
    }, 500), // 500ms debounce delay; adjust as needed
    []
  );

  useEffect(() => {
    // Cleanup the debounce on unmount
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  const handleRemove = () => {
    const itemToDelete = customItems.find((item) => item.id === itemToRemove);
    if (!itemToDelete) {
      message.error("Item not found.");
      setConfirmVisible(false);
      return;
    }
    const updatedItems = customItems.filter((item) => item.id !== itemToRemove);
    setCustomItems(updatedItems);
    handleSave(updatedItems);
    message.success(`Removed item: ${itemToDelete.itemDescription}`);
    setConfirmVisible(false);
  };

  const filteredAssemblies = items.filter((item) => {
    const matchesSearchTerm =
      !searchTerm ||
      item.itemDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.manufacturer.toLowerCase().includes(searchTerm.toLowerCase());
    const notAlreadyAdded = !customItems.find(
      (customItem) => customItem.id === item.id
    );
    return matchesSearchTerm && notAlreadyAdded;
  });

  const columns = [
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Price",
      key: "price",
      render: (text, record) => formatMoney(getPreferredPrice(record)),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={quantities[record.id]}
          onChange={(value) => handleQuantityChange(value, record.id)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleAddExistingItem(record.id)}
          disabled={!quantities[record.id]}
        >
          Add
        </Button>
      ),
    },
  ];

  const getTotalCostByType = (type) =>
    customItems
      .filter((item) => item.type === type)
      .reduce((total, item) => total + item.price * item.quantity, 0);

  const renderItemsByType = (type, color) => (
    <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
      {customItems
        .filter((item) => item.type === type)
        .map((item) => (
          <Col span={8} key={item.id}>
            <Card
              className="box-shadow"
              size="small"
              actions={[
                <div>
                  <span style={{ marginRight: 8 }}>Qty:</span>
                  <InputNumber
                    min={1}
                    value={item.quantity}
                    onChange={(value) => handleQuantityChange(value, item.id)}
                  />
                </div>,
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setItemToRemove(item.id);
                    setConfirmVisible(true);
                  }}
                  style={{ marginLeft: 8 }}
                />,
              ]}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start", // Align to top
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ fontWeight: 500, maxWidth: "62.5%", fontSize: 13 }}
                  >
                    {item.itemDescription}
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <div style={{ marginBottom: 8 }}>
                      <Typography.Text
                        type="secondary"
                        style={{ marginRight: 8 }}
                      >
                        Price
                      </Typography.Text>
                      <Tag color="green">{formatMoney(item.price)}</Tag>
                    </div>
                    <div>
                      <Typography.Text style={{ marginRight: 8 }}>
                        Total
                      </Typography.Text>
                      <Tag color="green" style={{ fontWeight: 600 }}>
                        {formatMoney(item.price * item.quantity)}
                      </Tag>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
    </Row>
  );

  // Find the item to delete based on itemToRemove
  const itemToDelete = customItems.find((item) => item.id === itemToRemove);

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 18, marginRight: 12, fontWeight: 500 }}>
              Total Cost:
            </span>
            <Tag
              style={{ fontSize: 18, padding: 4, fontWeight: 600 }}
              color={"green"}
            >
              {formatMoney(
                getTotalCostByType("custom") + getTotalCostByType("aria")
              )}
            </Tag>
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
          >
            Manage Custom Items
          </Button>
        </Col>
      </Row>
      <Modal
        title="Manage Custom Items"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="100%"
      >
        <Row justify="end" style={{ marginBottom: 16 }}>
          <Col>
            <Switch
              checkedChildren="Custom Items"
              unCheckedChildren="Aria Items"
              checked={isCustomForm}
              onChange={() => setIsCustomForm(!isCustomForm)}
            />
          </Col>
        </Row>

        {isCustomForm ? (
          <Card title="Add Custom Item">
            <Form layout="vertical">
              <Form.Item label="Item Description" required>
                <Input
                  placeholder="Item Description"
                  value={customItem.itemDescription}
                  onChange={(e) =>
                    setCustomItem({
                      ...customItem,
                      itemDescription: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="Price" required>
                <InputNumber
                  placeholder="Price"
                  type="number"
                  value={customItem.price}
                  onChange={(value) =>
                    setCustomItem({ ...customItem, price: value })
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item label="Quantity" required>
                <InputNumber
                  min={1}
                  value={customItem.quantity}
                  type="number"
                  onChange={(value) =>
                    setCustomItem({ ...customItem, quantity: value })
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Button type="primary" onClick={handleAddCustomItem} block>
                Add Custom Item
              </Button>
            </Form>
          </Card>
        ) : (
          <div>
            <Input
              placeholder="Search Items"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "100%", marginBottom: 16 }}
            />
            <Table
              columns={columns}
              dataSource={filteredAssemblies}
              rowKey="id"
              pagination={{
                pageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "25", "50", "100"],
              }}
              loading={itemsLoading}
            />
          </div>
        )}
      </Modal>

      {customItems.filter((item) => item.type === "custom").length > 0 && (
        <Card
          className="box-shadow"
          size="small"
          bodyStyle={{
            backgroundColor: "#E3ECF8",
          }}
          style={{ boxShadow: "0 0 5px 1px #E3ECF8", marginTop: 16 }}
        >
          <div
            className="box-title"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 16,
              fontWeight: 600,
              marginBottom: 12,
            }}
          >
            <Badge
              color={"blue"}
              count={
                customItems.filter((item) => item.type === "custom").length
              }
            />
            <span style={{ marginLeft: 8 }}>Custom Items</span>
            <Tag
              color="green"
              style={{ marginLeft: "auto", fontWeight: 600, fontSize: 16 }}
            >
              {formatMoney(getTotalCostByType("custom"))}
            </Tag>
          </div>
          {renderItemsByType("custom", "blue")}
        </Card>
      )}

      {customItems.filter((item) => item.type === "aria").length > 0 && (
        <Card
          className="box-shadow"
          size="small"
          bodyStyle={{
            backgroundColor: "#E3ECF8",
          }}
          style={{ boxShadow: "0 0 5px 1px #E3ECF8", marginTop: 16 }}
        >
          <div
            className="box-title"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 16,
              fontWeight: 600,
              marginBottom: 12,
            }}
          >
            <Badge
              color={"purple"}
              count={customItems.filter((item) => item.type === "aria").length}
            />
            <span style={{ marginLeft: 8 }}>Aria Items</span>
            <Tag
              color="green"
              style={{ marginLeft: "auto", fontWeight: 600, fontSize: 16 }}
            >
              {formatMoney(getTotalCostByType("aria"))}
            </Tag>
          </div>
          {renderItemsByType("aria", "purple")}
        </Card>
      )}

      {/* Confirmation Modal */}
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExclamationCircleOutlined
              style={{ color: "#faad14", marginRight: 8 }}
            />
            <span>Confirm Removal</span>
          </div>
        }
        visible={confirmVisible}
        onOk={handleRemove}
        onCancel={() => setConfirmVisible(false)}
        okText="Yes"
        cancelText="No"
        okButtonProps={{ danger: true }} // Highlights the OK button in red
      >
        {itemToRemove && itemToDelete ? (
          <div>
            {/* Confirmation Message */}
            <Typography.Text strong>
              Are you sure you want to remove this item?
            </Typography.Text>

            {/* Item Description */}
            <div style={{ marginTop: 16 }}>
              <Typography.Text strong>
                {itemToDelete.itemDescription}
              </Typography.Text>
            </div>

            {/* Quantity, Price, and Total in the Same Row */}
            <Row style={{ marginTop: 8 }} gutter={16}>
              {/* Quantity */}
              <Col span={8}>
                <Typography.Text>Quantity:</Typography.Text>
                <Typography.Text strong style={{ marginLeft: 8 }}>
                  {itemToDelete.quantity}
                </Typography.Text>
              </Col>

              {/* Price */}
              <Col span={8}>
                <Typography.Text>Price:</Typography.Text>
                <Typography.Text strong style={{ marginLeft: 8 }}>
                  {formatMoney(itemToDelete.price)}
                </Typography.Text>
              </Col>

              {/* Total */}
              <Col span={8}>
                <Typography.Text>Total:</Typography.Text>
                <Typography.Text strong style={{ marginLeft: 8 }}>
                  {formatMoney(itemToDelete.price * itemToDelete.quantity)}
                </Typography.Text>
              </Col>
            </Row>
          </div>
        ) : (
          <Typography.Text>No item selected for removal.</Typography.Text>
        )}
      </Modal>
    </div>
  );
};

export default CustomItems;
