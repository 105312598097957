import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Badge } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { useVendors, usePurchaseOrders, useProjects } from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { formatMoney } from "../../Formatters/helpers";
import dayjs from "dayjs";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import TableHeader from "../../Styled/TableHeader";
import { COLORS } from "../../constants";
import ReturnOrderModal from "./ReturnOrderModal";
import { getProjectName } from "../../Formatters/getProjectName";

const ReturnsTable = ({ onFilterChange }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    vendors: [],
    buyers: [],
    projects: [],
    status: [],
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const {
    data: vendors,
    loading: vendorsLoading,
    error: vendorsError,
  } = useVendors();
  const {
    data: purchaseOrders,
    loading: poLoading,
    error: poError,
  } = usePurchaseOrders();

  const { data: projects } = useProjects();

  const returnOrders = purchaseOrders.filter((po) => po.returnOrder);

  console.log("returnOrders:", returnOrders);

  // Aggregate and structure the data
const aggregateData = (data) => {
  const aggregated = {};
  data.forEach((item) => {
    if (!aggregated[item.roNumber]) {
      aggregated[item.roNumber] = {
        ...item,
        lines: item.lines || [],
        totalQty: 0,
        totalAmount: 0,
        receivedDate: null,
        roStatus: "Closed",
      };
    }

    // Include all lines (not just those with qtyReturn > 0)
    item.lines.forEach((line) => {
      aggregated[item.roNumber].totalQty += parseFloat(line.qtyReturn || "0");
      aggregated[item.roNumber].totalAmount +=
        parseFloat(line.itemPrice || "0") * parseFloat(line.qtyReturn || "0");

      if (line.shippedDate) {
        const lineReceivedDate = dayjs(line.shippedDate);
        if (
          !aggregated[item.roNumber].receivedDate ||
          lineReceivedDate.isAfter(
            dayjs(aggregated[item.roNumber].receivedDate)
          )
        ) {
          aggregated[item.roNumber].receivedDate = line.shippedDate;
        }
      }

      // Check if all qtyReturn === qtyShipped for roStatus "Closed"
      if (line.qtyReturn !== line.qtyShipped) {
        aggregated[item.roNumber].roStatus = "Open";
      }
    });

    // Store all lines (including those without qtyReturn)
    aggregated[item.roNumber].lines = item.lines;
  });

  return Object.values(aggregated);
};


  const calculateLateStatus = (record) => {
    if (!record || !record.returnOrder.requiredDate) return false;
    const requiredDateMidnight = dayjs(record.returnOrder.requiredDate).startOf(
      "day"
    );
    const latestShippedDate = record.lines
      .filter((line) => line.shippedDate)
      .map((line) => dayjs(line.shippedDate))
      .sort((a, b) => b - a)[0];
    if (latestShippedDate) {
      return latestShippedDate.isAfter(requiredDateMidnight);
    }
    return dayjs().isAfter(requiredDateMidnight) && record.roStatus === "Open";
  };

  useEffect(() => {
    console.log("Modal visible state:", modalVisible);
  }, [modalVisible]);


  useEffect(() => {
    if (!poLoading && !poError && purchaseOrders.length > 0) {
      const aggregatedResult = aggregateData(returnOrders);
      setData(aggregatedResult);
      setOriginalData(aggregatedResult);
      setLoading(false);
      onFilterChange(aggregatedResult);
      updateFilterOptions(aggregatedResult); // Initial filter options from the aggregated result
    }
  }, [poLoading, poError, purchaseOrders]);

  const getUniqueFilters = (dataSource, dataIndex) => {
    return Array.from(
      new Set(
        dataSource.map((item) =>
          dataIndex === "vendor"
            ? getVendorName(item[dataIndex], vendors)
            : dataIndex === "buyer"
            ? formatBuyerName(item[dataIndex])
            : item[dataIndex]
        )
      )
    ).map((value) => ({
      text: value,
      value: value,
    }));
  };

  const updateFilterOptions = (filteredData) => {
    setFilterOptions({
      vendors: getUniqueFilters(filteredData, "vendor"),
      buyers: getUniqueFilters(filteredData, "buyer"),
      projects: getUniqueFilters(filteredData, "projectNumber"),
      status: getUniqueFilters(filteredData, "roStatus"),
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    let filteredData = originalData;

    if (value) {
      filteredData = originalData.filter((record) => {
        const vendorName = getVendorName(record.vendor, vendors);
        return (
          record.roNumber.toLowerCase().includes(value) ||
          vendorName.toLowerCase().includes(value) ||
          formatBuyerName(record.buyer).toLowerCase().includes(value) ||
          record.projectNumber.toLowerCase().includes(value) ||
          record.poNumber.toLowerCase().includes(value)
        );
      });
    }

    if (Object.keys(filteredInfo).length > 0) {
      filteredData = applyFiltersToData(filteredData);
    }

    setData(filteredData);
    onFilterChange(filteredData);
    updateFilterOptions(filteredData); // Update the available filter options after search
  };

  const handleFilterChange = (pagination, filters) => {
    setFilteredInfo(filters); // Track filters in state
    const filteredData = applyFiltersToData(originalData, filters);
    setData(filteredData);
    onFilterChange(filteredData);
    updateFilterOptions(filteredData); // Update filter options after applying filters
  };

  const applyFiltersToData = (data, filters = filteredInfo) => {
    return data.filter((record) => {
      return Object.keys(filters).every((key) => {
        const filterValues = filters[key];
        if (!filterValues) return true;
        const recordValue =
          key === "vendor"
            ? getVendorName(record[key], vendors)
            : key === "buyer"
            ? formatBuyerName(record[key])
            : record[key];
        return filterValues.includes(recordValue);
      });
    });
  };

  const extractNumber = (roNumber) => {
    const match = roNumber.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };


  const columns = [
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{text}</span>
          <Badge
            count={record.lines.length}
            style={{ background: "#6BA1E2", fontWeight: "bold" }}
          />
        </div>
      ),
    },
    {
      title: "RO Number",
      dataIndex: "roNumber",
      key: "roNumber",
      sorter: (a, b) => extractNumber(a.roNumber) - extractNumber(b.roNumber),
      defaultSortOrder: "descend", // Set default sort order to ascending
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{text}</span>
          <Badge
            count={record.lines.filter((line) => line.qtyReturn > 0).length}
            style={{ fontWeight: "bold" }}
          />
        </div>
      ),
    },

    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      sorter: (a, b) =>
        getVendorName(a.vendor, vendors).localeCompare(
          getVendorName(b.vendor, vendors)
        ),
      filterSearch: true,
      filters: filterOptions.vendors,
      filteredValue: filteredInfo.vendor || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        getVendorName(record.vendor, vendors).includes(value),
      render: (text) =>
        vendorsLoading ? "Loading..." : getVendorName(text, vendors),
    },
    {
      title: "Date Submitted",
      dataIndex: ["returnOrder", "orderedDate"],
      key: "orderedDate",
      sorter: (a, b) =>
        dayjs(a.returnOrder.orderedDate).diff(dayjs(b.returnOrder.orderedDate)),
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "Required Date",
      dataIndex: ["returnOrder", "requiredDate"],
      key: "requiredDate",
      sorter: (a, b) =>
        dayjs(a.returnOrder.requiredDate).diff(
          dayjs(b.returnOrder.requiredDate)
        ),
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "Buyer",
      dataIndex: ["returnOrder", "submittedBy"], // Access submittedBy from returnOrder
      key: "submittedBy",
      sorter: (a, b) =>
        a.returnOrder?.submittedBy.localeCompare(b.returnOrder?.submittedBy),
      filterSearch: true,
      filters: filterOptions.buyers,
      filteredValue: filteredInfo.buyer || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        record.returnOrder?.submittedBy
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (text) => (text ? formatBuyerName(text) : "N/A"),
    },

    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      sorter: (a, b) => {
        let aProjNum = a.projectNumber || "";
        let bProjNum = b.projectNumber || "";
        return aProjNum.toString().localeCompare(bProjNum.toString());
      },
      filterSearch: true,
      filters: filterOptions.projects,
      filteredValue: filteredInfo.projectNumber || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        record.projectNumber.toLowerCase().includes(value.toLowerCase()),
      render: (text) => (getProjectName(text, projects) || "N/A"),
    },

    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => formatMoney(text),
    },
    {
      title: "RO Status",
      dataIndex: "roStatus",
      key: "roStatus",
      sorter: (a, b) => a.roStatus.localeCompare(b.roStatus),
      filterSearch: true,
      filters: filterOptions.status,
      filteredValue: filteredInfo.roStatus || null, // Connect filteredInfo to Table
      onFilter: (value, record) => record.roStatus.includes(value),
      render: (status) => (
        <Tag
          style={{ fontSize: 11 }}
          color={status === "Closed" ? "green" : "blue"}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Time Status",
      key: "timeStatus",
      filters: [
        { text: "On Time", value: "On Time" },
        { text: "Late", value: "Late" },
      ],
      onFilter: (value, record) =>
        (calculateLateStatus(record) ? "Late" : "On Time") === value,
      render: (text, record) => {
        const isLate = calculateLateStatus(record);
        const color = isLate ? "red" : "green";
        const statusText = isLate ? "Late" : "On Time";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {statusText}
          </Tag>
        );
      },
    },
    {
      title: "Date Shipped",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => dayjs(a.receivedDate).diff(dayjs(b.receivedDate)),
      render: (text, record) =>
        record.roStatus !== "Closed" ? (
          <StopOutlined style={{ color: "#d3d3d3" }} />
        ) : (
          dayjs(text).format("MM/DD/YYYY")
        ),
    },
  ];

const onRowClick = (record) => {
  console.log("Row clicked:", record);
  setSelectedRecord(record);
  setModalVisible(true);
};


  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div>
        <TableHeader
          title="Returns"
          placeholder="Search Return Orders..."
          onSearchChange={handleSearch}
          totalItems={originalData.length}
          showRemoveFilters
          removeFilters={() => {
            setSearchTerm("");
            setFilteredInfo({});
            setData(originalData);
            onFilterChange(originalData);
          }}
          totalResults={data.length}
        />
        <Table
          className="small-table"
          rowClassName="clickable-row"
          dataSource={data}
          columns={columns}
          rowKey="id"
          loading={loading}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
          
          onChange={handleFilterChange}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100", "200"],
          }}
        />
      </div>
      <ReturnOrderModal
        purchaseOrder={selectedRecord}
        visible={modalVisible}
        onClose={handleModalClose}
        vendors={vendors}
        projects={projects} // Add projects data if needed
      />
    </>
  );
};

export default ReturnsTable;
