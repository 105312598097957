import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/google/createQuote";

export const createQuote = async (file, fileName, mimeType, folderId) => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const base64data = reader.result.split(",")[1]; // Remove the data URL prefix
        const response = await axios.post(
          `${API_BASE_URL}`,
          {
            fileName,
            fileContent: base64data,
            mimeType,
            folderId, // Include the folderId in the request
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response.data.webViewLink); // Adjusted to return webViewLink
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload file");
  }
};
