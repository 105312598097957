// src/Components/BOM/BOM.js
import React, { useMemo } from "react";
import { Table, Tag, Typography } from "antd";
import { formatMoney } from "../../../Formatters/helpers";
import {
  usePurchaseOrders,
  useVendors,
} from "../../../Contexts/useSpecificData";
import {
  getDefaultPriceItem,
  getLastPurchasedItem,
} from "../../../utils/itemsHelpers";
import { StopOutlined } from "@ant-design/icons";
import { COLORS } from "../../../constants";

const { Title } = Typography;

const BOM = ({ estimate }) => {
  const customItems = estimate.customItems || [];
  const modules = estimate.modules || [];
  const rfq = estimate.rfq || [];

  console.log("bom estimate", estimate);

  // Fetch purchase orders and vendors using custom hooks
  const { data: purchaseOrders = [] } = usePurchaseOrders();
  const { data: vendors = [] } = useVendors();

  // Helper function to parse price
  const parsePrice = (price) =>
    typeof price === "string"
      ? parseFloat(price.replace(/[^0-9.-]+/g, ""))
      : price;

  // Extract assembly items from rfq and adjust quantities and total cost
  const assemblyItems = rfq.flatMap((rfqItem) =>
    (rfqItem.assemblyItems || []).map((assemblyItem) => ({
      ...assemblyItem,
      price: parsePrice(assemblyItem.price),
      quantity: assemblyItem.quantity * rfqItem.quantity,
      assemblyName: rfqItem.assemblyName,
      type: "Assembly",
    }))
  );

  // Extract module items
  const moduleItems = modules.map((module) => ({
    ...module.container,
    price: parsePrice(module.container.price),
    quantity: parseInt(module.container.quantity, 10),
    type: "Module",
  }));

  // Combine all items
  const allItems = [
    ...customItems.map((item) => ({
      ...item,
      price: parsePrice(item.price),
      itemNumber: item.type !== "custom" ? item.itemNumber : "",
      type: item.type === "custom" ? "Custom Item" : "Aria Item",
      category:  item.type !== "custom" ? item.category : "",
    })),
    ...assemblyItems,
    ...moduleItems,
  ];

  // Enhance allItems with defaultPrice and lastPurchasedDate conditionally
  const enhancedItems = useMemo(() => {
    return allItems.map((item) => {
      // Apply utility functions only to items that are not "Custom Item"
      if (item.type !== "Custom Item") {
        const { defaultPrice } = getDefaultPriceItem(item, purchaseOrders);
        const { lastPurchasedDate } = getLastPurchasedItem(
          item,
          purchaseOrders
        );

        return {
          ...item,
          defaultPrice: defaultPrice || item.price, // Fallback to current price if defaultPrice is not available
          lastPurchasedDate: lastPurchasedDate || "N/A",
        };
      }

      // For "Custom Item", do not apply utility functions
      return {
        ...item,
        defaultPrice: null, // Or any other appropriate default value
        lastPurchasedDate: "N/A",
      };
    });
  }, [allItems, purchaseOrders]);

  // Calculate total cost
  const totalCost = enhancedItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const columns = [
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      sorter: (a, b) =>
        a.itemDescription.localeCompare(b.itemDescription, undefined, {
          sensitivity: "base",
        }),
      render: (itemDescription, record) => (
        <div>
          {/* {record.category && <Tag color="blue">{record.category}</Tag>} */}
          {record.itemNumber && <Tag>{record.itemNumber}</Tag>}
          <Typography.Text>{itemDescription}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) =>
        a.type.localeCompare(b.type, undefined, { sensitivity: "base" }),
      render: (type) => (
        <Tag
          color={
            type === "Custom Item"
              ? "blue"
              : type === "Aria Item"
              ? "purple"
              : type === "Assembly"
              ? "red"
              : "orange"
          }
        >
          {type}
        </Tag>
      ),
    },
    // {
    //   title: "Default Price",
    //   dataIndex: "defaultPrice",
    //   key: "defaultPrice",
    //   sorter: (a, b) => {
    //     const priceA = a.defaultPrice || 0;
    //     const priceB = b.defaultPrice || 0;
    //     return priceA - priceB;
    //   },
    //   render: (defaultPrice) =>
    //     defaultPrice !== null ? (
    //       formatMoney(defaultPrice)
    //     ) : (
    //       <StopOutlined style={{ color: COLORS.WARNING }} />
    //     ),
    // },
    {
      title: "Last Purchased",
      dataIndex: "lastPurchasedDate",
      key: "lastPurchasedDate",
      sorter: (a, b) => {
        if (a.lastPurchasedDate === "N/A" && b.lastPurchasedDate === "N/A")
          return 0;
        if (a.lastPurchasedDate === "N/A") return -1;
        if (b.lastPurchasedDate === "N/A") return 1;
        return new Date(a.lastPurchasedDate) - new Date(b.lastPurchasedDate);
      },
      render: (lastPurchasedDate) =>
        lastPurchasedDate !== "N/A" ? (
          lastPurchasedDate
        ) : (
          <StopOutlined style={{ color: COLORS.WARNING }} />
        ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (price) => formatMoney(price),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      render: (quantity) => quantity,
    },
    {
      title: "Total Cost",
      key: "totalCost",
      sorter: (a, b) => a.price * a.quantity - b.price * b.quantity,
      render: (text, record) => formatMoney(record.price * record.quantity),
    },
  ];

  return (
    <div>
      <Table
        size="small"
        columns={columns}
        dataSource={enhancedItems}
        rowKey={(record, index) => `${record.id || index}`}
        pagination={false}
        footer={() => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            Total Cost of Materials:{" "}
            <Tag
              color="green"
              style={{ fontWeight: "bold", fontSize: 16, marginLeft: 4 }}
            >
              {formatMoney(totalCost)}
            </Tag>
          </div>
        )}
      />
    </div>
  );
};

export default BOM;
