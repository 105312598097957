// src/Functions/itemsHelpers.js
import dayjs from "dayjs";

/**
 * Retrieves the last purchased date and price for a given item.
 * @param {Object} item - The item object.
 * @param {Array} purchaseOrders - Array of purchase orders.
 * @returns {Object} - Contains lastPurchasedDate and lastPurchasedPrice.
 */
export const getLastPurchasedItem = (item, purchaseOrders) => {
  let lastPurchasedDate = null;
  let lastPurchasedPrice = null;

  purchaseOrders?.forEach((po) => {
    po.lines.forEach((line) => {
      const vendorItemIds = item.vendors.map((vendor) => vendor.itemId);
      if (vendorItemIds.includes(line.itemId)) {
        const poDate = dayjs(po.orderedDate);

        if (!lastPurchasedDate || poDate.isAfter(lastPurchasedDate)) {
          lastPurchasedDate = poDate;
          lastPurchasedPrice = line.itemPrice;
        }
      }
    });
  });

  return {
    lastPurchasedDate: lastPurchasedDate
      ? lastPurchasedDate.format("MM/DD/YYYY")
      : null,
    lastPurchasedPrice,
  };
};

/**
 * Determines the default price for a given item.
 * @param {Object} item - The item object.
 * @param {Array} purchaseOrders - Array of purchase orders.
 * @returns {number|null} - The default price or null if not available.
 */
export const getDefaultPriceItem = (item, purchaseOrders) => {
  let lastPurchasedPrice = null;
  let highestPrice = null;

  purchaseOrders?.forEach((po) => {
    po.lines.forEach((line) => {
      const vendorItemIds = item.vendors.map((vendor) => vendor.itemId);
      if (vendorItemIds.includes(line.itemId)) {
        if (
          lastPurchasedPrice === null ||
          line.itemPrice > lastPurchasedPrice
        ) {
          lastPurchasedPrice = line.itemPrice;
        }

        if (highestPrice === null || line.itemPrice > highestPrice) {
          highestPrice = line.itemPrice;
        }
      }
    });
  });

  return lastPurchasedPrice !== null ? lastPurchasedPrice : highestPrice;
};
