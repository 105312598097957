import React from "react";
import {
  Button,
  Row,
  Col,
  Card,
  Tooltip,
  Modal,
  message,
  Tag,
  Descriptions,
  Divider,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import AddModuleModal from "./AddModuleModal";
import { updateById } from "../../../Functions/updateById";
import { TABLES, COLORS } from "../../../constants";
import { formatMoney } from "../../../Formatters/helpers";

function EstimateModules({ estimate, onAddSuccess }) {
  const handleDelete = (moduleId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this module?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const updatedModules = estimate.modules.filter(
            (module) => module.moduleId !== moduleId
          );

          const updatedEstimate = { modules: updatedModules };

          await updateById(TABLES.ESTIMATES, updatedEstimate, estimate.id);

          message.success("Module deleted successfully!");
          onAddSuccess(); // Ensure this function is called after successful deletion
        } catch (error) {
          message.error("Failed to delete module: " + error.message);
        }
      },
    });
  };

  const getTotalCost = (module) => {
    const price = module.container.price;
    const quantity = module.container.quantity;
    return price * quantity;
  };

  const totalCost = (estimate?.modules || []).reduce((total, module) => {
    return total + getTotalCost(module);
  }, 0);

  return (
    <>
      {/* Header Section: Total Cost and Add Module Button */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 8 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 18, marginRight: 12, fontWeight: 500 }}>
              Total Cost:
            </span>
            <Tag style={{ fontSize: 18, padding: 4, fontWeight:600 }} color={"green"}>
              {formatMoney(totalCost)}
            </Tag>
          </div>
        </Col>
        <Col>
          <AddModuleModal estimate={estimate} onAddSuccess={onAddSuccess}>
            <Button type="primary" icon={<PlusOutlined />}>
              Add Module
            </Button>
          </AddModuleModal>
        </Col>
      </Row>

      {/* Modules List */}
      <Row gutter={[24, 24]}>
        {estimate && estimate.modules && estimate.modules.length > 0 ? (
          estimate.modules.map((module) => (
            <Col key={module.moduleId} xs={24} sm={24} md={12} lg={8}>
              <Card
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {module.moduleName}
                    </span>
                    <Tag color={"green"} style={{ fontSize: 14 }}>
                      {formatMoney(getTotalCost(module))}
                    </Tag>
                  </div>
                }
                bodyStyle={{ padding: 0 }}
                actions={[
                  <AddModuleModal
                    key="edit"
                    estimate={estimate}
                    module={module}
                    onAddSuccess={onAddSuccess}
                  >
                    <Tooltip title="Edit Module">
                      <Button type="link" icon={<EditOutlined />} />
                    </Tooltip>
                  </AddModuleModal>,
                  <Tooltip key="delete" title="Delete Module">
                    <Button
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(module.moduleId)}
                    />
                  </Tooltip>,
                ]}
                bordered={false}
                style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}
              >
                {/* Module Details */}
                <Descriptions
                  column={1}
                  size="small"
                  bordered={true}
                  style={{ marginBottom: 16 }}
                >
                  <Descriptions.Item label="Description">
                    {module.description}
                  </Descriptions.Item>
                  <Descriptions.Item label="Container">
                    {module.container.itemDescription}
                  </Descriptions.Item>
                  <Descriptions.Item label="Quantity">
                    {module.container.quantity}
                  </Descriptions.Item>
                  <Descriptions.Item label="Structure Dimensions">
                    {`${module.structureWidth}ft (W) x ${module.structureLength}ft (L) x ${module.structureHeight}ft (H)`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Partition Walls">
                    {module.partitionWalls}
                  </Descriptions.Item>
                  <Descriptions.Item label="Wall Finish">
                    {module.wallFinish}
                  </Descriptions.Item>
                  <Descriptions.Item label="Permitted">
                    {module.permitted ? (
                      <Tag icon={<CheckCircleOutlined />} color="success">
                        Yes
                      </Tag>
                    ) : (
                      <Tag icon={<CloseCircleOutlined />} color="error">
                        No
                      </Tag>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Rain Protection">
                    {module.rainProtection ? (
                      <Tag icon={<CheckCircleOutlined />} color="success">
                        Yes
                      </Tag>
                    ) : (
                      <Tag icon={<CloseCircleOutlined />} color="error">
                        No
                      </Tag>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Long Wall Removed">
                    {module.longWallRemoved ? (
                      <Tag icon={<CheckCircleOutlined />} color="success">
                        Yes
                      </Tag>
                    ) : (
                      <Tag icon={<CloseCircleOutlined />} color="error">
                        No
                      </Tag>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="End Wall Removed">
                    {module.endWallRemoved ? (
                      <Tag icon={<CheckCircleOutlined />} color="success">
                        Yes
                      </Tag>
                    ) : (
                      <Tag icon={<CloseCircleOutlined />} color="error">
                        No
                      </Tag>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="R-Wall Value">
                    {module.wallRValue || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="R-Floor Value">
                    {module.floorRValue || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="R-Ceiling Value">
                    {module.ceilingRValue || "N/A"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          ))
        ) : (
          <Col span={24} style={{ textAlign: "center", padding: "40px 0" }}>
            <p>No modules added yet.</p>
          </Col>
        )}
      </Row>
    </>
  );
}

export default EstimateModules;
