import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Badge, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { formatMoney, formatDate } from "../../Formatters/helpers";
import { SearchInput } from "../../Styled/SearchInput";
import AddAssemblyModal from "../../Components/Assemblies/AddAssemblyModal";
import { useAssemblies, useItems } from "../../Contexts/useSpecificData";

const Assemblies = () => {
  const {
    data: assembliesData,
    loading: assembliesLoading,
    error: assembliesError,
    refresh: refreshAssemblies,
  } = useAssemblies();
  const {
    data: itemsData,
    loading: itemsLoading,
    error: itemsError,
    refresh: refreshItems,
  } = useItems();

  const [formattedAssemblies, setFormattedAssemblies] = useState([]);
  const [filteredAssemblies, setFilteredAssemblies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.title = `Assemblies`;
  }, [assembliesData]);

  useEffect(() => {
    if (assembliesData) {
      const formatted = assembliesData.map((assembly) => {
        const totalMaterialsPrice =
          assembly?.assemblyItems?.length > 0
            ? assembly.assemblyItems.reduce((total, item) => {
                const price = item?.price ? parseFloat(item.price) : 0;
                const quantity = item?.quantity ? parseFloat(item.quantity) : 0;
                return total + price * quantity;
              }, 0)
            : 0;

        console.log("Assembly ID:", assembly.id);
        console.log("Total materials price:", totalMaterialsPrice);

        // Ensure laborCost is parsed as a number
        const laborCostParsed = assembly?.laborCost
          ? parseFloat(assembly.laborCost)
          : 0;

        console.log("Labor cost parsed:", laborCostParsed);

        const totalCost = totalMaterialsPrice + laborCostParsed;

        console.log("Total cost:", totalCost);

        return {
          ...assembly,
          created: new Date(assembly.created),
          totalMaterialsPrice,
          laborCost: laborCostParsed,
          totalCost,
        };
      });

      console.log("Formatted assemblies:", formatted);

      setFormattedAssemblies(formatted);
      setFilteredAssemblies(formatted);
    }
  }, [assembliesData]);

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = formattedAssemblies.filter((item) => {
      return Object.keys(item).some((key) =>
        key === "created"
          ? formatDate(item[key], true).toLowerCase().includes(lowercasedFilter)
          : typeof item[key] === "string" &&
            item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredAssemblies(filteredData);
  }, [searchTerm, formattedAssemblies]);

  const categoryFilters = Array.from(
    new Set(formattedAssemblies.map((assembly) => assembly.category))
  )
    .filter((category) => category)
    .map((category) => ({ text: category, value: category }));

  const columns = [
    {
      title: "Date Created",
      dataIndex: "created",
      key: "created",
      render: (text) => formatDate(text, true),
      sorter: (a, b) => a.created - b.created,
      defaultSortOrder: "descend",
    },
    {
      title: "Assembly Name",
      dataIndex: "assemblyName",
      key: "assemblyName",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={`/estimating/assemblies/${record.id}`}>{text}</Link>
          <Badge color="#08c" count={record?.assemblyItems?.length}></Badge>
        </div>
      ),
    },
    {
      title: "Quote Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: categoryFilters,
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Materials Price",
      dataIndex: "totalMaterialsPrice",
      key: "totalMaterialsPrice",
      render: (text) => {
        console.log("Rendering Materials Price:", text);
        return text > 0 ? (
          formatMoney(text)
        ) : (
          <Tag color="red">Unavailable</Tag>
        );
      },
    },
    {
      title: "Labor Cost",
      dataIndex: "laborCost",
      key: "laborCost",
      render: (text) => {
        console.log("Rendering Labor Cost:", text);
        return text > 0 ? (
          formatMoney(text)
        ) : (
          <Tag color="red">Unavailable</Tag>
        );
      },
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      render: (text, record) => {
        console.log("Rendering Total Cost:", text);
        return text > 0 ||
          record.totalMaterialsPrice > 0 ||
          record.laborCost > 0 ? (
          formatMoney(text)
        ) : (
          <Tag color="red">Unavailable</Tag>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  if (assembliesError || itemsError)
    return <div>Error: {assembliesError?.message || itemsError?.message}</div>;

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={8}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        Assemblies
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></Col>
      <Col align="right" span={8}>
        <AddAssemblyModal onAddSuccess={refreshAssemblies}>
          <Button type="primary" icon={<PlusOutlined />}>
            Add Assembly
          </Button>
        </AddAssemblyModal>
      </Col>
      <Col span={24}>
        <SearchInput placeholder="Search Assemblies" onChange={handleSearch} />
      </Col>
      <Col span={24}>
        <Table
          dataSource={filteredAssemblies}
          columns={columns}
          loading={assembliesLoading}
          rowKey="id"
          size="small"
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default Assemblies;
