import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Table,
  Tag,
  Popover,
  Modal,
  Form,
  Select,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { useItems, useVendors } from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import { COLORS, TABLES } from "../../constants";
import { updateById } from "../../Functions/updateById";
import { useParams, useNavigate } from "react-router-dom";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";

const VendorItems = () => {
  const { id: vendorId } = useParams(); // Get vendor ID from URL
  const navigate = useNavigate(); // useNavigate hook for navigation
    const { data: items, refresh: refreshItems, loading: isLoading } = useItems();
    const {data: vendors, refresh:refreshVendors} = useVendors();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [visiblePopover, setVisiblePopover] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [saving, setSaving] = useState(false);
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItemToAdd, setSelectedItemToAdd] = useState(null);
  const [newItemPrice, setNewItemPrice] = useState("");
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

    const [form] = Form.useForm();
    

  useEffect(() => {
    if (items) {
      // Filter items associated with the vendor
      const vendorItems = items.filter((item) =>
        item.vendors.some((vendor) => vendor.id === vendorId)
      );
      const lowercasedFilter = searchTerm.toLowerCase();
      const filteredData = vendorItems.filter((item) => {
        return (
          (item.itemDescription &&
            item.itemDescription.toLowerCase().includes(lowercasedFilter)) ||
          (item.itemNumber &&
            item.itemNumber.toLowerCase().includes(lowercasedFilter))
        );
      });
      setFilteredItems(filteredData);

      // Get items not associated with this vendor and sort by itemDescription A-Z
      const unassociatedItems = items
        .filter(
          (item) => !item.vendors.some((vendor) => vendor.id === vendorId)
        )
        .sort((a, b) => {
          const descriptionA = (a.itemDescription || "").trim(); // Fallback to empty string and trim whitespace
          const descriptionB = (b.itemDescription || "").trim(); // Fallback to empty string and trim whitespace
          return descriptionA.localeCompare(descriptionB);
        });

      setAvailableItems(unassociatedItems);
    }
  }, [items, vendorId, searchTerm]);

const handleDeleteItem = async () => {
  const hasMultipleVendors = selectedItem.vendors.length > 1;

  if (!hasMultipleVendors) {
    // Open additional confirmation modal if there's only one vendor
    setDeleteModalVisible(false); // Close the initial modal
    setConfirmDeleteModalVisible(true); // Open the second confirmation modal
  } else {
    // If there are multiple vendors, proceed to remove only the selected vendor
    setSaving(true);
    try {
      const updatedVendors = selectedItem.vendors.filter(
        (vendor) => vendor.id !== vendorId
      );

      await updateById(
        TABLES.ITEMS,
        { vendors: updatedVendors },
        selectedItem.id
      );

      refreshItems();
      setDeleteModalVisible(false);
    } catch (error) {
      console.error("Failed to delete vendor:", error);
    } finally {
      setSaving(false);
    }
  }
};

  const confirmDeleteItem = async () => {
    setSaving(true);
    try {
      await updateById(TABLES.ITEMS, { deleted: true }, selectedItem.id); // Completely delete the item
      refreshItems();
      setConfirmDeleteModalVisible(false); // Close the confirmation modal
    } catch (error) {
      console.error("Failed to delete item:", error);
    } finally {
      setSaving(false);
    }
  };


const handleEditPrice = async (itemId, vendorId, newPrice) => {
  if (!newPrice) return;

  setSaving(true);
  const item = items.find((item) => item.id === itemId);

  const updatedVendors = item.vendors.map((vendor) => {
    if (vendor.id === vendorId) {
      return {
        ...vendor,
        lastPrice: vendor.price, // Store the current price as lastPrice
        price: parseFloat(newPrice), // Update the price
        lastUpdated: dayjs().format("MM/DD/YYYY"), // Set the lastUpdated field to the current date
      };
    }
    return vendor;
  });

  try {
    await updateById(TABLES.ITEMS, { vendors: updatedVendors }, itemId);
    refreshItems();
    setVisiblePopover({});
  } catch (error) {
    console.error("Failed to update price:", error);
  } finally {
    setSaving(false);
  }
};

const handleAddItem = async () => {
  try {
    const values = await form.validateFields(); // Ensure form validation
    const { item: selectedItemToAdd, price: newItemPrice } = values; // Destructure form values

    setSaving(true);
    const item = items.find((item) => item.id === selectedItemToAdd);
    const updatedVendors = [
      ...item.vendors,
      { id: vendorId, price: parseFloat(newItemPrice) },
    ];

    await updateById(TABLES.ITEMS, { vendors: updatedVendors }, item.id);
    refreshItems();
    setAddItemModalVisible(false);
    refreshVendors();
    form.resetFields(); // Clear the form after submission
  } catch (error) {
    console.error("Failed to add item:", error);
  } finally {
    setSaving(false);
  }
};

  const PricePopoverContent = ({ itemId, vendorId, currentPrice }) => {
    const [newPrice, setNewPrice] = useState(currentPrice);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          type="number"
          value={newPrice}
          onChange={(e) => setNewPrice(e.target.value)}
          style={{ width: 100, marginRight: 8 }}
          prefix="$"
        />
        <Button
          type="primary"
          onClick={() => handleEditPrice(itemId, vendorId, newPrice)}
          loading={saving}
          disabled={!newPrice}
        >
          Save
        </Button>
      </div>
    );
  };

  const handleVisibleChange = (key, visible) => {
    setVisiblePopover((prevState) => ({
      ...prevState,
      [key]: visible,
    }));
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => <div>{text}</div>,
      width: 200,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text) => text,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Mfg ID",
      dataIndex: "manufacturerId",
      key: "manufacturerId",
      width: 120,
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      width: 75,
      ellipsis: true,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.orderBatchSize} {text}
        </div>
      ),
    },
    {
      title: "Item #",
      dataIndex: "itemNumber",
      key: "itemNumber",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      width: 100,
      render: (text, record) => {
        const vendor = record.vendors.find((v) => v.id === vendorId);
        return vendor?.lastUpdated ? (
          vendor.lastUpdated
        ) : (
          <StopOutlined style={{ color: "gray", opacity: 0.5 }} />
        );
      },
    },
    {
      title: "Last Price",
      dataIndex: "lastPrice",
      key: "lastPrice",
      width: 100,
      render: (text, record) => {
        const vendor = record.vendors.find((v) => v.id === vendorId);
        return vendor?.lastPrice ? (
          formatMoney(vendor.lastPrice)
        ) : (
          <StopOutlined style={{ color: "gray", opacity: 0.5 }} />
        );
      },
    },

    {
      title: "Price",
      key: "price",
      width: 125,
      render: (text, record) => {
        const vendor = record.vendors.find((v) => v.id === vendorId);
        return (
          <Popover
            content={
              <PricePopoverContent
                itemId={record.id}
                vendorId={vendorId}
                currentPrice={vendor.price}
              />
            }
            trigger="click"
            visible={visiblePopover[`${record.id}-price`]}
            onVisibleChange={(visible) =>
              handleVisibleChange(`${record.id}-price`, visible)
            }
          >
            <Button
              icon={<EditOutlined />}
              size="small"
              style={{ marginRight: 8 }}
            />
            <Tag color="green">{formatMoney(vendor.price)}</Tag>
          </Popover>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (text, record) => (
        <Button
          icon={<DeleteOutlined />}
          size="small"
          type="primary"
          danger
          onClick={() => {
            setSelectedItem(record);
            setDeleteModalVisible(true);
          }}
        />
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={12}
        style={{
          display: "flex",

          alignItems: "center",
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        <div style={{ marginRight: 8 }}>
          <span>{getVendorName(vendorId, vendors)}</span>{" "}
          <Tag color="blue" style={{ fontSize: 16, marginLeft: 8 }}>
            Items
          </Tag>
        </div>
      </Col>
      <Col span={12} align="right">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setAddItemModalVisible(true)}
        >
          Add Item
        </Button>
      </Col>

      <Col span={24}>
        <Input
          placeholder="Search Items"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 16 }}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={filteredItems}
          rowKey="id"
          loading={isLoading}
          pagination={false}
          size="small"
          className="small-table"
        />
      </Col>
      <Modal
        title={<Tag color={COLORS.WARNING}>Delete Item</Tag>}
        visible={deleteModalVisible}
        onOk={handleDeleteItem}
        onCancel={() => setDeleteModalVisible(false)}
        confirmLoading={saving}
      >
        Are you sure you want to remove this item from the vendor?
      </Modal>

      <Modal
        title="Confirm Item Deletion"
        visible={confirmDeleteModalVisible}
        onOk={confirmDeleteItem} // Trigger complete item deletion
        onCancel={() => setConfirmDeleteModalVisible(false)}
        confirmLoading={saving}
      >
        This vendor is the only one associated with the item. Are you sure you
        want to delete the item entirely?
      </Modal>

      <Modal
        title="Add Item to Vendor"
        visible={addItemModalVisible}
        onOk={form.submit} // Trigger form submission on OK button click
        onCancel={() => setAddItemModalVisible(false)}
        confirmLoading={saving}
        width={"60%"}
      >
        <Form
          form={form}
          onFinish={handleAddItem} // Set handleAddItem to run after successful form validation
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item
            label="Select Item"
            name="item"
            rules={[{ required: true, message: "Please select an item" }]}
          >
            <Select
              placeholder="Select an item"
              onChange={(value) => setSelectedItemToAdd(value)}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const itemNumber =
                  option?.itemNumber?.replace(/-/g, "").toLowerCase() || ""; // Remove dashes from itemNumber
                const itemDescription =
                  option?.itemDescription?.toLowerCase() || "";
                const inputWithoutDashes = input
                  .replace(/-/g, "")
                  .toLowerCase(); // Remove dashes from input

                return (
                  itemNumber.includes(inputWithoutDashes) ||
                  itemDescription.includes(input.toLowerCase())
                );
              }}
            >
              {availableItems.map((item) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  itemNumber={item.itemNumber} // Pass itemNumber as a prop
                  itemDescription={item.itemDescription} // Pass itemDescription as a prop
                >
                  <Tag>{item.itemNumber}</Tag> {item.itemDescription}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            rules={[{ required: true, message: "Please enter a price" }]}
          >
            <Input type="number" placeholder="Enter Price" prefix="$" />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default VendorItems;
