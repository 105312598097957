import React, { useState, useEffect } from "react";
import { Table, Input, Tag, Row, Col, Card, Button } from "antd";
import { StopOutlined, FilterOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import { getProjectName } from "../../Formatters/getProjectName";
import { SearchInput } from "../../Styled/SearchInput";
import TableHeader from "../../Styled/TableHeader";

const ItemsTable = ({ onFilterChange }) => {
  const { data: purchaseOrders, loading: purchaseOrdersLoading } =
    usePurchaseOrders();
  const {
    data: vendors,
    loading: vendorsLoading,
    error: vendorsError,
  } = useVendors();
  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useProjects();

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({}); // Add filteredInfo for managing filters

  const [vendorFilters, setVendorFilters] = useState([]);
  const [buyerFilters, setBuyerFilters] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [lineStatusFilters, setLineStatusFilters] = useState([]);

  useEffect(() => {
    if (!purchaseOrdersLoading && !vendorsLoading && !projectsLoading) {
      const flattenedData = purchaseOrders.flatMap((order) => {
        // Validate critical dates using dayjs
        const orderedDate = dayjs(order.orderedDate);
        const requiredDate = dayjs(order.requiredDate);
        const projectStartDate = dayjs(order.projectStartDate); // Example additional date
        // Add other dates if necessary

        // Check if all required dates are valid
        if (
          !orderedDate.isValid() ||
          !requiredDate.isValid()
          // Add other date validations if necessary
        ) {
          // Skip this order if any critical date is invalid
          return [];
        }

        // Process each line within the order
        return order.lines
          .map((line) => {
            const receivedDate = dayjs(line.receivedDate);
            // Optionally, validate receivedDate if it's critical
            if (line.receivedDate && !receivedDate.isValid()) {
              // Skip this line if receivedDate is invalid
              return null;
            }

            const today = dayjs().startOf("day");
            const isLate =
              line.qtyReceived >= line.qtyOrdered &&
              line.receivedDate &&
              receivedDate.isAfter(requiredDate);
            const timeStatus =
              line.qtyReceived < line.qtyOrdered && today.isAfter(requiredDate)
                ? "Late"
                : isLate
                ? "Late"
                : "On Time";

            return {
              ...line,
              poNumber: order.poNumber,
              vendor: order.vendor,
              orderedDate: dayjs(order.orderedDate),
              requiredDate: dayjs(order.requiredDate),
              buyer: order.buyer,
              projectNumber: order.projectNumber,
              total: line.qtyOrdered * line.itemPrice,
              lineStatus:
                line.qtyReceived >= line.qtyOrdered
                  ? "Closed"
                  : line.qtyReceived > 0
                  ? "Partial"
                  : "Open",
              timeStatus,
            };
          })
          .filter((line) => line !== null); // Exclude lines with invalid receivedDate
      });

      setData(flattenedData);
      setOriginalData(flattenedData);
      setLoading(false);
      updateFilterOptions(flattenedData);
      onFilterChange(flattenedData);
    }
  }, [
    purchaseOrders,
    purchaseOrdersLoading,
    vendorsLoading,
    projectsLoading,
    vendors,
    projects,
    onFilterChange,
  ]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value === "") {
      setData(originalData);
      onFilterChange(originalData);
      return;
    }

    const filteredData = originalData.filter((record) =>
      ["itemNumber", "itemDescription", "poNumber"].some((key) =>
        record[key]?.toString().toLowerCase().includes(value)
      )
    );

    setData(filteredData);
    onFilterChange(filteredData);
  };

  const handleFilterChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters); // Track filters in state
    applyFilters(filters);
  };

  const applyFilters = (filters) => {
    let filteredData = originalData;

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filteredData = filteredData.filter((record) => {
          const valueToCompare =
            key === "vendor"
              ? getVendorName(record[key], vendors)
              : key === "buyer"
              ? formatBuyerName(record[key])
              : key === "projectNumber"
              ? getProjectName(record[key], projects)
              : record[key];

          return valueToCompare && filters[key].includes(valueToCompare);
        });
      }
    });

    setData(filteredData);
    updateFilterOptions(filteredData);
    onFilterChange(filteredData);
  };

  const updateFilterOptions = (filteredData) => {
    setVendorFilters(getUniqueFilters(filteredData, "vendor"));
    setBuyerFilters(getUniqueFilters(filteredData, "buyer"));
    setProjectFilters(getUniqueFilters(filteredData, "projectNumber"));
    setLineStatusFilters(getUniqueFilters(filteredData, "lineStatus"));
  };

  const getUniqueFilters = (dataSource, dataIndex) => {
    return Array.from(
      new Set(
        dataSource.map((item) => {
          if (dataIndex === "vendor") {
            return getVendorName(item[dataIndex], vendors);
          } else if (dataIndex === "buyer") {
            return formatBuyerName(item[dataIndex]);
          } else if (dataIndex === "projectNumber") {
            return getProjectName(item[dataIndex], projects);
          } else {
            return item[dataIndex];
          }
        })
      )
    )
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b)) // Sorting alphabetically A-Z
      .map((value) => ({
        text: value,
        value: value,
      }));
  };

  const removeFilters = () => {
    setFilteredInfo({}); // Clear the filter state
    setData(originalData); // Reset data to originalData
    onFilterChange(originalData);
  };

  const columns = [
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
      className: "no-wrap",
      filterSearch: true,
      onFilter: (value, record) =>
        record.itemNumber
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      filterSearch: true,
      width:325,
      onFilter: (value, record) =>
        record.itemDescription
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      className: "no-wrap",
      width:60,
      filterSearch: true,
      onFilter: (value, record) =>
        record.poNumber?.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      defaultSortOrder: "descend", // Set default sort order to descending
    },

    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      width:150,
      filters: vendorFilters,
      filteredValue: filteredInfo.vendor || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        getVendorName(record.vendor, vendors)?.includes(value),
      render: (text) => {
        if (vendorsLoading) return "Loading...";
        if (vendorsError) return "Error loading vendors";
        return getVendorName(text, vendors);
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "orderedDate",
      key: "orderedDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.orderedDate).diff(dayjs(b.orderedDate)),
      // defaultSortOrder: "ascend",
    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      filters: buyerFilters,
      filteredValue: filteredInfo.buyer || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        formatBuyerName(record.buyer)?.includes(value),
      render: (text) => formatBuyerName(text),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      filters: projectFilters,
      filteredValue: filteredInfo.projectNumber || null, // Connect filteredInfo to Table
      onFilter: (value, record) =>
        getProjectName(record.projectNumber, projects)?.includes(value),
      render: (text) => getProjectName(text, projects),
    },
    // {
    //   title: "Ordered",
    //   dataIndex: "qtyOrdered",
    //   key: "qtyOrdered",
    // },
    {
      title: "Received",
      dataIndex: "qtyReceived",
      key: "qtyReceived",
      render: (text, record) => {
        const { qtyReceived, qtyOrdered } = record;
        if (!qtyOrdered) {
          return <StopOutlined style={{ color: "#d3d3d3" }} />;
        }
        const receivedFraction = `${qtyReceived || 0} / ${qtyOrdered}`;
        return <span>{receivedFraction}</span>;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "itemPrice",
      key: "itemPrice",
      render: (text) => formatMoney(text),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => formatMoney(text),
    },
    {
      title: "Line Status",
      dataIndex: "lineStatus",
      key: "lineStatus",
      filters: lineStatusFilters,
      filteredValue: filteredInfo.lineStatus || null, // Connect filteredInfo to Table
      onFilter: (value, record) => record.lineStatus?.includes(value),
      render: (status) => {
        let color = "blue";
        if (status === "Closed") color = "green";
        if (status === "Partial") color = "orange";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Time Status",
      dataIndex: "timeStatus",
      key: "timeStatus",
      filters: [
        { text: "On Time", value: "On Time" },
        { text: "Late", value: "Late" },
      ],
      filteredValue: filteredInfo.timeStatus || null, // Connect filteredInfo to Table
      onFilter: (value, record) => record.timeStatus === value,
      render: (text, record) => {
        const color = record.timeStatus === "Late" ? "red" : "green";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {record.timeStatus}
          </Tag>
        );
      },
    },
    {
      title: "Date Received",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => {
        if (!a.receivedDate && !b.receivedDate) return 0;
        if (!a.receivedDate) return -1; // `a` is null, so it comes after `b`
        if (!b.receivedDate) return 1; // `b` is null, so it comes after `a`
        return dayjs(a.receivedDate).diff(dayjs(b.receivedDate));
      },
      render: (text) =>
        text ? (
          dayjs(text).format("MM/DD/YYYY")
        ) : (
          <StopOutlined style={{ color: "#d3d3d3" }} />
        ),
    },
  ];

  return (
    <>
      <TableHeader
        title="Items"
        totalResults={data.length}
        totalItems={originalData.length}
        searchValue={searchTerm}
        onSearchChange={handleSearch}
        removeFilters={removeFilters}
        showRemoveFilters={true} // Set to true or false depending on the use case
      />
      <Table
        className="small-table"
        dataSource={data}
        columns={columns}
        pagination={{
          pageSizeOptions: ["10", "25", "50", "100", "250"],

          // hideOnSinglePage: true, total: data.length, showTotal: (total) => <Tag>{total} items</Tag>
        }}
        rowKey={(record) =>
          record.poNumber + record.itemNumber + record.itemDescription
        }
        loading={
          loading || purchaseOrdersLoading || vendorsLoading || projectsLoading
        }
        onChange={handleFilterChange}
      />
    </>
  );
};

export default ItemsTable;
